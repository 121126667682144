import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from '../config/constants'

const HttpInstanceImage=axios.create({
    baseURL: apiUrl,
    headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken'),
     'Content-Type':'multipart/form-data'
    }
  })

export const GetDietitiansTableData = (params:any,approvalstatus:number)=>{
    const RespData= HttpInstance.post("/dietitians/agentdietitiantable",{...params,approvalstatus:approvalstatus})
    return RespData;
 }

 export const SubmitDietitian = (resObj:any)=>{
    const respData= HttpInstanceImage.post("/dietitians/adddietitian",resObj)
    return respData
 }
 export const GetDietitiandetailsbyID = (id:string) =>{
  const RespData = HttpInstance.get("/dietitians/getdietitianbyid?id="+id)
  return RespData
}
 