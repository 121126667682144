import React, { FormEvent, useEffect, useRef, useState } from "react";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import "../add-restaurants/addRestaurants.css";
import { Button } from "primereact/button";
import { GetRestbyID } from "../../../api/pendingTable.api";
import "rc-time-picker/assets/index.css";
import Loader from "../../../components/ui/loader/loader.components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Steps } from "primereact/steps";
import { MenuItem } from "primereact/menuitem";
import { Toast } from "primereact/toast";
import {
  AutoComplete,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import { MapContainer, TileLayer, Marker, useMapEvents } from "react-leaflet";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import AddRemoveInputField from "../../../components/timing/timings.components";
import DropFileInput from "../drop-file-input/drop-file-input";
import { Image } from "primereact/image";
import {
  SearchRestaurant,
  SubmitRestaurant,
  getDistricts,
} from "../../../api/addRestarant.api";
import {EditRestaurantSubmit} from "../../../api/editRestaurant.api" ;
import { getRefreshedToken, isTokenExpired } from "../../../api/auth.api";
import { Chips } from "primereact/chips";

interface AutoCompleteChangeEvent extends FormEvent {}

type resObj = {
  // lat: any;
  name: string;
  address1: string;
  // address2: string;
  sizeofestablishment: string;
  city: string;
  state: string;
  // maplink: string;
  latitude: string;
  longitude: string;
  country: string;
  diettype: any[];
  pincode: string;
  landmark: string;
  website: string;
  email: string;
  phoneno: string;
  eaterytype: string;
  foodtype: any[];
  preference: string[];
  cuisine: string[];
  ambience: string;
  serving: string[];
  mealoftheday: string[];
  serviceoptions: string[];
  values: any[];
  features: string[];
  specialitems: string[];
  source: string;
  sourcelink: string;
  timings: any[];
  about: string[];
  whyinyeldam: string[];
  images: string[];
  zomatolink: string;
  swiggylink: string;
  dineoutlink: string;
  averagecost: string;
  district: string;
  // menu: string[];
  menuimages: any[];
  tags:any[]
};
const initialState = {
  name: "",
  address1: "",
  // address2: "",
  city: "",
  state: "",
  // maplink: "",
  diettype: [],
  latitude: "",
  longitude: "",
  country: "",
  pincode: "",
  landmark: "",
  website: "",
  email: "",
  phoneno: "",
  eaterytype: "",
  foodtype: [],
  preference: [],
  cuisine: [],
  ambience: "",
  serving: [],
  mealoftheday: [],
  serviceoptions: [],
  values: [],
  features: [],
  specialitems: [],
  source: "",
  sourcelink: "",
  timings: [],
  about: [],
  whyinyeldam: [],
  images: [],
  zomatolink: "",
  swiggylink: "",
  dineoutlink: "",
  averagecost: "",
  sizeofestablishment: "",
  district: "",
  // menu: [],
  menuimages: [],
  tags:[]
};
export default function Edit_Restaurants() {
  const [isAdding, setIsadding] = useState(false); // Loader state..
  const location = useLocation();
  const [resObj, setResObj] = useState<resObj>(initialState);
  const [uploadedImages, setUploadedImages] = useState<any[]>([]);
  const [latitude, setLatitude] = useState<string>("");
  const [longitude, setLongitude] = useState<string>("");
  const [uploadedMenu, setUploadedMenu] = useState<any[]>([]);
  // const [uploadedImage, setUploadedImage] = useState<any[]>([]);
  const [menuImages, setMenuImages] = useState<any>([]);
  const { id } = useParams();
  const toast = useRef<Toast>(null);
  const loadLazyData = () => {
    console.log("Inside loadlazy");
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      // setIsadding(true);
      const resData = await GetRestbyID(id as string);
      // console.log(
      //   "resData",
      //   resData.data.data,
      //   resData.data.data.location.coordinates[1]
      // );
      // setImages(resData.data.data.images);
      setResObj({
        ...resData.data.data,
        latitude: resData.data.data.location.coordinates[0].toString(),
        longitude: resData.data.data.location.coordinates[1].toString(),
      });
      setPreferences(resData.data.data.preference);
      setServiceoptions(resData.data.data.serviceoptions);
      setMod(resData.data.data.mealoftheday);
      setServices(resData.data.data.serving);
      setFeatures(resData.data.data.features);
      setEatery(resData.data.data.eaterytype);
      setFoodType(resData.data.data.foodtype);
      setAmbience(resData.data.data.ambience);
      setDiet(resData.data.data.diettype);
      setDistrict(resData.data.data.district);
      setNewState(resData.data.data.state);
      const resData1 = await getDistricts(resData.data.data.state);
      setDistricts(resData1.data.data.districts);
      //setImg(resData.data.data.images);
      setMenuImages(resData.data.data.menuimages);
      setLatitude(resData.data.data.location.coordinates[0]);
      setLongitude(resData.data.data.location.coordinates[1]);
      setCuisine(resData.data.data.cuisine);
      setSoe(resData.data.data.sizeofestablishment);
      setIsadding(false);
      setUploadedImages(resData.data.data.images)

      console.log("at the end of loadlazy");
      // setCuisine(resData.data.data.cuisine)
    }, 4000);
  };

  useEffect(() => {
    setIsadding(true);
    loadLazyData();
  }, []);
  const [selectedLocation, setSelectedLocation] = useState<
    L.LatLng | undefined
  >(undefined);
  const LocationMarker = () => {
    useMapEvents({
      click: (e) => {
        setSelectedLocation(e.latlng);
      },
    });

    return selectedLocation === undefined ? null : (
      <Marker position={selectedLocation}></Marker>
    );
  };
  // const location = [
  //   { lat: 17.4175901, lng: 78.3991192 },
  //   { lat: 13.0511668, lng: 80.0751056 },
  //   { lat: 22.572645, lng: 88.363892 },
  //   { lat: 12.9716, lng: 77.5946 },
  //   { lat: 19.07609, lng: 72.877426 },
  //   { lat: 28.6448, lng: 77.216721 },
  // ];
  const items: any = [
    {
      label: "Basic Information",
      command: (event: any) => {
        // toast.current.show({ severity: 'info', summary: 'First Step', detail: event.item.label });
      },
    },
    {
      label: "Attributes",
      command: (event: any) => {
        // toast.current.show({ severity: 'info', summary: 'Second Step', detail: event.item.label });
      },
    },
    {
      label: "Payment",
      command: (event: any) => {
        // toast.current.show({ severity: 'info', summary: 'Third Step', detail: event.item.label });
      },
    },
    {
      label: "Confirmation",
      command: (event: any) => {
        // toast.current.show({ severity: 'info', summary: 'Last Step', detail: event.item.label });
      },
    },
  ];
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [selectedImage1, setSelectedImage1] = useState<File | null>(null);
  const [selectedImage2, setSelectedImage2] = useState<File | null>(null);
  const [selectedImage3, setSelectedImage3] = useState<File | null>(null);
  const [selectedImage4, setSelectedImage4] = useState<File | null>(null);

  const handleImageChange1 = (e: any) => {
    const file = e.target.files?.[0];
    resObj.menuimages.push(file);

    setSelectedImage1(file);
  };
  const handleImageChange2 = (e: any) => {
    const file = e.target.files?.[0];
    resObj.menuimages.push(file);
    setSelectedImage2(file);
  };
  const handleImageChange3 = (e: any) => {
    const file = e.target.files?.[0];
    resObj.menuimages.push(file);
    setSelectedImage3(file);
  };
  const handleImageChange4 = (e: any) => {
    const file = e.target.files?.[0];
    resObj.menuimages.push(file);

    setSelectedImage4(file);
  };
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleNext = () => {
    console.log("per-data", resObj)
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1);
  };

  const SearchResBar = async (e: any) => {
    setSearch(e.value);

    if (e.target.value.length >= 3) {
      setTimeout(async () => {
        const resData = await SearchRestaurant(e.target.value);
        setSuggestions(resData.data.data);
        setSuggest(true);
      }, 1000);
    } else {
      setSuggest(false);
    }
  };

  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState<resObj[]>([]);
  const [suggest, setSuggest] = useState(false);

  interface restaurant {
    name: string;
    address1: string;
    images: any;
  }

  const itemTemplate = (item: restaurant) => {
    return (
      <div className="flex align-items-center">
        <li className="justify-content-between">
          <div className="flex align-items-center">
            <img
              alt=""
              src={item.images[0]}
              className={`mr-2`}
              style={{ width: "18px", height: "18px" }}
            />
            <div>{item.name}</div>
          </div>
          <div>{item.address1}</div>
        </li>
      </div>
    );
  };

  const [districts, setDistricts] = useState<string[]>([]);
  // const [tagValue, seTagValue] = useState<string[]>([]);
  const [sourceName, setSourceName] = useState<string>("");
  const [sourceURL, setSourceURL] = useState<string>("");
  const [newState, setNewState] = useState("");
  const states_list = [
    "Telangana",
    "Andhra Pradesh",
    "Tamil Nadu",
    "Maharashtra",
    "West Bengal",
    "Delhi",
    "Karnataka",
  ];
  const onStateChange = async (e: any) => {
    setNewState(e.value);
    const resData = await getDistricts(e.value as string);
    setDistricts(resData.data.data.districts);
    setResObj({ ...resObj, state: e.value });
  };

  const [district, setDistrict] = useState("");
  const onDistrictChange = (e: any) => {
    setDistrict(e.value);
    setResObj({ ...resObj, district: e.value });
  };

  const searchs = (event: AutoCompleteCompleteEvent) => {
    let _filteredRest;

    if (!event.query.trim().length) {
      _filteredRest = [...suggestions];
    } else {
      _filteredRest = suggestions.filter((rest) => {
        return rest.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }
    setSuggestions(_filteredRest);
  };

  const [preferences, setPreferences] = useState<string[]>([]);
  const Preferences = (e: any) => {
    let _preferences = [...preferences];

    if (e.checked) _preferences.push(e.value);
    else _preferences.splice(_preferences.indexOf(e.value), 1);

    setPreferences(_preferences);
    setResObj({ ...resObj, preference: _preferences });
  };

  const [services, setServices] = useState<string[]>([]);
  const Services = (e: any) => {
    let _services = [...services];

    if (e.checked) _services.push(e.value);
    else _services.splice(_services.indexOf(e.value), 1);

    setServices(_services);
    setResObj({ ...resObj, serving: _services });
  };

  const [mod, setMod] = useState<string[]>([]);
  const MOD = (e: any) => {
    let _mod = [...mod];

    if (e.checked) _mod.push(e.value);
    else _mod.splice(_mod.indexOf(e.value), 1);

    setMod(_mod);
    setResObj({ ...resObj, mealoftheday: _mod });
  };

  const [cuisine, setCuisine] = useState<any>(null);

  const onCuisineChange = (e: any) => {
    setCuisine(e.value);
    setResObj({
      ...resObj,
      cuisine: e.value,
    });
  };

  const Cuisine = [
    { name: "Indian", code: "IND" },
    { name: "Chinese", code: "CHI" },
    { name: "Mughlai", code: "MUG" },
    { name: "Italian", code: "ITA" },
    { name: "Continental", code: "CON" },
    { name: "American", code: "AM" },
    { name: "Thai", code: "TH" },
    { name: "Mediterranean", code: "MED" },
    { name: "Mexican", code: "MEX" },
    { name: "Japanese", code: "JAP" },
    { name: "Bengali", code: "BEN" },
    { name: "Awadhi", code: "AWA" },
    { name: "Oriental", code: "ORI" },
    { name: "Sichuan", code: "SIC" },
    { name: "Spanish", code: "SPA" },
    { name: "Chettinad", code: "CHE" },
    { name: "South Indian", code: "SOI" },
    { name: "North Indian", code: "NOI" },
  ];

  const SpecialItems = (data: string) => {
    const items = data.split(",");
    setResObj({ ...resObj, specialitems: items });
  };

  const [eatery, setEatery] = useState<any | null>(null);
  const onEateryChange = (e: any) => {
    setEatery(e.value);
    setResObj({ ...resObj, eaterytype: e.value });
  };
  const EateryType = [
    "Restaurant",
    "Bakery",
    "Cafe",
    "Sweets",
    "Ice Cream",
    "Cloud Kitchen",
    "Store",
    "Dhaba",
    "Food Court",
    "Juice",
    "Tiffins",
    "Street Food",
  ];

  const SOE = ["Small", "Medium", "Large", "Movable"];
  const [soe, setSoe] = useState<any>(null);

  const onSoeChange = (e: any) => {
    setSoe(e.value);
    setResObj({
      ...resObj,
      sizeofestablishment: e.value,
    });
  };

  const [ambience, setAmbience] = useState<any[]>([]);
  const onAmbienceChange = (e: any) => {
    setAmbience(e.value);
    setResObj({ ...resObj, ambience: e.value });
  };
  const Ambience = [
    "None",
    "Drive In",
    "Mountain",
    "Lake",
    "Sea Shore",
    "Mughlai",
    "Cave",
    "Jail",
    "Tiger",
    "Historical",
    "Forest",
    "Artistic",
    "Waterfall",
    "Cloud",
    "Beach",
    "Aeroplane",
    "Village",
    "Train",
    "Robot",
    "Bollywood",
    "Small Town",
    "Rain Forest",
    "Roof Top",
    "Bohemiam",
    "European",
    "Military",
    "Bus",
    "Lorry Garage",
    "Haunted",
    "Farm",
    "Avathar",
    "Pets",
    "Super star Rajni kanth",
    "Hut's",
    "Bar",
    "Palace",
    "Costal",
    "Castle",
  ];

  const [foodType, setFoodType] = useState<any | null>(null);
  const onFoodTypeChange = (e: any) => {
    setFoodType(e.value);
    setResObj({ ...resObj, foodtype: e.value });
  };
  const FoodType = [
    { name: "None", code: "NON" },
    { name: "Organic", code: "ORG" },
    { name: "Healthy", code: "HEA" },
    { name: "Traditional", code: "TRA" },
  ];

  const [diet, setDiet] = useState<any | null>(null);
  const onDietChange = (e: any) => {
    setDiet(e.value);
    setResObj({ ...resObj, diettype: e.value });
  };
  const DietTypes = [
    { name: "None", code: "NON" },
    { name: "Vegan", code: "VEG" },
    { name: "Gluten Free", code: "GLU" },
    { name: "Nut Free", code: "NUT" },
    { name: "Kosher", code: "KOS" },
    { name: "Halal", code: "HAL" },
    { name: "Keto", code: "KET" },
    { name: "Pescatarian", code: "PIS" },
    { name: "Dairy Free", code: "DAI" },
  ];

  const [value, setValue] = useState<any | null>(null);
  const onValueChange = (e: any) => {
    setValue(e.value);
    setResObj({ ...resObj, values: e.value });
  };
  const Values = [
    { name: "None", code: "NON" },
    { name: "Quality Driven", code: "QUA" },
    { name: "Service Driven", code: "SER" },
    { name: "Health Driven", code: "HEA" },
    { name: "Environment Driven", code: "ENV" },
  ];

  const SetOpTimes = (e: any) => {
    setResObj({
      ...resObj,
      timings: e,
    });
  };

  const [features, setFeatures] = useState<string[]>([]);
  const Features = (e: any) => {
    let _features = [...features];

    if (e.checked) _features.push(e.value);
    else _features.splice(_features.indexOf(e.value), 1);

    setFeatures(_features);
    setResObj({ ...resObj, features: _features });
  };

  const [serviceoptions, setServiceoptions] = useState<string[]>([]);

  const ServiceOptions = (e: any) => {
    let _serviceOptions = [...serviceoptions];

    if (e.checked) _serviceOptions.push(e.value);
    else _serviceOptions.splice(_serviceOptions.indexOf(e.value), 1);

    setServiceoptions(_serviceOptions);
    setResObj({ ...resObj, serviceoptions: _serviceOptions });
  };

  const About = (data: string) => {
    const about = data.split(".");
    setResObj({ ...resObj, about: about });
  };

  const WhyinYeldam = (data: string) => {
    const why = data.split(".");
    setResObj({ ...resObj, whyinyeldam: why });
  };

  const [restaurant, setRestaurant] = useState(false);
  const [phone, setPhone] = useState(false);
  const [address, setAddress] = useState(false);
  const [city, setCity] = useState(false);
  const [state, setState] = useState(false);
  const [pincode, setPincode] = useState(false);
  const [country, setCountry] = useState(false);
  const [lati, setLati] = useState(false);
  const [long, setLong] = useState(false);
  const [avgcost, setAvgcost] = useState(false);
  const [eater, setEater] = useState(false);
  const [food, setFood] = useState(false);
  const [aboutRestaurant, setAboutRestaurant] = useState(false);

  const [whyIn, setWhyIn] = useState(false);
  const [image, setImage] = useState(false);
  const [mail, setMail] = useState(false);
  const [dist, setdist] = useState(false);
  const [menuimages, setmenuimages] = useState<any>();
  // const [resObj, setResObj] = useState<resObj>(initialState);
  const [opTimes, setOpTimes] = useState<any[]>([]);
  const navigate = useNavigate();
  const SubmitResObj = async () => {
    const show = (message: any, severity: any, summary: any) => {
      if (toast.current) {
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      } else {
        console.log("toast issue");
      }
    };
    setIsadding(true);
    console.log("ResObj ", resObj);
    setTimeout(async () => {
      if (
        resObj.name.length === 0 ||
        resObj.phoneno.length === 0 ||
        resObj.address1.length === 0 ||
        resObj.city.length === 0 ||
        resObj.state.length === 0 ||
        resObj.pincode.length === 0 ||
        resObj.country.length === 0 ||
        resObj.latitude.length === 0 ||
        resObj.longitude.length === 0 ||
        resObj.averagecost.length === 0 ||
        resObj.eaterytype.length === 0 ||
        resObj.foodtype.length === 0 ||
        resObj.about.length === 0 ||
        resObj.whyinyeldam.length === 0 ||
        resObj.images.length === 0 ||
        resObj.menuimages.length === 0 
        
      ) {
        if (resObj.name.length === 0) {
          const element = document.getElementById("name");
          setIsadding(false);
          show("Please enter Restaurant Name", "warn", "Warn");

          setRestaurant(true);
        }
        if (resObj.phoneno.length === 0) {
          const element = document.getElementById("phoneno");
          setIsadding(false);
          show("Please enter Phone Number", "warn", "Warn");
          setPhone(true);
        }
        if (resObj.email.length === 0) {
          const element = document.getElementById("email");
          setIsadding(false);
          show("Please enter Email", "warn", "Warn");
          setMail(true);
        }

        if (resObj.address1.length === 0) {
          const element = document.getElementById("address");
          setIsadding(false);
          show("Please enter Address", "warn", "Warn");
          setAddress(true);
        }
        if (resObj.city.length === 0) {
          const element = document.getElementById("city");
          setIsadding(false);
          show("Please enter City", "warn", "Warn");
          setCity(true);
        }
        if (resObj.state.length === 0) {
          const element = document.getElementById("state");
         
          setIsadding(false);
          show("Please enter State", "warn", "Warn");
          setState(true);
        } 
         if (resObj.pincode.length === 0) {
          const element = document.getElementById("pincode");
        
          setIsadding(false);
          show("Please enter picode", "warn", "Warn");
          setPincode(true);
        }  
        if (resObj.district.length === 0) {
          const element = document.getElementById("pincode");
        
          setIsadding(false);
          show("Please enter district", "warn", "Warn");
          setdist(true);
        } 
         if (resObj.country.length === 0) {
          const element = document.getElementById("country");
        
          setIsadding(false);
          show("Please enter Country", "warn", "Warn");
          setCountry(true);
        }  
        if (resObj.latitude.length === 0) {
          const element = document.getElementById("latitude");
         
          setIsadding(false);
          show("Please enter Latitude", "warn", "Warn");
          setLati(true);
        }  
        if (resObj.longitude.length === 0) {
          const element = document.getElementById("longitude");
          setIsadding(false);
          show("Please enter Logitude", "warn", "Warn");
          setLong(true);
        }  
        if (resObj.averagecost.length === 0) {
          const element = document.getElementById("average-cost");
          setIsadding(false);
          show("Please enter AveragCost", "warn", "Warn");
          setAvgcost(true);
        }  
        if (resObj.eaterytype.length === 0) {
          const element = document.getElementById("eater");
          setIsadding(false);
          show("Please enter EateryType", "warn", "Warn");
          setEater(true);
        }  
        if (resObj.foodtype.length === 0) {
          const element = document.getElementById("food-type");
          setIsadding(false);
          show("Please enter FoodTypes", "warn", "Warn");
          setFood(true);
        }  
        if (resObj.about.length === 0) {
          const element = document.getElementById("about-the");
          setIsadding(false);
          show("Please enter About Restaurant", "warn", "Warn");
          setAboutRestaurant(true);
        }  
        if (resObj.whyinyeldam.length === 0) {
          const element = document.getElementById("why-in");
          if (element) {
            // Check if element exists before accessing it
            // element.style.border = "1px solid red";
          }
          setIsadding(false);
          show("Please enter Why In Yeldam", "warn", "Warn");
          setWhyIn(true);
        }   
        if (resObj.menuimages.length === 0) {
          const element = document.getElementById("menu-items");

          setIsadding(false);
          show("Please enter MenuItems", "warn", "Warn");
          setmenuimages(true);
        }
        //  else {
        //   setIsadding(false);
        //   show("Please enter mandatory fields", "warn", "Warn");
        //   setResObj({
        //     ...resObj,
        //     timings: opTimes,
        //   });
        // }
      } else {
        // const formData = new FormData();
        // Object.entries(resObj).forEach(([key, value]) => {
          // if (key === "images" || key === "menuimages") {
          //   if (value) {
          //     const files = Array.from(value);
          //     files.forEach((file: any, index) => {
          //       formData.append(`${key}`, file);
          //     });
          //   }
          // }
          //  else if (key === "timings") {
          //   if (Array.isArray(value)) {
          //     value.forEach((timing, index) => {
          //       const timingPrefix = `timings[${index}]`;
          //       formData.append(`${timingPrefix}[day]`, timing.day);
          //       timing.timings.forEach((time: any, timeIndex: any) => {
          //         const timePrefix = `${timingPrefix}[timings][${timeIndex}]`;
          //         formData.append(`${timePrefix}[opens]`, time.opens);
          //         formData.append(`${timePrefix}[closes]`, time.closes);
          //       });
          //     });
          //   } else {
          //     formData.append(key, value as string);
          //   }
          // } 
          // else if (
          //   key === "foodtype" ||
          //   key === "diettype" ||
          //   key === "cuisine" ||
          //   key === "values"
          // ) 
          // {
          //   if (Array.isArray(value)) {
          //     value.forEach((val, index) => {
          //       const newVal = `${key}[${index}]`;
          //       formData.append(`${newVal}[name]`, val.name);
          //       formData.append(`${newVal}[code]`, val.code);
          //     });
          //   }
          // } 
          // else {
          //   if (Array.isArray(value)) {
          //     value.forEach((item) => {
          //       formData.append(key, item);
          //     });
          //   } else {
          //     formData.append(key, value);
          //   }
          // }
        // });

        // Use the formData object to make the API call

        // console.log(formData);
        if (isTokenExpired()) {
          console.log("inside");
          await getRefreshedToken();
        } else {
          console.log("outside");
          await EditRestaurantSubmit(id as string, resObj)
            .then((response: any) => {
              console.log(response)
              if (response.data.status === 200) {
                console.log(response.data);
                setIsadding(false);
                show("Saved", "info", "Info");
                const { pathname } = location;
                const splitpath = pathname.split("/");
                console.log("pathname" + splitpath[0]);
                const pathname_s =
                  "/" + splitpath[1] + "/details/" + splitpath[3];
                navigate(pathname_s);
              }  else {
                console.log(response);
                setIsadding(false);
                show("Invalid Access", "error", "Error");
              }
            })
            .catch((error: any) => {
              console.log(error.response.status);
              setIsadding(false);
              if (error.response.status === 403) {
                show("Token Expired", "warn", "Warn");
              }
            });
        }
      }
    }, 1000);
  };

  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const removeUrlByName = (nameToRemove: string, imagesToRemove: any[]) => {
    const removeImg = imagesToRemove.filter((url) => url.name !== nameToRemove);
    setUploadedImages(removeImg);
  };
  const removeImageByIndex = (index:any) => {
    const updatedImages = [...menuImages];
    updatedImages.splice(index, 1);
    setMenuImages(updatedImages);
  };
  
  return (
    <>
      <main>
        {!isAdding ? "" : <Loader />}
        <div>
          <Navbar />
          <Toast ref={toast}></Toast>
          <section className="page-content">
            <div className="">
              <div className="container align-items-center">
                <div
                  className="fw-500 fs-20 ym-text-link-primary"
                  style={{ fontFamily: "rubik" }}
                >
                  <a href="/dashboard">
                    <i
                      className="pi pi-home"
                      style={{ fontSize: "1.2rem", color: "black" }}
                    ></i>
                  </a>{" "}
                  &nbsp; {">"} &nbsp;Add Restaurant
                </div>
                {/* <div className="ml-auto row">
                    <div className="col">
                      <Button
                        className="p-button-success ym-reset-button"
                        label="Reset"
                      ></Button>
                    </div>
                    <div className="col">
                      <Button
                        className="p-button-success ym-add-button"
                        label="Add Restaurant"
                      ></Button>
                    </div>
                  </div> */}
              </div>
              <div className="container-fluid">
                <div className="d-flex mt-1">
                  {/* <div className="scrollbar m-2" id="style-default"> */}
                  <div className="container-fluid">
                    <div className="row container-fluid">
                      {/* <div className="card p-0 m-0 ym-add-card">
                        <Steps
                          model={items}
                          activeIndex={activeIndex}
                          onSelect={(e) => setActiveIndex(e.index)}
                          readOnly={false}
                        />
                         </div> */}

                      <div className="card ym-add-card">
                        <div className="row container-fluid">
                          <div className="col-12 lg:col-12 xl:col-12">
                            <div className="card p-2 m-0 ym-add-card ym-bg-primary-50">
                              <div className="search-input p-inputgroup">
                                <AutoComplete
                                  id="search"
                                  className="Restaurant"
                                  placeholder="Search Restaurant Name..."
                                  style={{ fontFamily: "roboto" }}
                                  value={search}
                                  suggestions={suggestions}
                                  onSelect={(e: any) => {
                                    setSearch(e.value.name);
                                  }}
                                  completeMethod={searchs}
                                  onChange={(e: any) => {
                                    SearchResBar(e);
                                  }}
                                  itemTemplate={itemTemplate}
                                />
                                <Button
                                  icon="pi pi-search"
                                  className="search-button p-button-warning"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <br></br>
                        <div className="card ym-add-card pt-0">
                          {currentIndex === 0 && (
                            <div className="row container-fluid">
                              <div className="col-6 lg:col-6 xl:col-6">
                                <div className="card mb-0 pb-0 pt-0 ym-add-card">
                                  <label className="ym-input-label">
                                    Restaurant Name{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      id="name"
                                      value={resObj.name}
                                      onChange={(e: any) => {
                                        setResObj({
                                          ...resObj,
                                          name: e.target.value,
                                        });
                                      }}
                                      placeholder="Enter Restaurant Name..."
                                      aria-describedby="username-help"
                                    />
                                    <div
                                      className="fw-400 fs-14 ym-text-typo-500 mt-1"
                                      style={{ fontFamily: "rubik" }}
                                    >
                                      Do not exceed 20 characters when entering
                                      the restaurant name{" "}
                                    </div>
                                  </div>

                                  <br></br>
                                  <label className="ym-input-label">
                                    Phone Number{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      // type="number"
                                      id="phoneno"
                                      value={resObj.phoneno}
                                      className="no-spinner"
                                      maxLength={14}
                                      onChange={(e: any) => {
                                        setResObj({
                                          ...resObj,
                                          phoneno: e.target.value,
                                        });
                                      }}
                                      placeholder="Enter Phone Number..."
                                      aria-describedby="username-help"
                                    />
                                    <div
                                      className="fw-400 fs-14 ym-text-typo-500 mt-1"
                                      style={{ fontFamily: "rubik" }}
                                    >
                                      Enter a valid phone number in the input
                                      field (e.g., +1234567890).{" "}
                                    </div>
                                  </div>
                                  <br></br>
                                  <label className="ym-input-label">
                                    Email Address{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      id="email"
                                      value={resObj.email}
                                      onChange={(e: any) => {
                                        setResObj({
                                          ...resObj,
                                          email: e.target.value,
                                        });
                                      }}
                                      placeholder="Enter Email..."
                                      aria-describedby="username-help"
                                    />
                                    <div
                                      className="fw-400 fs-14 ym-text-typo-500 mt-1"
                                      style={{ fontFamily: "rubik" }}
                                    >
                                      Enter a valid email address
                                      (example@example.com).{" "}
                                    </div>
                                  </div>
                                  <br></br>
                                  <label className="ym-input-label">
                                    Website URL{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      id="website"
                                      value={resObj.website}
                                      onChange={(e: any) => {
                                        setResObj({
                                          ...resObj,
                                          website: e.target.value,
                                        });
                                      }}
                                      placeholder="Enter Website URL..."
                                      aria-describedby="username-help"
                                    />
                                    <div
                                      className="fw-400 fs-14 ym-text-typo-500 mt-1"
                                      style={{ fontFamily: "rubik" }}
                                    >
                                      Enter a valid website link in the input
                                      field (e.g., www.example.com).{" "}
                                    </div>
                                  </div>
                                  <br></br>
                                  <label className="ym-input-label">
                                    Average Cost (per 2 persons){" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      id="average-cost"
                                      maxLength={5}
                                      placeholder="Enter Amount..."
                                      value={resObj.averagecost}
                                      onChange={(e: any) => {
                                        setResObj({
                                          ...resObj,
                                          averagecost: e.target.value,
                                        });
                                      }}
                                    />
                                    <div
                                      className="fw-400 fs-14 ym-text-typo-500 mt-1"
                                      style={{ fontFamily: "rubik" }}
                                    >
                                      The value should be in the appropriate
                                      currency and format{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 lg:col-6 xl:col-6">
                                <div className="card mb-0 pb-0 pt-0 ym-add-card">
                                  <label className="ym-input-label">
                                    Address <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputTextarea
                                      id="address"
                                      className="ym-address-input"
                                      value={resObj.address1}
                                      onChange={(e: any) => {
                                        setResObj({
                                          ...resObj,
                                          address1: e.target.value,
                                        });
                                      }}
                                      placeholder="Enter Address Line 1..."
                                      rows={5}
                                      cols={30}
                                    />
                                    <div
                                      className="fw-400 fs-14 ym-text-typo-500 mt-1"
                                      style={{ fontFamily: "rubik" }}
                                    >
                                      Enter address with street, address
                                      required.{" "}
                                    </div>
                                  </div>

                                  <div className="row mt-2">
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        City <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="flex flex-column">
                                        <InputText
                                          id="city"
                                          placeholder="Enter City..."
                                          value={resObj.city}
                                          onChange={(e: any) => {
                                            setResObj({
                                              ...resObj,
                                              city: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        State <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="row">
                                        <div className=" pt-0">
                                          <Dropdown
                                            style={{ width: "100%" }}
                                            id="state"
                                            placeholder="Select State..."
                                            value={newState}
                                            options={states_list}
                                            onChange={onStateChange}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row mt-2">
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        District{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>

                                      <div className="flex flex-column">
                                        <Dropdown
                                          style={{ width: "100%" }}
                                          id="eater"
                                          placeholder="Select District..."
                                          value={district}
                                          options={districts}
                                          onChange={onDistrictChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        Country{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="flex flex-column">
                                        <InputText
                                          id="country"
                                          placeholder="Enter Country..."
                                          value={resObj.country}
                                          onChange={(e: any) => {
                                            setResObj({
                                              ...resObj,
                                              country: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-2">
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        PinCode/ZipCode{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="flex flex-column">
                                        <InputText
                                          id="pincode"
                                          maxLength={6}
                                          placeholder="Enter Pincode..."
                                          value={resObj.pincode}
                                          onChange={(e: any) => {
                                            if (e.target.value > 6) {
                                            }
                                            setResObj({
                                              ...resObj,
                                              pincode: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-2">
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        Latitude{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>

                                      <div className="flex flex-column">
                                        <InputText
                                          id="latitude"
                                          className="ym-input-text"
                                          value={resObj.latitude}
                                          onChange={(e: any) => {
                                            setResObj({
                                              ...resObj,
                                              latitude: e.target.value,
                                            });
                                          }}
                                          placeholder="Please enter a valid latitude..."
                                          aria-describedby="latitude"
                                        />
                                      </div>
                                    </div>
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        Longitude{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="flex flex-column">
                                        <InputText
                                          id="longitude"
                                          className="ym-input-text"
                                          value={resObj.longitude}
                                          onChange={(e: any) => {
                                            setResObj({
                                              ...resObj,
                                              longitude: e.target.value,
                                            });
                                          }}
                                          placeholder="Please enter a valid longitude..."
                                          aria-describedby="longitude"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <br></br>
                                  {/* <label className="ym-input-label">
                                    Map Details{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label> */}
                                  {/* <div>
                                      Selected Location:{" "}
                                      {selectedLocation?.toString()}
                                  </div> */}
                                  {/* <div className="flex flex-column">
                                    <MapContainer
                                      center={location[0]}
                                      zoom={10}
                                      style={{ height: "250px", width: "100%" }}
                                    >
                                      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                      <LocationMarker />
                                    </MapContainer>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          )}
                          {currentIndex === 1 && (
                            <div className="row container-fluid">
                              <div className="col-6 lg:col-6 xl:col-6">
                                <div className="card mb-0 pb-0 pt-0 ym-add-card">
                                  <label className="ym-input-label">
                                    Preferance{" "}
                                  </label>

                                  <div className="row">
                                    <div className="col-3">
                                      <div className="flex align-items-center gap-2">
                                        <Checkbox
                                          inputId="preference1"
                                          name="preferences"
                                          value="Veg"
                                          onChange={Preferences}
                                          checked={preferences.includes("Veg")}
                                        />
                                        <label
                                          htmlFor="preference1"
                                          className="ml-2"
                                        >
                                          Veg
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="preference2"
                                          name="preferences"
                                          value="Non-Veg"
                                          onChange={Preferences}
                                          checked={preferences.includes(
                                            "Non-Veg"
                                          )}
                                        />
                                        <label
                                          htmlFor="preference2"
                                          className="ml-2"
                                        >
                                          Non Veg
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <br></br>
                                  <label className="ym-input-label">
                                    Serving
                                  </label>
                                  <div className="row">
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="service1"
                                          name="services"
                                          value="Tiffins"
                                          onChange={Services}
                                          checked={services.includes("Tiffins")}
                                        />
                                        <label
                                          htmlFor="service1"
                                          className="ml-2"
                                        >
                                          Tiffins
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="service2"
                                          name="services"
                                          value="Ala Carte"
                                          onChange={Services}
                                          checked={services.includes(
                                            "Ala Carte"
                                          )}
                                        />
                                        <label
                                          htmlFor="service2"
                                          className="ml-2"
                                        >
                                          Ala Carte
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="service3"
                                          name="services"
                                          value="Buffet"
                                          onChange={Services}
                                          checked={services.includes("Buffet")}
                                        />
                                        <label
                                          htmlFor="service3"
                                          className="ml-2"
                                        >
                                          Buffet
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="service3"
                                          name="services"
                                          value="Meals"
                                          onChange={Services}
                                          checked={services.includes("Meals")}
                                        />
                                        <label
                                          htmlFor="service3"
                                          className="ml-2"
                                        >
                                          Meals
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="service3"
                                          name="services"
                                          value="Thali"
                                          onChange={Services}
                                          checked={services.includes("Thali")}
                                        />
                                        <label
                                          htmlFor="service3"
                                          className="ml-2"
                                        >
                                          Thali
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <br></br>
                                  <label className="ym-input-label">
                                    Meal Of The Day{" "}
                                  </label>
                                  <div className="row">
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="mod1"
                                          name="mods"
                                          value="Breakfast"
                                          onChange={MOD}
                                          checked={mod.includes("Breakfast")}
                                        />
                                        <label htmlFor="mod1" className="ml-2">
                                          Breakfast
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="mod2"
                                          name="mods"
                                          value="Lunch"
                                          onChange={MOD}
                                          checked={mod.includes("Lunch")}
                                        />
                                        <label htmlFor="mod2" className="ml-2">
                                          Lunch
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="mod3"
                                          name="mods"
                                          value="Dinner"
                                          onChange={MOD}
                                          checked={mod.includes("Dinner")}
                                        />
                                        <label htmlFor="mod3" className="ml-2">
                                          Dinner
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="mod4"
                                          name="mods"
                                          value="Brunch"
                                          onChange={MOD}
                                          checked={mod.includes("Brunch")}
                                        />
                                        <label htmlFor="mod4" className="ml-2">
                                          Brunch
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-3">
                                      <div className="flex align-items-center">
                                        <Checkbox
                                          inputId="mod5"
                                          name="mods"
                                          value="Snacks"
                                          onChange={MOD}
                                          checked={mod.includes("Snacks")}
                                        />
                                        <label htmlFor="mod5" className="ml-2">
                                          Snacks
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <br></br>
                                  <label className="ym-input-label">
                                  Cuisine{" "}
                                  </label>
                                  <div className="flex flex-column gap-2 justify-content-center multiselect-demo">
                                    <MultiSelect
                                      value={cuisine}
                                      options={Cuisine}
                                      onChange={onCuisineChange}
                                      optionLabel="name"
                                      placeholder="Select Cuisines..."
                                      display="chip"
                                    />
                                  </div>
                                  <br></br>
                                  <label className="ym-input-label">
                                    Special Items{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputTextarea
                                      placeholder="Enter Special Items..."
                                      value={resObj.specialitems.join(",")}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                      ) => {
                                        SpecialItems(e.target.value);
                                      }}
                                      rows={7}
                                      cols={40}
                                    />

                                    <div
                                      className="fw-400 fs-14 ym-text-typo-500 mt-1"
                                      style={{ fontFamily: "rubik" }}
                                    >
                                      Please enter any special items, separated
                                      by commas, below. For example: Item1,
                                      Item2, Item3, etc.{" "}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 lg:col-6 xl:col-6">
                                <div className="card mb-0 pb-0 pt-0 ym-add-card">
                                  <div className="row">
                                    <label className="ym-input-label">
                                      Menu Images
                                    </label>
                                    <div className="col-4 lg:col-4 xl:col-4">
                                      <div className="upload-box">
                                        {menuImages.length > 0 ? (
                                          <>
                                            <img
                                              src={menuImages[0]}
                                              alt="Selected"
                                              className="preview-image"
                                            />
                                            <span className="delete-btn">
                                            <i
              className="pi pi-times"
              onClick={() => {
                removeImageByIndex(0); 
                // Remove the first image (index 0)
              }}
            ></i>
                                            </span>
                                          </>
                                        ) : (
                                          <div className="container ">
                                            <div className=" d-flex justify-content-center align-items-center">
                                              <i
                                                className="pi pi-image"
                                                style={{
                                                  fontSize: "2rem",
                                                  color: "#ccc",
                                                }}
                                              ></i>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                              <label
                                                htmlFor="upload-input"
                                                className="upload-label"
                                              >
                                                <div className="ym-placeholder">
                                                  Drag you images here, or
                                                  select{" "}
                                                  <span
                                                    style={{ color: "#000" }}
                                                  >
                                                    click to browse
                                                  </span>
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange1}
                                        id="upload-input"
                                      />
                                    </div>
                                    <div className="col-4 lg:col-4 xl:col-4">
                                      <div className="upload-box">
                                        {menuImages.length > 0 ? (
                                          <>
                                          <img
                                            src={menuImages[1]}
                                            alt="Selected"
                                            className="preview-image"
                                          /> 
                                           <span className="delete-btn1">
                                           <i
              className="pi pi-times"
              onClick={() => {
                removeImageByIndex(1);
                 // Remove the first image (index 0)
              }}
            ></i>
                                            </span>

                                          </>
                                        ) : (
                                          <div className="container ">
                                            <div className=" d-flex justify-content-center align-items-center">
                                              <i
                                                className="pi pi-image"
                                                style={{
                                                  fontSize: "2rem",
                                                  color: "#ccc",
                                                }}
                                              ></i>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                              <label
                                                htmlFor="upload-input"
                                                className="upload-label"
                                              >
                                                <div className="ym-placeholder">
                                                  Drag you images here, or
                                                  select{" "}
                                                  <span
                                                    style={{ color: "#000" }}
                                                  >
                                                    click to browse
                                                  </span>
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange2}
                                        id="upload-input"
                                      />
                                    </div>
                                    <div className="col-4 lg:col-4 xl:col-4">
                                      <div className="small-upload-box">
                                        {menuImages.length > 0 ? (
                                         <>
                                         <img
                                           src={menuImages[2]}
                                           alt="Selected"
                                           className="preview-image"
                                         /> 
                                          <span className="delete-btn2">
                                          <i
              className="pi pi-times"
              onClick={() => {
                removeImageByIndex(2);
                 // Remove the first image (index 0)
              }}
            ></i>
                                           </span>

                                         </>
                                         
                                        ) : (
                                          <div className="container">
                                            <div className=" d-flex justify-content-center align-items-center">
                                              <i
                                                className="pi pi-image"
                                                style={{
                                                  fontSize: "1rem",
                                                  color: "#ccc",
                                                }}
                                              ></i>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                              <label
                                                htmlFor="upload-input"
                                                className="upload-label"
                                              >
                                                <div className="small-ym-placeholder">
                                                  Drag you images here, or
                                                  select{" "}
                                                  <span
                                                    style={{ color: "#000" }}
                                                  >
                                                    click to browse
                                                  </span>
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange3}
                                        id="upload-input"
                                      />
                                      <div className="small-upload-box mt-1">
                                        {menuImages.length > 0 ? (
                                           <>
                                           <img
                                             src={menuImages[3]}
                                             alt="Selected"
                                             className="preview-image"
                                           /> 
                                            <span className="delete-btn3">
                                            <i
              className="pi pi-times"
              onClick={() => {
                removeImageByIndex(3);

                 // Remove the first image (index 0)
              }}
            ></i>
                                             </span>
 
                                           </>
                                        ) : (
                                          <div className="container">
                                            <div className=" d-flex justify-content-center align-items-center">
                                              <i
                                                className="pi pi-image"
                                                style={{
                                                  fontSize: "1rem",
                                                  color: "#ccc",
                                                }}
                                              ></i>
                                            </div>
                                            <div className="d-flex justify-content-center align-items-center">
                                              <label
                                                htmlFor="upload-input"
                                                className="upload-label"
                                              >
                                                <div className="small-ym-placeholder">
                                                  Drag you images here, or
                                                  select{" "}
                                                  <span
                                                    style={{ color: "#000" }}
                                                  >
                                                    click to browse
                                                  </span>
                                                </div>
                                              </label>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                      <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange4}
                                        id="upload-input"
                                      />
                                    </div>
                                  </div>
                                  <div className="row mt-1">
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        Eatry Type{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="flex flex-column">
                                        <Dropdown
                                          id="eater"
                                          placeholder="Select Eatery Type..."
                                          value={eatery}
                                          options={EateryType}
                                          onChange={onEateryChange}
                                        />
                                      </div>
                                    </div>
                                    <br></br>
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        Size Of Establishment
                                      </label>
                                      <div className="flex flex-column">
                                        <Dropdown
                                          placeholder="Select Size..."
                                          options={SOE}
                                          value={soe}
                                          onChange={onSoeChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row mt-0">
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        Ambience
                                      </label>
                                      <div className="flex flex-column">
                                        <Dropdown
                                          placeholder="Select Ambience..."
                                          options={Ambience}
                                          value={ambience}
                                          onChange={onAmbienceChange}
                                        />
                                      </div>
                                    </div>
                                    <div className="col m-0">
                                      <label className="ym-input-label">
                                        Food Type{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <div className="flex flex-column">
                                        <MultiSelect
                                          id="food-type"
                                          value={foodType}
                                          options={FoodType}
                                          onChange={onFoodTypeChange}
                                          optionLabel="name"
                                          placeholder="Select Food Types..."
                                          display="chip"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <label className="ym-input-label">
                                    Diet Types{" "}
                                  </label>
                                  <div className="flex flex-column">
                                    <MultiSelect
                                      value={diet}
                                      options={DietTypes}
                                      onChange={onDietChange}
                                      optionLabel="name"
                                      placeholder="Select Diets..."
                                      display="chip"
                                    />
                                  </div>
                                  <br></br>
                                  <label className="ym-input-label">
                                    Values{" "}
                                  </label>
                                  <div className="flex flex-column">
                                    <MultiSelect
                                      value={resObj.values}
                                      options={Values}
                                      onChange={onValueChange}
                                      optionLabel="name"
                                      placeholder="Select Values..."
                                      display="chip"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                          {currentIndex === 2 && (
                            <div className="row container-fluid">
                              <div className="col-6 lg:col-6 xl:col-6">
                                <div className="card mb-0 pb-0 pt-0 ym-add-card">
                                  <label className="ym-input-label">
                                    Timings <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <AddRemoveInputField func={SetOpTimes} />
                                </div>
                              </div>
                              <div className="col-6 lg:col-6 xl:col-6">
                                <div className="card mb-0 pb-0 pt-0 ym-add-card">
                                  <label className="ym-input-label">
                                    Upload Images{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>

                                  <div className="col-12 lg:col-12 xl:col-12 mt-2">
                                    <DropFileInput
                                      uploadedFiles={uploadedImages}
                                      setUploadedFiles={setUploadedImages}
                                      resObj={resObj}
                                      setResObj={setResObj}
                                      purpose="Menu"
                                    />
                                    <br></br>
                                    <div className="d-flex flex-column">
                                      <div className="row">
                                        {uploadedImages.map((item: any, i) => {
                                          console.log(uploadedImages)
                                          return (
                                            <>
                                              <div className="col-3 lg:col-3 xl:col-3">
                                                <img
                                                  className="ym-image"
                                                  src={uploadedImages[0]}
                                                  width="100"
                                                  height="100"
                                                />
                                                <span className="delete-button">
                                                  <i
                                                    className="pi pi-times "
                                                    onClick={() => {
                                                      removeUrlByName(
                                                        item.name,
                                                        uploadedImages
                                                      );
                                                  
                                                    }}
                                                  ></i>
                                                </span>
                                              </div>
                                              {/*@ts-ignore   */}
                                            </>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          {currentIndex === 3 && (
                            <div className="row container-fluid">
                              <div className="col-6 lg:col-6 xl:col-6">
                                <div className="card mb-0 pb-0 pt-0 ym-add-card">
                                  <div className="row mb-1">
                                    <label className="ym-input-label">
                                      Features and Facilities
                                    </label>
                                    <div className="row">
                                      <div className="col">
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="feature1"
                                            name="features"
                                            value="Lift"
                                            onChange={Features}
                                            checked={features.includes("Lift")}
                                          />
                                          <label
                                            htmlFor="feature1"
                                            className="ml-2"
                                          >
                                            Lift
                                          </label>
                                        </div>
                                        <br></br>
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="feature2"
                                            name="features"
                                            value="Air Conditioned"
                                            onChange={Features}
                                            checked={features.includes(
                                              "Air Conditioned"
                                            )}
                                          />
                                          <label
                                            htmlFor="feature2"
                                            className="ml-2"
                                          >
                                            Air Conditioned
                                          </label>
                                        </div>
                                        <br></br>
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="feature3"
                                            name="features"
                                            value="WiFi"
                                            onChange={Features}
                                            checked={features.includes("WiFi")}
                                          />
                                          <label
                                            htmlFor="feature3"
                                            className="ml-2"
                                          >
                                            WiFi
                                          </label>
                                        </div>
                                        <br></br>
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="feature4"
                                            name="features"
                                            value="Card Accepted"
                                            onChange={Features}
                                            checked={features.includes(
                                              "Card Accepted"
                                            )}
                                          />
                                          <label
                                            htmlFor="feature4"
                                            className="ml-2"
                                          >
                                            Card Accepted
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="feature5"
                                            name="features"
                                            value="Wallet Accepted"
                                            onChange={Features}
                                            checked={features.includes(
                                              "Wallet Accepted"
                                            )}
                                          />
                                          <label
                                            htmlFor="feature5"
                                            className="ml-2"
                                          >
                                            Wallet Accepted
                                          </label>
                                        </div>
                                        <br></br>
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="feature6"
                                            name="features"
                                            value="Parking"
                                            onChange={Features}
                                            checked={features.includes(
                                              "Parking"
                                            )}
                                          />
                                          <label
                                            htmlFor="feature6"
                                            className="ml-2"
                                          >
                                            Parking
                                          </label>
                                        </div>
                                        <br></br>
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="feature7"
                                            name="features"
                                            value="Valet Parking"
                                            onChange={Features}
                                            checked={features.includes(
                                              "Valet Parking"
                                            )}
                                          />
                                          <label
                                            htmlFor="feature7"
                                            className="ml-2"
                                          >
                                            Valet Parking
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <label className="ym-input-label">
                                    Service Options
                                  </label>
                                  <div className="flex flex-column">
                                    <div className="row">
                                      <div className="col-3">
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="serviceoption1"
                                            name="serviceoptions"
                                            value="Dine-In"
                                            onChange={ServiceOptions}
                                            checked={serviceoptions.includes(
                                              "Dine-In"
                                            )}
                                          />
                                          <label
                                            htmlFor="service1"
                                            className="ml-2"
                                          >
                                            Dine-In
                                          </label>
                                        </div>
                                      </div>
                                      <div className="col-3">
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="serviceoption2"
                                            name="serviceoptions"
                                            value="Takeaway"
                                            onChange={ServiceOptions}
                                            checked={serviceoptions.includes(
                                              "Takeaway"
                                            )}
                                          />
                                          <label
                                            htmlFor="service2"
                                            className="ml-2"
                                          >
                                            Takeaway
                                          </label>
                                        </div>
                                      </div>

                                      <div className="col-3">
                                        <div className="flex align-items-center">
                                          <Checkbox
                                            inputId="serviceoption3"
                                            name="serviceoptionss"
                                            value="Delivery"
                                            onChange={ServiceOptions}
                                            checked={serviceoptions.includes(
                                              "Delivery"
                                            )}
                                          />
                                          <label
                                            htmlFor="service3"
                                            className="ml-2"
                                          >
                                            Delivery
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <label className="ym-input-label mt-2">
                                    About Restaurant{" "}
                                    <b style={{ color: "red" }}>*</b>{" "}
                                  </label>
                                  <div className="flex flex-column mb-2">
                                    <InputTextarea
                                      id="about-the"
                                      placeholder="Type About The Restaurant..."
                                      value={resObj.about.join(".")}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                      ) => {
                                        About(e.target.value);
                                      }}
                                      rows={5}
                                      cols={30}
                                    />
                                  </div>
                                  <label className="ym-input-label mt-2">
                                    Why in YELDAM?{" "}
                                    <b style={{ color: "red" }}>*</b>{" "}
                                  </label>
                                  <div className="flex flex-column mb-2">
                                    <InputTextarea
                                      id="why-in"
                                      placeholder="Why Did You Choose This Restaurant?..."
                                      value={resObj.about.join(".")}
                                      onChange={(
                                        e: React.ChangeEvent<HTMLTextAreaElement>
                                      ) => WhyinYeldam(e.target.value)}
                                      rows={5}
                                      cols={30}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 lg:col-6 xl:col-6">
                                <div className="card mb-0 pb-0 pt-0 ym-add-card">
                                  <div className="row">
                                    <label className="ym-input-label">
                                      Youtube 
                                      {/* <b style={{ color: "red" }}>*</b>{" "} */}
                                    </label>
                                    <div className="flex flex-column mb-2">
                                      <InputText
                                        id="youtube"
                                        placeholder="Enter Youtube URL..."
                                        value={resObj.sourcelink}
                                        onChange={(e: any) =>
                                          setResObj({
                                            ...resObj,
                                            sourcelink: e.target.value,
                                          })
                                        }
                                        aria-describedby="username-help"
                                      />
                                    </div>
                                    <label className="ym-input-label">
                                      Zomoto 
                                      {/* <b style={{ color: "red" }}>*</b>{" "} */}
                                    </label>
                                    <div className="flex flex-column mb-2">
                                      <InputText
                                        id="zomato"
                                        placeholder="Enter Zomato URL..."
                                        value={resObj.zomatolink}
                                        onChange={(e: any) =>
                                          setResObj({
                                            ...resObj,
                                            zomatolink: e.target.value,
                                          })
                                        }
                                        aria-describedby="username-help"
                                      />
                                    </div>
                                    <label className="ym-input-label">
                                      Swiggy 
                                      {/* <b style={{ color: "red" }}>*</b>{" "} */}
                                    </label>
                                    <div className="flex flex-column mb-2">
                                      <InputText
                                        id="swiggy"
                                        placeholder="Enter Swiggy URL..."
                                        value={resObj.swiggylink}
                                        onChange={(e: any) =>
                                          setResObj({
                                            ...resObj,
                                            swiggylink: e.target.value,
                                          })
                                        }
                                        aria-describedby="username-help"
                                      />
                                    </div>
                                    <label className="ym-input-label">
                                      Dineout 
                                      {/* <b style={{ color: "red" }}>*</b>{" "} */}
                                    </label>
                                    <div className="flex flex-column mb-2">
                                      <InputText
                                        id="dineout"
                                        placeholder="Enter Dineout URL..."
                                        value={resObj.dineoutlink}
                                        onChange={(e: any) =>
                                          setResObj({
                                            ...resObj,
                                            dineoutlink: e.target.value,
                                          })
                                        }
                                        aria-describedby="username-help"
                                      />
                                    </div>
                                    <label className="ym-input-label">
                                      Tags
                                    </label>
                                    <div className="flex flex-column mb-2 tags">
                                      <Chips
                                        value={resObj.tags}
                                        onChange={(e: any) => {
                                          setResObj({
                                            ...resObj,
                                            tags: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                    <div className="other-sources">
                                      <div className="d-flex flex-column  mb-2">
                                        <label className="ym-input-label">
                                          Source Name
                                        </label>
                                        <InputText
                                          value={sourceName}
                                          placeholder="Enter the source name Ex:Instagram,Twitter"
                                          onChange={(e: any) =>
                                            setSourceName(e.target.value)
                                          }
                                        />
                                      </div>
                                      <div className="d-flex flex-column  mb-2">
                                        <label className="ym-input-label">
                                          Source URL
                                        </label>
                                        <InputText
                                          value={sourceURL}
                                          placeholder="Enter the URL"
                                          onChange={(e: any) =>
                                            setSourceURL(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <footer className="footer">
              <div className="button-container">
                <br></br>
                <div className="row ml-auto button-container">
                  <Button
                    className="p-button-success ym-add-button"
                    label="Previous"
                    onClick={handlePrevious}
                    disabled={currentIndex === 0}
                  ></Button>

                  {currentIndex === 3 ? (
                    <Button
                      className="p-button-success ym-add-button"
                      label="Save changes"
                      onClick={SubmitResObj}
                      // disabled={currentIndex === 3}
                    ></Button>
                  ) : (
                    <Button
                      className="p-button-success ym-add-button"
                      label="Next"
                      onClick={handleNext}
                      // disabled={currentIndex === 3}
                    ></Button>
                  )}
                </div>
              </div>
            </footer>
          </section>
        </div>
      </main>
    </>
  );
}
