import { InputText } from "primereact/inputtext";
import React, { useRef, useState } from "react";
import { Calendar } from "primereact/calendar";
import { InputTextarea } from "primereact/inputtextarea";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import { Button } from "primereact/button";
import { SubmitEvent } from "../../../api/addRestarant.api";
import { Toast } from "primereact/toast";
import "./addEvents.css";
import foodeventsimg from "../../../assets/images/foodevents.png"
import Loader from "../../../components/ui/loader/loader.components";
import { Dropdown } from "primereact/dropdown";
type resObj = {
  eventtitle: string;
  eventstartdate: string;
  eventenddate: string;
  city: string;
  description: string[];
  referencelink: string;
};
const Addevents = () => {
  const [isAdding, setIsadding] = useState(false);
  const [eventTitleError, setEventTitleError] = useState("");
  const [eventDateError, setEventDateError] = useState("");
  const [cityError, setCityError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [referenceLinkError, setReferenceLinkError] = useState("");
  const initialState = {
    eventtitle: "",
    eventstartdate: "",
    eventenddate: "",
    city: "",
    description: [],
    referencelink: "",
  };
  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
        life: 2000,
      });
    } else {
      console.log("toast issue");
    }
  };
  const [description, setDescription] = useState<string[]>([]);

  const Description = (e: any) => {
    const description = e.target.value;
    const descript = description.split(".");
    setDescription(descript);
    setResObj({ ...resObj, description: descript });
  };
  const clearFields = () => {
    setResObj(initialState);
    setDescription([]);
  };
  const [resObj, setResObj] = useState<resObj>(initialState);
  const SubmitEventObj = async (e: any) => {
    try {
      setIsadding(true);
      setEventTitleError("");
      setEventDateError("");
      setCityError("");
      setDescriptionError("");
      setReferenceLinkError("");
      if (
        !resObj.eventtitle ||
        !resObj.eventstartdate ||
        !resObj.eventenddate ||
        !resObj.city ||
        resObj.description.length === 0 ||
        !resObj.referencelink
      ) {
        if (!resObj.eventtitle) setEventTitleError("Event Title is required");
        if (!resObj.eventstartdate) setEventDateError("Event start Date is required");
        if (!resObj.eventenddate) setEventDateError("Event end Date is required")
        if (!resObj.city) setCityError("City is required");
        if (resObj.description.length === 0)
          setDescriptionError("Description is required");
        if (!resObj.referencelink)
          setReferenceLinkError("Reference Link is required");
        setIsadding(false);
        return;
      }

      const formattedStartDate = formatDateString(resObj.eventstartdate);
      if (formattedStartDate === "") {
        setEventDateError("Invalid start date format");
        setIsadding(false);
        return;
      }

      const formattedEndDate = formatDateString(resObj.eventenddate);
      if (formattedEndDate === "") {
        setEventDateError("Invalid end date format");
        setIsadding(false);
        return;
      }

      const startDateResponse = await SubmitEvent({
        ...resObj,
        eventstartdate: formattedStartDate,
        eventenddate: formattedEndDate,
      });

      if (startDateResponse.status === 201) {
        setIsadding(false);
        show("Event Submitted Successfully", "info", "Info");
        setResObj(initialState);
        setDescription([]);
      } else {
        setIsadding(false);
        show("Error: Unexpected response status", "error", "Error");
      }
    } catch (error) {
      setIsadding(false);
      console.error("Error from backend API:", error);
      show("Error: Failed to submit event", "error", "Error");
    }
  };

  const formatDateString = (dateString: string): string => {
    const formattedDate = new Date(dateString);
    if (isNaN(formattedDate.getTime())) return "";

    const day = String(formattedDate.getDate()).padStart(2, "0");
    const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
    const year = String(formattedDate.getFullYear());
    return `${day}/${month}/${year}`;
  };

  const cities = [
    { label: 'Bangalore', value: 'Bangalore' },
    { label: 'Chennai', value: 'Chennai' },
    { label: 'Delhi', value: 'Delhi' },
    { label: 'Hyderabad', value: 'Hyderabad' },
    { label: 'Mumbai', value: 'Mumbai' },
  ];

  return (
    <>
      {!isAdding ? "" : <Loader />}
      <main>
        <Toast ref={toast}></Toast>
        <div>
          <Navbar />
          <section className="page-content" style={{ backgroundColor: "#F5F5F5" }}>
            <div className="d-flex justify-content-center align-items-center">
              <div className="col d-flex justify-content-center align-items-center">
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "85vh" }}
                >
                  <img src={foodeventsimg} alt="foodevents" />

                </div>
              </div>


              <div className="col">
                <div
                  className="card details-card"
                  style={{
                    width: "calc(100vw - 60vw)",
                    height: "auto",
                    borderRadius: "12px",
                  }}
                ><div className="row">
                    <div className="row">
                      <div className="col">
                        <div className="col">
                          <div
                            className="fw-500 fs-20 ym-text-link-primary"
                            style={{ fontFamily: "rubik" }}
                          >
                            Add Food Events
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" d-flex justify-content-center align-items-center">
                      <div className="col-12 d-flex flex-column gap-1">
                        <div className="d-flex flex-column gap-2  mb-2">
                          <label className="add-event-head">
                            Title
                          </label>
                          <InputText
                            value={resObj.eventtitle}
                            placeholder="Enter Event title"
                            onChange={(e: any) => {
                              setResObj({ ...resObj, eventtitle: e.target.value });
                              setEventTitleError("");
                            }}
                          />
                          {eventTitleError && (
                            <small className="text-danger">{eventTitleError}</small>
                          )}
                        </div>
                        <div className="d-flex gap-2">
                          <div className="col-6 d-flex flex-column gap-2 mb-2 calender">
                            <label className="add-event-head">
                              Start Date
                            </label>
                            <Calendar
                              value={resObj.eventstartdate}
                              onChange={(e: any) => {
                                setResObj({ ...resObj, eventstartdate: e.target.value });
                                setEventDateError("");
                              }}
                              placeholder="Enter Event Date "
                              showIcon
                            />
                            {eventDateError && (
                              <small className="text-danger">{eventDateError}</small>
                            )}
                          </div>

                          <div className="d-flex col-6 flex-column gap-2 mb-2 calender">
                            <label className="add-event-head">
                              End Date
                            </label>
                            <Calendar
                              value={resObj.eventenddate}
                              onChange={(e: any) => {
                                setResObj({ ...resObj, eventenddate: e.target.value });
                                setEventDateError("");
                              }}
                              placeholder="Enter Event Date "
                              showIcon
                            />
                            {eventDateError && (
                              <small className="text-danger">{eventDateError}</small>
                            )}
                          </div>

                        </div>

                        <div className="d-flex flex-column gap-2 mb-2">
                          <label className="add-event-head">
                            City
                          </label>
                          <Dropdown
                            value={resObj.city}
                            options={cities}
                            onChange={(e) => {
                              setResObj({ ...resObj, city: e.value });
                              setCityError("");
                            }}
                            placeholder="Select City"
                          />
                          {cityError && (
                            <small className="text-danger">{cityError}</small>
                          )}
                        </div>

                        <div>
                          <label className="add-event-head">
                            Description
                          </label>
                          <div className="d-flex flex-column  mb-2">
                            <InputTextarea
                              id="description"
                              className="ym-address-input"
                              value={description.join(". ")}
                              onChange={(e: any) => Description(e)}
                              placeholder="Enter Description Of Event"
                              rows={4}
                              cols={30}
                            />
                            {descriptionError && (
                              <small className="text-danger">{descriptionError}</small>
                            )}
                          </div>
                        </div>

                        <div className="d-flex flex-column gap-2  mb-2">
                          <label className="add-event-head">
                            Reference Link
                          </label>
                          <InputText
                            value={resObj.referencelink}
                            placeholder="Enter References Link"
                            onChange={(e: any) => {
                              setResObj({ ...resObj, referencelink: e.target.value });
                              setReferenceLinkError("");
                            }}
                          />
                          {referenceLinkError && (
                            <small className="text-danger">
                              {referenceLinkError}
                            </small>
                          )}
                        </div>
                        <div className="row">
                          <div className="col">
                            <div className=" d-flex justify-content-between">
                              <Button
                                label="Cancel"
                                style={{
                                  width: "190px",
                                  height: "40px",
                                  marginRight: "8px",
                                }}
                                type="submit"
                                severity="success"
                                outlined
                                rounded
                                onClick={clearFields}
                              />
                              <Button
                                label="Submit"
                                style={{
                                  width: "190px",
                                  height: "40px",
                                  background: "#36A41D",
                                }}
                                onClick={(e) => SubmitEventObj(e)}
                                type="submit"
                                severity="success"
                                rounded
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default Addevents;
