import HttpInstance from "./http.api";
import Cookies from "js-cookie";
import { useCallback } from "react";
import jwt_decode from "jwt-decode";

type Login={
    email:string
}

type otpObj={
    otp:number
}
export enum SocialEnum {
    microsoft,
    google,
    facebook,
    amazon,
}

export type SocialEnumKeyType = keyof typeof SocialEnum;
export const AgentLogin = (LoginObj:Login)=>{

  const RespData=  HttpInstance.post("/agent/agentlogin",LoginObj);
    return RespData
}

// export const RefreshToken =async ()=>{
//     const rt = Cookies.get("refreshToken")
//     await HttpInstance.get("/agent/refreshtoken?refreshToken="+rt).then((data)=>{
//         Cookies.remove("accessToken");
//         Cookies.set("accessToken",data.data.data)
//     })
// }

export const signIn = async (provider: SocialEnumKeyType, profile: any) => {
        const email = getEmail(provider, profile);
        const fullName = getFullName(provider, profile);
        try {
            const respObj = await AgentLogin({email:email.toLowerCase()})
            console.log(respObj);
            // localStorage.setItem("accessToken",respObj.data.data.accessToken)
            // localStorage.setItem("refreshToken",respObj.data.data.refreshToken)
            return respObj.status
        } catch (err: any) {
            console.log('error socialSignIn', err);
        }
    }

export const authService = {
    async socialSignIn(data:any) {
        const { data: responseData } = await HttpInstance.post('agent/agentlogin', data);
        return responseData;
    }, 
};

export const verifyOtp = async (otp:otpObj) =>  {
    try {
        const respObj = await HttpInstance.post('agent/otp/verify', otp);
        console.log("respObj verifyOtp",respObj);
        localStorage.setItem("accessToken",respObj.data.data.accessToken)
        localStorage.setItem("refreshToken",respObj.data.data.refreshToken)
        return respObj.status
    } catch (err: any) {
        console.log('error otpverify', err);
    }
};

function getEmail(provider: SocialEnumKeyType, profile: any) {
    return SocialEnum[provider] === SocialEnum.google
        ? profile.email
        : SocialEnum[provider] === SocialEnum.microsoft
            ? profile.userPrincipalName
            : SocialEnum[provider] === SocialEnum.facebook
                ? profile.email
                : '';
}

function getFullName(provider: SocialEnumKeyType, profile: any) {
    return SocialEnum[provider] === SocialEnum.google
        ? { firstName: profile.family_name, lastName: profile.given_name }
        : SocialEnum[provider] === SocialEnum.microsoft
            ? { firstName: profile.givenName, lastName: profile.surname }
            : SocialEnum[provider] === SocialEnum.facebook
                ? { firstName: profile.first_name, lastName: profile.last_name }
                : { firstName: '', lastName: '' }

}



export const getToken:any = () => {
    return localStorage.getItem("accessToken")
}

export const setToken = (token:any) => {
    localStorage.setItem("accessToken", token)
}

export const isTokenExpired = () => {
    const decoded:any = jwt_decode(getToken())
    //console.log(decoded);
    return decoded.iat > Date.now()
}


export const getRefreshedToken =async ()=>{
    const rt = localStorage.getItem("refreshToken")
    await HttpInstance.get("/agent/refreshtoken?refreshToken="+rt).then((data)=>{
        localStorage.setItem("accessToken",data.data.data)
    })
}

export const refreshToken = async () => {
    const newToken = await getRefreshedToken()
    setToken(newToken)
}

export const GetAgentById = ()=>{
    const RespData = HttpInstance.get("/agent/getagentbyid")
    return RespData
 }

export const logout = () => {
    localStorage.clear(); //for localStorage
    sessionStorage.clear(); //for sessionStorage
  };