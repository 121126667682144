import React, { useState } from 'react'
import "./Restaurant.photos.component.css"
import { Image } from "primereact/image";
import {sas} from "../../config/constants"

type props = {
    images: string[];
}

const Restaurantphotos: React.FC<props> = ({ images }) => {

    return (
        <div>
            {/* <h6>Photos</h6><br /> */}
            <div className='d-flex'>
                {images.map((item) => (
                    <div className="rest-img">
                        {/*@ts-ignore   */}
                        <Image src={item+"?"+sas} key={item} preview />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Restaurantphotos
