import React from 'react'
import { Image } from "primereact/image";
import "./storeImages.component.css"
import {sas} from "../../config/constants"

type props = {
    storeimages: string[];
}

const StoreImages: React.FC<props> = ({storeimages}) => {
  return (
    <div>
     
      <div className="d-flex gap-2">
      {storeimages.map((item) => (
                    <div className="">
                        {/*@ts-ignore   */}
                        <Image src={item+"?"+sas} alt="Image" preview className="store-img"/>
                       
                    </div>
                ))}
      </div>
    </div>
  )
};

export default StoreImages