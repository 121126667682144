import React, { useEffect, useRef, useState } from "react";
import "./EditStore.css";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import RestaurantTimings from "../../../components/RestaurantTimings/timings.component";
import DropFileInput from "../../../pages/restaurants/drop-file-input/drop-file-input";
import { sas } from "../../../config/constants";
import { Button } from "primereact/button";
import { EditStoreDetails } from "../../../api/editOrganicstore.api";
import { Toast } from "primereact/toast";

const EditStore: React.FC<any> = ({ storedataobj,setEditVisible }) => {
  const [storethumbnailImages, setstoreThumbnailImages] = useState<any[]>([]);
  const [storeimages, setStoreImages] = useState<any[]>([]);
  const [storeobj, setStoreobj] = useState<any>({ ...storedataobj });
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();

  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];
  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });

  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };
  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };
  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<
    Record<string, string[]>
  >(initialSelectedTimings);
  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter(
        (day) => checkedDays[day]
      );

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);

        // Directly set the updatedTimings without wrapping it in an array
        //setResObj({ ...resObj, timings: updatedTimings });

        return updatedTimings;
      });
    }
  };

  const timingsJSON = JSON.stringify(selectedTimings);
  const handleStoretypeChange = (value: any) => {
    const updatedstoretype = storeobj.storetype.includes(value)
      ? storeobj.storetype.filter((pref: any) => pref !== value)
      : [...storeobj.storetype, value];

    setStoreobj({ ...storeobj, storetype: updatedstoretype });
  };
  const handleServicetypeChange = (value: any) => {
    const updatedservicetype = storeobj.servicetype.includes(value)
      ? storeobj.servicetype.filter((pref: any) => pref !== value)
      : [...storeobj.servicetype, value];

    setStoreobj({ ...storeobj, servicetype: updatedservicetype });
  };
  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const handleDeleteClick = (index: any, purpose: any) => {
    const updatedstoreObj = { ...storeobj };
    if (purpose === "storeThumbnailImages") {
      const updatedThumbnails = [...storeobj.thumbnails];
      updatedThumbnails.splice(index, 1);
      updatedstoreObj.thumbnails = updatedThumbnails;
    } else if (purpose === "Storeimages") {
      const updatedImages = [...updatedstoreObj.storeimages];
      updatedImages.splice(index, 1);
      updatedstoreObj.storeimages = updatedImages;
    }

    setStoreobj(updatedstoreObj);
  };

  const handleEditStore = async () => {
    if (storeobj) {
      const formData = new FormData();
      storeobj.storetype.forEach((store: string, index: number) => {
        formData.append(`storetype[${index}]`, store);
      });
      storeobj.servicetype.forEach((service: string, index: number) => {
        formData.append(`servicetype[${index}]`, service);
      });
      formData.append("storename", storeobj.storename);
      formData.append("phoneno", storeobj.phoneno);
      formData.append("address", storeobj.address);
      formData.append("longitude", storeobj.location.coordinates[0]);
      formData.append("latitude", storeobj.location.coordinates[1]);
      formData.append("city", storeobj.city);
      formData.append("district", storeobj.district);
      formData.append("state", storeobj.state);
      formData.append("country", storeobj.country);
      formData.append("pincode", storeobj.pincode);
      storeobj.storeimages.forEach((storeimage: File, index: number) => {
        formData.append(`storeimages`, storeimage);
      });

      storeobj.thumbnails.forEach((thumbnail: File, index: number) => {
        formData.append(`thumbnailimages`, thumbnail);
      });
      if (JSON.stringify(selectedTimings) !== JSON.stringify(initialSelectedTimings)) {
        formData.append("timings", JSON.stringify(selectedTimings));
      }

      try {
        const response = await EditStoreDetails(storeobj._id, formData);
        console.log("Updated restaurant:", response);
        show("Organicstore Updated Successfully", "success", "Success");
        setEditVisible(false);
      } catch (error) {
        console.error("Error updating restaurant:", error);
      }
    }
  };

  console.log("Updated store Object:", storeobj);
  return (
    <div className="p-2 d-flex  gap-2">
      <Toast ref={toast} position="top-right" />
      <ScrollPanel>
        <div className="d-flex flex-column gap-2">
          <div className="row gap-1">
            <label className="ym-input-label">
              Organic Store Name <b style={{ color: "red" }}>*</b>
            </label>
            <div className="flex flex-column">
              <InputText
                // placeholder="Enter Restaurant Name"
                maxLength={50}
                value={storeobj.storename}
                onChange={(e) => {
                  setStoreobj({
                    ...storeobj,
                    storename: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row gap-1">
            <label className="ym-input-label">
              Phone Number <b style={{ color: "red" }}>*</b>
            </label>
            <div className="flex flex-column">
              <InputText
                // placeholder="Enter Restaurant Name"
                maxLength={50}
                value={storeobj.phoneno}
                onChange={(e) => {
                  setStoreobj({
                    ...storeobj,
                    phoneno: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row gap-1">
            <label className="ym-input-label">
              Email <b style={{ color: "red" }}>*</b>
            </label>
            <div className="flex flex-column">
              <InputText
                // placeholder="Enter Restaurant Name"
                maxLength={50}
                value={storeobj.email}
                onChange={(e) => {
                  setStoreobj({
                    ...storeobj,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row gap-1">
            <label className="ym-input-label">
              Website <b style={{ color: "red" }}>*</b>
            </label>
            <div className="flex flex-column">
              <InputText
                // placeholder="Enter Restaurant Name"
                maxLength={50}
                value={storeobj.website}
                onChange={(e) => {
                  setStoreobj({
                    ...storeobj,
                    website: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-flex flex-column gap-1">
              <div className="d-flex align-items-baseline">
                <label className="ym-input-label">
                  Latitude
                  <b style={{ color: "red" }}>*</b>
                </label>
                <i
                  className="bi bi-info-circle"
                  title="Enter the valid latitude"
                  id="icon"
                ></i>
              </div>
              <div className="flex flex-column">
                <InputText
                  maxLength={30}
                  value={storeobj.location.coordinates[1]}
                  name="latitude"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setStoreobj({
                      ...storeobj,
                      location: {
                        ...storeobj.location,
                        coordinates: [
                          storeobj.location.coordinates[0],
                          parseFloat(value),
                        ],
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column gap-1">
              <div className="d-flex align-items-baseline">
                <label className="ym-input-label">
                  Longitude
                  <b style={{ color: "red" }}>*</b>
                </label>
                <i
                  className="bi bi-info-circle"
                  title="Enter the valid longitude"
                  id="icon"
                ></i>
              </div>
              <div className="flex flex-column">
                <InputText
                  maxLength={30}
                  value={storeobj.location.coordinates[0]}
                  name="longitude"
                  onChange={(e) => {
                    const { name, value } = e.target;
                    setStoreobj({
                      ...storeobj,
                      location: {
                        ...storeobj.location,
                        coordinates: [
                          parseFloat(value),
                          storeobj.location.coordinates[1],
                        ],
                      },
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row gap-1">
            <label className="ym-input-label">
              Address <b style={{ color: "red" }}>*</b>
            </label>
            <div className="flex flex-column">
              <InputTextarea
                maxLength={150}
                value={storeobj.address}
                onChange={(e) => {
                  setStoreobj({
                    ...storeobj,
                    address: e.target.value,
                  });
                }}
                rows={5}
                cols={30}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-flex flex-column gap-1">
              <div className="d-flex align-items-baseline">
                <label className="ym-input-label">
                  City
                  <b style={{ color: "red" }}>*</b>
                </label>
                <i
                  className="bi bi-info-circle"
                  title="Enter the vaild logitude"
                  id="icon"
                ></i>
              </div>
              <div className="flex flex-column">
                <InputText
                  maxLength={30}
                  value={storeobj.city}
                  onChange={(e) => {
                    setStoreobj({
                      ...storeobj,
                      city: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column gap-1">
              <div className="d-flex align-items-baseline">
                <label className="ym-input-label">
                  District
                  <b style={{ color: "red" }}>*</b>
                </label>
                <i
                  className="bi bi-info-circle"
                  title="Enter the vaild logitude"
                  id="icon"
                ></i>
              </div>
              <div className="flex flex-column">
                <InputText
                  maxLength={30}
                  value={storeobj.district}
                  onChange={(e) => {
                    setStoreobj({
                      ...storeobj,
                      district: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 d-flex flex-column gap-1">
              <div className="d-flex align-items-baseline">
                <label className="ym-input-label">
                  State
                  <b style={{ color: "red" }}>*</b>
                </label>
                <i
                  className="bi bi-info-circle"
                  title="Enter the vaild logitude"
                  id="icon"
                ></i>
              </div>
              <div className="flex flex-column">
                <InputText
                  maxLength={30}
                  value={storeobj.state}
                  onChange={(e) => {
                    setStoreobj({
                      ...storeobj,
                      state: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6 d-flex flex-column gap-1">
              <div className="d-flex align-items-baseline">
                <label className="ym-input-label">
                  Country
                  <b style={{ color: "red" }}>*</b>
                </label>
                <i
                  className="bi bi-info-circle"
                  title="Enter the vaild logitude"
                  id="icon"
                ></i>
              </div>
              <div className="flex flex-column">
                <InputText
                  maxLength={30}
                  value={storeobj.country}
                  onChange={(e) => {
                    setStoreobj({
                      ...storeobj,
                      country: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row gap-1">
            <label className="ym-input-label">
              PinCode <b style={{ color: "red" }}>*</b>
            </label>
            <div className="flex flex-column">
              <InputText
                // placeholder="Enter Restaurant Name"
                maxLength={50}
                value={storeobj.pincode}
                onChange={(e) => {
                  setStoreobj({
                    ...storeobj,
                    pincode: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="row">
            <label className="ym-input-label mt-2">Store Type</label>
            <div className="row ">
              <div className="col-lg-3">
                <div className="flex align-items-center gap-2">
                  <Checkbox
                    inputId="storetype1"
                    name="storetype"
                    value="Groceries"
                    onChange={(e) => handleStoretypeChange(e.target.value)}
                    checked={(storeobj.storetype as string[]).includes(
                      "Groceries"
                    )}
                  />
                  <label htmlFor="preference1" className="">
                    Groceries
                  </label>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="storetype2"
                    name="storetype"
                    value="Vegetables"
                    onChange={(e) => handleStoretypeChange(e.target.value)}
                    checked={(storeobj.storetype as string[]).includes(
                      "Vegetables"
                    )}
                  />
                  <label htmlFor="preference2" className="ml-2">
                    Vegetables
                  </label>
                </div>
              </div>

              <div className="col-lg-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="storetype3"
                    name="storetype"
                    value="Fruits"
                    onChange={(e) => handleStoretypeChange(e.target.value)}
                    checked={(storeobj.storetype as string[]).includes(
                      "Fruits"
                    )}
                  />
                  <label htmlFor="storetype3" className="ml-2">
                    Fruits
                  </label>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="storetype3"
                    name="storetype"
                    value="Dairy"
                    onChange={(e) => handleStoretypeChange(e.target.value)}
                    checked={(storeobj.storetype as string[]).includes("Dairy")}
                  />
                  <label htmlFor="storetype4" className="ml-2">
                    Dairy
                  </label>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="storetype5"
                    name="storetype"
                    value="Nuts"
                    onChange={(e) => handleStoretypeChange(e.target.value)}
                    checked={(storeobj.storetype as string[]).includes("Nuts")}
                  />
                  <label htmlFor="storetype5" className="ml-2">
                    Nuts
                  </label>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="storetype5"
                    name="storetype"
                    value="Poultry"
                    onChange={(e) => handleStoretypeChange(e.target.value)}
                    checked={(storeobj.storetype as string[]).includes(
                      "Poultry"
                    )}
                  />
                  <label htmlFor="storetype5" className="ml-2">
                    Poultry
                  </label>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="storetype5"
                    name="storetype"
                    value="Oils"
                    onChange={(e) => handleStoretypeChange(e.target.value)}
                    checked={(storeobj.storetype as string[]).includes("Oils")}
                  />
                  <label htmlFor="storetype5" className="ml-2">
                    Oils
                  </label>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="storetype5"
                    name="storetype"
                    value="Millets"
                    onChange={(e) => handleStoretypeChange(e.target.value)}
                    checked={(storeobj.storetype as string[]).includes(
                      "Millets"
                    )}
                  />
                  <label htmlFor="storetype5" className="ml-2">
                    Millets
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <label className="ym-input-label mt-2">service Type</label>
            <div className="row">
              <div className="col-lg-4">
                <div className="flex align-items-center gap-2">
                  <Checkbox
                    inputId="service1"
                    name="servicestype"
                    value="Delivery"
                    onChange={(e) => handleServicetypeChange(e.target.value)}
                    checked={(storeobj.servicetype as string[]).includes(
                      "Delivery"
                    )}
                  />
                  <label htmlFor="service1" className="ml-2">
                    Delivery
                  </label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="service2"
                    name="servicestype"
                    value="In-store Shopping"
                    onChange={(e) => handleServicetypeChange(e.target.value)}
                    checked={(storeobj.servicetype as string[]).includes(
                      "In-store Shopping"
                    )}
                  />
                  <label htmlFor="service2" className="ml-2">
                    In-store Shopping
                  </label>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="flex align-items-center">
                  <Checkbox
                    inputId="service3"
                    name="servicestype"
                    value="In-store Pickup"
                    onChange={(e) => handleServicetypeChange(e.target.value)}
                    checked={(storeobj.servicetype as string[]).includes(
                      "In-store Pickup"
                    )}
                  />
                  <label htmlFor="service3" className="ml-2">
                    In-store Pickup
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* Timings */}
            <div className="row m-0">
              <div className="col-12">
                <div className="child-lef t" id="Timings">
                  <span>Timings</span>

                  <div className="">
                    <div className="row">
                      <div className="row">
                        {daysOfWeek.map((day: string) => (
                          <div className="col-lg-3" key={day}>
                            <div className="d-flex align-items-center">
                              <Checkbox
                                inputId={`day${day}`}
                                name="day"
                                value={day}
                                checked={checkedDays[day]}
                                onChange={() => handleCheckboxChange(day)}
                              />
                              <label htmlFor={`day${day}`} className="ml-1">
                                {day}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className=" d-flex from-to gap-2 align-items-center mb-3">
                        <div className="from d-flex flex-column col-lg-3">
                          <span className="restaurant-name">From</span>
                          <Dropdown
                            style={{
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            id="eater"
                            placeholder="Select"
                            value={fromtimes}
                            options={Timings}
                            onChange={onFromTimeChange}
                          />
                        </div>
                        <div className="from d-flex flex-column col-3">
                          <span className="restaurant-name">To</span>
                          <Dropdown
                            style={{
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            id="eater"
                            placeholder="Select"
                            value={totimes}
                            options={Timings}
                            onChange={onToTimeChange}
                          />
                        </div>
                        <div className="mt-3">
                          <button
                            className="add-button"
                            onClick={handleAddTiming}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-start align-items-center mt-3">
                        <div className="col-8">
                          {Object.entries(selectedTimings).map(
                            ([day, timing]) => (
                              <div
                                className="Sunday-timing col-12 gap-3 d-flex justify-content-between p-2 align-items-center mt-3"
                                key={day}
                              >
                                <p className="col-3 m-0 day">{day}</p>
                                <div className="row col-9 d-flex">
                                  <div className="col gap-1">
                                    {timing.map((time, index) => (
                                      <div className="col d-flex justify-content-between align-items-center p-0">
                                        <div>
                                        <span className="time" key={index}>
                                          {time}
                                        </span>
                                        </div>
                                     
                                        <div>
                                        <i
                                          className="bi bi-trash-fill"
                                          style={{
                                            color: "#818181",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            const updatedTimings = {
                                              ...selectedTimings,
                                            };
                                            updatedTimings[day] =
                                              updatedTimings[day].filter(
                                                (t) => t !== time
                                              );

                                            // Check if there are no times left, delete the entire day
                                            if (
                                              updatedTimings[day].length === 0
                                            ) {
                                              delete updatedTimings[day];
                                            }

                                            setSelectedTimings(updatedTimings);
                                          }}
                                        ></i>
                                        </div>
                                        
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-start align-items-center mt-3">
                        <RestaurantTimings
                          timing={storeobj.timings}
                          editable={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* images */}
          {/* <div className="row m-0">
            <div className="col-12">
              <div className="child-left" id="uploadimages">
                <div className="">
                  <div className="">
                    <label className="ym-input-label">
                      Thumbnail Image
                      <b style={{ color: "red" }}>*</b>
                    </label>
                    <DropFileInput
                      uploadedFiles={storethumbnailImages}
                      setUploadedFiles={setstoreThumbnailImages}
                      resObj={storeobj}
                      setResObj={setStoreobj}
                      purpose="storeThumbnailImages"
                    />
                  </div>
                  <div className="grid mt-2 ml-1 gap-3">
                    {Array.isArray(storeobj.thumbnailimages) &&
                      storeobj.thumbnailimages.every(
                        (item: any) => typeof item === "string"
                      ) &&
                      storeobj.thumbnailimages?.map((item: any, index: any) => {
                        return (
                          <div
                            id="Imagecrd"
                            style={{ position: "relative" }}
                            key={index}
                          >
                            <img
                              className="uploadimage"
                              key={item}
                              src={item + "?" + sas}
                              alt=""
                            />

                            <i
                              className="pi pi-trash icontrash"
                              onClick={() =>
                                handleDeleteClick(index, "storeThumbnailImages")
                              }
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                margin: "8px",
                              }}
                            ></i>
                          </div>
                        );
                      })}
                  </div>

                  <div className="row gap-2 pt-3">
                    <label className="get-input-label">
                      Store Images <b style={{ color: "red" }}>*</b>
                    </label>
                    <div>
                      <DropFileInput
                        uploadedFiles={storeimages}
                        setUploadedFiles={setStoreImages}
                        resObj={storeobj}
                        setResObj={setStoreobj}
                        purpose="Storeimages"
                      />
                    </div>
                  </div>

                  <div className="grid mt-2 ml-1 gap-3">
                    {Array.isArray(storeobj.storeimages) &&
                      storeobj.storeimages.every(
                        (item: any) => typeof item === "string"
                      ) &&
                      storeobj.storeimages?.map((item: any, index: any) => {
                        return (
                          <div
                            id="Imagecrd"
                            style={{ position: "relative" }}
                            key={index}
                          >
                            <img
                              className="uploadimage"
                              key={item}
                              src={item + "?" + sas}
                              alt=""
                            />

                            <i
                              className="pi pi-trash icontrash"
                              onClick={() =>
                                handleDeleteClick(index, "Storeimages")
                              }
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                margin: "8px",
                              }}
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div> */}
         <div className="row m-0">
            <div className="col-12">
              <div className="child-left" id="uploadimages">
                <div className="">
                  <div className="">
                    <label className="ym-input-label">
                      Thumbnail Image
                      <b style={{ color: "red" }}>*</b>
                    </label>
                    <DropFileInput
                      uploadedFiles={storethumbnailImages}
                      setUploadedFiles={setstoreThumbnailImages}
                      resObj={storeobj}
                      setResObj={setStoreobj}
                      purpose="storeThumbnailImages"
                    />
                  </div>
                  <div className="grid mt-2 ml-1 gap-3">
                    {Array.isArray(storeobj.thumbnails) &&
                      storeobj.thumbnails.every(
                        (item: any) => typeof item === "string"
                      ) &&
                      storeobj.thumbnails?.map((item: any, index: any) => {
                        return (
                          <div
                            id="Imagecrd"
                            style={{ position: "relative" }}
                            key={index}
                          >
                            <img
                              className="uploadimage"
                              key={item}
                              src={item + "?" + sas}
                              alt=""
                            />

                            <i
                              className="pi pi-trash icontrash"
                              onClick={() =>
                                handleDeleteClick(index, "storeThumbnailImages")
                              }
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                margin: "8px",
                              }}
                            ></i>
                          </div>
                        );
                      })}
                  </div>

                  <div className="row gap-2 pt-3">
                    <label className="get-input-label">
                      Store Images <b style={{ color: "red" }}>*</b>
                    </label>
                    <div>
                      <DropFileInput
                        uploadedFiles={storeimages}
                        setUploadedFiles={setStoreImages}
                        resObj={storeobj}
                        setResObj={setStoreobj}
                        purpose="Storeimages"
                      />
                    </div>
                  </div>

                  <div className="grid mt-2 ml-1 gap-3">
                    {Array.isArray(storeobj.storeimages) &&
                      storeobj.storeimages.every(
                        (item: any) => typeof item === "string"
                      ) &&
                      storeobj.storeimages?.map((item: any, index: any) => {
                        return (
                          <div
                            id="Imagecrd"
                            style={{ position: "relative" }}
                            key={index}
                          >
                            <img
                              className="uploadimage"
                              key={item}
                              src={item + "?" + sas}
                              alt=""
                            />

                            <i
                              className="pi pi-trash icontrash"
                              onClick={() =>
                                handleDeleteClick(index, "Storeimages")
                              }
                              style={{
                                position: "absolute",
                                top: "0",
                                right: "0",
                                margin: "8px",
                              }}
                            ></i>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* update button */}
          <div className="d-flex justify-content-end">
            <Button
              label="Save Changes"
              // icon="pi pi-check"
              className="p-button-rounded d-flex  p-button-success change-btn-login"
              autoFocus
              onClick={handleEditStore}
            />
          </div>
        </div>
      </ScrollPanel>
    </div>
  );
};

export default EditStore;
