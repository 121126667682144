import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from "../config/constants";

export const EditStoreDetails = async (id:string, formData:any) => {
    const response = await axios.patch(
      `${apiUrl}/organicstore/updateorganicstore?id=${id}`,
      formData,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("accessToken"),
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } 