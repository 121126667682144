import { Button } from 'primereact/button';
import React, { useRef, useState } from 'react';
import "./dietitianCustomStepper.component.css";
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useForm } from 'react-hook-form';
import DropFileInput from '../../pages/restaurants/drop-file-input/drop-file-input';
import { InputTextarea } from 'primereact/inputtextarea';
import { Checkbox } from 'primereact/checkbox';
import { SubmitDietitian } from "../../api/dietitians.api";
import { getRefreshedToken, isTokenExpired } from "../../api/auth.api";
import { Toast } from 'primereact/toast';
import Loader from '../ui/loader/loader.components';
import { classNames } from 'primereact/utils';

interface dietitianStepper {
  steps: string[];
  images: string[];
  stepdesc: string[];
}

type resObj = {
  dietitiantitle: string;
  dietitianname: string;
  gender: string;
  speciality: string[];
  degree: string;
  college: string;
  yearofcompletion: string;
  certification: string;
  experience: string;
  languages: string[];
  about: string[];
  services: string[];
  awards: string[];
  membership: string[];
  clinicname: string;
  latitude: string;
  longitude: string;
  clinicaddress: string;
  locality: string;
  city: string;
  district: string;
  state: string;
  country: string;
  location: string;
  pincode: string;
  phoneno: string;
  mode: string[];
  registereddietitian: string;
  email: string;
  fees: string;
  timings: any[];
  dietitianwebsite: string;
  dietitianfacebookurl: string;
  dietitianinstaurl: string;
  dietitianyoutubeurl: string;
  dietitiantwitterurl: string;
  clinicwebsite: string;
  clinicfacebookurl: string;
  clinicinstaurl: string;
  clinicyoutubeurl: string;
  clinictwitterurl: string;
  socailmedia: string;
  dietitianimages: string[];
  clinicimages: string[];
  certificationimages: string[];

}

const initialState = {
  dietitiantitle: "",
  dietitianname: "",
  gender: "",
  speciality: [],
  degree: "",
  college: "",
  yearofcompletion: "",
  certification: "",
  experience: "",
  languages: [],
  about: [],
  services: [],
  awards: [],
  membership: [],
  clinicname: "",
  clinicaddress: "",
  latitude: "",
  longitude: "",
  locality: "",
  city: "",
  district: "",
  state: "",
  country: "",
  location: "",
  pincode: "",
  phoneno: "",
  mode: [],
  registereddietitian: "",
  email: "",
  fees: "",
  timings: [],
  dietitianwebsite: "",
  dietitianfacebookurl: "",
  dietitianinstaurl: "",
  dietitianyoutubeurl: "",
  dietitiantwitterurl: "",
  clinicwebsite: "",
  clinicfacebookurl: "",
  clinicinstaurl: "",
  clinicyoutubeurl: "",
  clinictwitterurl: "",
  socailmedia: "",
  dietitianimages: [],
  clinicimages: [],
  certificationimages: [],

}

const DietitianCustomStepper: React.FC<dietitianStepper> = ({
  steps, images, stepdesc
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(Array(steps.length).fill(false));
  const [stepColors, setStepColors] = useState(Array(steps.length).fill(""));
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();
  const [resObj, setResObj] = useState<resObj>(initialState);
  const [isAdding, setIsadding] = useState(false);
  const toast = useRef<Toast>(null);
  const [medicalregistrationproof, setMedicalregistrationproof] = useState<any[]>([]);
  const [dietitianimage, setDietitianimage] = useState<any[]>([]);
  const [clinicimages, setClinicimages] = useState<any[]>([]);
  const [title, setTitle] = useState('');
  const [Gender, setGender] = useState('');
  const [specialities, setSpecialities] = useState<string[]>([]);
  const [moc, setMoc] = useState<string[]>([]);
  const [aboutdietitian, setaboutdietitian] = useState<any>(null);

  const defaultValues = {
    dietitianname: "",
    experience: "",
    fees: "",
    clinicname: "",
    latitude: "",
    longitude: "",
    address: "",

  }
  const {
    control,
    formState: { errors },
    handleSubmit,
    // getValues,
    reset,
  } = useForm({ defaultValues });
  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];

  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<
    Record<string, string[]>
  >(initialSelectedTimings);

  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter((day) => checkedDays[day]);

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);

        // Directly set the updatedTimings without wrapping it in an array
        //setResObj({ ...resObj, timings: updatedTimings });

        return updatedTimings;
      });
    }
  };

  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };

  const handleImageClick = (index: any) => {
    setActiveStep(index);
    setCompletedSteps(prevCompletedSteps => {
      const updatedSteps = [...prevCompletedSteps];
      updatedSteps[index] = true;
      return updatedSteps;
    });
  };
  const handleNext = () => {
    if (!validateFields()) {
      return;
    }
    const updatedCompletedSteps = [...completedSteps];
    updatedCompletedSteps[activeStep] = true;
    setCompletedSteps(updatedCompletedSteps);
    setActiveStep(activeStep + 1);
  };

  const validateFields = () => {
    const show = (message: any, severity: any, summary: any) => {
      console.log("ouside of toast");
      if (toast.current) {
        console.log("going in toast");
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      } else {
        console.log("toast issue");
      }
    };

    if (activeStep === 0) {
      // Validation for step 1
      if (
        resObj.dietitianname.length === 0 ||
        resObj.gender.length === 0 ||
        resObj.experience.length === 0 ||
        resObj.speciality.length === 0 ||
        resObj.languages.length === 0 ||
        resObj.fees.length === 0 ||
        resObj.mode.length === 0 ||
        resObj.about.length === 0
      ) {
        show("Please check all Mandatory filed", "warn", "Warn");
        return false;
      }
    } else if (activeStep === 1) {

      if (resObj.degree.length === 0

      ) {
        show("Please check all Mandatory filed", "warn", "Warn");
        return false;
      }
    } else if (activeStep === 2) {
      if (
        resObj.clinicname.length === 0 ||
        resObj.latitude.length === 0 ||
        resObj.longitude.length === 0 ||
        resObj.clinicaddress.length === 0 ||
        resObj.locality.length === 0 ||
        resObj.city.length === 0 ||
        resObj.district.length === 0 ||
        resObj.state.length === 0 ||
        resObj.country.length === 0 ||
        resObj.pincode.length === 0 ||
        resObj.phoneno.length === 0
      ) {
        show("Please check all Mandatory filed", "warn", "Warn");
        return false;
      }
    }
    else if (activeStep === 4) {
      if (
        resObj.dietitianimages.length === 0 ||
        resObj.clinicimages.length === 0
      ) {
        show("Please check all Mandatory filed", "warn", "Warn");
        return false;
      }
    }
    return true;
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    updateStepColors();
  };
  const updateStepColors = () => {
    const newStepColors = [...stepColors];
    newStepColors[activeStep] = "lightblue"; // Change this to the desired color
    setStepColors(newStepColors);
  };

  const titles = [
    { label: 'Dr.', value: 'Dr.' },
    { label: 'Mr.', value: 'Mr.' },
    { label: 'Ms.', value: 'Ms.' },
    { label: 'Mrs.', value: 'Mrs.' },
    { label: 'Dt.', value: 'Dt.' }
  ];
  const gender = ["Male", "Female"];
  const registercheck = ["Yes", "No"];

  const onTitleChange = (e: any) => {
    setTitle(e.value);
    setResObj({ ...resObj, dietitiantitle: e.value });
  };

  const genderselection = (e: any) => {
    const selectedGender = e.target.value;
    setGender(selectedGender);
    setResObj({ ...resObj, gender: selectedGender });
  }

  const redistereddoctor = (e: any) => {
    const selectedregister = e.target.value;
    setResObj({ ...resObj, registereddietitian: selectedregister });
  }

  const speciality = (e: any) => {
    let _special = [...specialities];

    if (e.checked) _special.push(e.value);
    else _special.splice(_special.indexOf(e.value), 1);

    setSpecialities(_special);
    setResObj({ ...resObj, speciality: _special });
  };

  const modofconsult = (e: any) => {
    let _modeof = [...moc];
    if (e.checked) _modeof.push(e.value);
    else _modeof.splice(_modeof.indexOf(e.value), 1);
    setMoc(_modeof);
    setResObj({ ...resObj, mode: _modeof });
  };

  const About = (e: any) => {
    const dietitianabout = e.target.value;
    const about = dietitianabout.split(".");
    setaboutdietitian(about);
    setResObj({ ...resObj, about: about });
  };

  const Language = (e: any) => {
    const dietitianlanguages = e.target.value;
    const language = dietitianlanguages.split(/[,\s]+/);
    setResObj({ ...resObj, languages: language });
  }

  const service = (e: any) => {
    const dietitianservice = e.target.value;
    const srvice = dietitianservice.split(",");
    setResObj({ ...resObj, services: srvice })
  }
  const handlemembership = (e: any) => {
    const dietitianmembership = e.target.value;
    const member = dietitianmembership.split(",");
    setResObj({ ...resObj, membership: member });
  }

  const awardsandrecog = (e: any) => {
    const dietitianawards = e.target.value;
    const awards = dietitianawards.split(",");
    setResObj({ ...resObj, awards: awards });
  }


  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });

  const ResetForm = () => {
    console.log("resetting the form");
    setIsadding(true);
    window.location.reload();
    setResObj(initialState);
  };

  const SubmitDietitianObj = () => {
    const show = (message: any, severity: any, summary: any) => {
      console.log("ouside of toast");
      if (toast.current) {
        console.log("going in toast");
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      } else {
        console.log("toast issue");
      }
    };
    setIsadding(true);
    console.log("ResObj ", resObj);
    setTimeout(async () => {
      if (
        resObj.dietitianname.length === 0 ||
        resObj.clinicaddress.length === 0 ||
        resObj.city.length === 0 ||
        resObj.state.length === 0 ||
        resObj.pincode.length === 0 ||
        resObj.country.length === 0 ||
        resObj.latitude.length === 0 ||
        resObj.longitude.length === 0 ||
        resObj.clinicname.length === 0 ||
        resObj.fees.length === 0 ||
        resObj.experience.length === 0 ||
        resObj.about.length === 0

      ) {
        show("Please check all Mandatory filed", "warn", "Warn");
        setIsadding(false);
      } else {
        const formData = new FormData();

        console.log("form checcc", formData);

        Object.entries(resObj).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              formData.append(key, item);
            });
          } else {
            formData.append(key, String(value));
          }

        });

        const timingsJSON = JSON.stringify(selectedTimings);

        formData.append("timings", timingsJSON);

        if (isTokenExpired()) {
          await getRefreshedToken();
        } else {
          await SubmitDietitian(formData)
            .then((response: any) => {
              if (response.status === 201) {
                setIsadding(false);
                console.log("in-to the success");
                show("Added Successful", "info", "Info");

                setTimeout(() => {
                  ResetForm();
                }, 3000);
              } else {
                setIsadding(false);
                console.log("in-to the invail");
                show("Invalid Access", "error", "Error");
              }
            })
            .catch((error: any) => {
              console.log(error.response.status);
              setIsadding(false);
              if (error.response.status === 403) {
                console.log("in-to the expired");
                show("Token Expired", "warn", "Warn");
              }
            });
        }
      }
    }, 1000);
  }

  return (
    <>
      {!isAdding ? "" : <Loader />}
      <div className="d-flex justify-content-center align-items-center">
        <div className="col d-flex justify-content-center align-items-center">
          <div className="d-flex justify-content-center align-items-center" style={{ height: "85vh" }}>
            <ol className="c-stepper">
              {steps.map((label, index) => (
                <li key={label} className={`c-stepper__item`}>
                  <img
                    className="c-stepper__item__img"
                    src={images[index]}
                    style={{ fill: "#00ff00" }}
                    onClick={() => handleImageClick(index)}
                  />
                  <div className="c-stepper__content">
                    <h3 className="c-stepper__title">Step {index + 1}</h3>
                    <p className="c-stepper__disc">{stepdesc[index]}</p>
                    <p className={`fs-12 ${index === activeStep ? "in-progress" : completedSteps[index] ? "completed" : " "}`}>
                      {index === activeStep ? "In progress" :
                        completedSteps[index] ? "Completed" : " "}
                    </p>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>
        <div className="col">
          <div className="card details-card" style={{ width: "calc(100vw - 60vw)", height: "92vh", borderRadius: "12px", }} >
            <div className="next-card card-body">
              <Toast ref={toast}></Toast>

              {activeStep === 0 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Dietitian Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className="row">
                              <div className="flex flex-column">

                                <Controller
                                  name="dietitianname"
                                  control={control}
                                  rules={{
                                    required: "Dietitian name  is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Dietitian Name <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container col-12 p-0">
                                        <InputText
                                          placeholder="Enter Dietitian Name"
                                          className={`col-10 name-area ${fieldState.error ? "p-invalid" : ""}`}
                                          style={{ borderRight: "none" }}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              dietitianname: e.target.value,
                                            });
                                          }}
                                        />
                                        <Dropdown
                                          optionLabel="label"
                                          optionValue="value"
                                          value={title}
                                          options={titles}
                                          onChange={onTitleChange}
                                          placeholder="Title"
                                          className="tilte-area col-2 p-0"


                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Gender <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className='d-flex gap-3'>
                                  {gender.map((e: any, index: any) => (
                                    <div key={index} className="submenu-item">
                                      <label className="d-flex gap-2">
                                        <input
                                          className="check-wrapper ml-1"
                                          type="radio"
                                          id={`custom-checkbox-${index}`}
                                          name="gender"
                                          onChange={genderselection}
                                          value={e}
                                        />
                                        <span className="gender-text-style">{e}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="experience"
                                  control={control}
                                  rules={{
                                    required: "Experience is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Experience <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Years of Experience (between 0 to 70 Years)"
                                          className={`col-12 ${fieldState.error ? "p-invalid" : ""}`}
                                          keyfilter={/^[0-9+\-\s]*$/}
                                          maxLength={2}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              experience: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <label className="ym-input-label mt-2">
                                Speciality <b style={{ color: 'red' }}>*</b>
                              </label>
                              <div className="row">
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="speciality1"
                                      name="specialities"
                                      value="Dietitian"
                                      onChange={speciality}
                                      checked={specialities.includes("Dietitian")}
                                    />
                                    <label htmlFor="preference1" className="pref-label">
                                      Dietitian
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="preference2"
                                      name="specialities"
                                      value="Nutritionist"
                                      onChange={speciality}
                                      checked={specialities.includes("Nutritionist")}
                                    />
                                    <label htmlFor="preference2" className="pref-label">
                                      Nutritionist
                                    </label>
                                  </div>
                                </div>

                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Languages <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Add Languages"
                                    className="col-12 "
                                    onChange={(e) => { Language(e); }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <label className="ym-input-label mt-2">
                                Mode of Consultation <b style={{ color: 'red' }}>*</b>
                              </label>
                              <div className="row">
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="consult1"
                                      name="consultation"
                                      value="Online"
                                      onChange={modofconsult}
                                      checked={moc.includes("Online")}
                                    />
                                    <label htmlFor="consult1" className="pref-label">
                                      Online
                                    </label>
                                  </div>
                                </div>
                                <div className="col-4">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="consult2"
                                      name="consultation"
                                      value="Offline"
                                      onChange={modofconsult}
                                      checked={moc.includes("Offline")}
                                    />
                                    <label htmlFor="consult2" className="pref-label">
                                      Offline
                                    </label>
                                  </div>
                                </div>

                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Services
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter any 5 Services"
                                    className="col-12 "
                                    onChange={(e) => { service(e); }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Are you a Registered Dietitian(RD)
                                </label>
                                <div className='d-flex gap-3'>
                                  {registercheck.map((e: any, index: any) => (
                                    <div key={index} className="filter-item">
                                      <label className="d-flex gap-2">
                                        <input
                                          className="check-wrapper ml-1"
                                          type="radio"
                                          id={`custom-checkbox-${index}`}
                                          name="registeredcheck"
                                          onChange={redistereddoctor}
                                          value={e}
                                        />
                                        <span className="gender-text-style">{e}</span>
                                      </label>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="fees"
                                  control={control}
                                  rules={{
                                    required: "Fees amount is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Fees <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Fees"
                                          className="col-12 "
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              fees: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  About Dietitian <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputTextarea
                                    placeholder="Enter Dietitian Description"
                                    rows={3}
                                    cols={82}
                                    onChange={(e) => { About(e); }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Website
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Website URL"
                                    className="col-12 "
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        dietitianwebsite: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Facebook
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Facebook URL"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          dietitianfacebookurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Instagram
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Instagram URL"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          dietitianinstaurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Youtube
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Youtube URL"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          dietitianyoutubeurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Twitter
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Twitter URL"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          dietitiantwitterurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Education Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className="row">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Degree <b style={{ color: 'red' }}>*</b>
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Degree"
                                    className="col-12 "
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        degree: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  College/Institute
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter College/Institute"
                                    className="col-12 "
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        college: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Year of Completion
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Year of Completion"
                                    className="col-12 "
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        yearofcompletion: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Certification
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Certification"
                                    className="col-12 "
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        certification: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Memberships
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Memberships"
                                    className="col-12 "
                                    onChange={(e) => { handlemembership(e); }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Awards & Recognitions
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Awards & Recognitions"
                                    className="col-12 "
                                    onChange={(e) => { awardsandrecog(e); }}
                                  />
                                </div>
                              </div>
                            </div>


                            <div className="row mt-4">
                              <div className="">
                                <div className="child-left" id="uploadimages">
                                  <label className="ym-input-label">
                                    Medical Registration Proof
                                  </label>
                                  <DropFileInput
                                    uploadedFiles={medicalregistrationproof}
                                    setUploadedFiles={setMedicalregistrationproof}
                                    resObj={resObj}
                                    setResObj={setResObj}
                                    purpose="medicalregistrationprooffile"
                                  />
                                </div>
                              </div>
                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="OrganicstoreDetails">
                        <h4 className='head-cust-cards'>Clinic Details</h4>
                        <hr style={{ color: "#f5f5f5", opacity: "100%", marginTop: "8px" }} />
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 27vh)" }}>

                            <div className="row">
                              <div className="flex flex-column">
                                <Controller
                                  name="clinicname"
                                  control={control}
                                  rules={{
                                    required: "Clinic name  is required.",
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Clinic Name <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputText
                                          placeholder="Enter Clinic Name"
                                          className="col-12 "
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              clinicname: e.target.value,
                                            });
                                          }}

                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col ">
                                <div className="">
                                  <Controller
                                    name="latitude"
                                    control={control}
                                    rules={{
                                      required: "latitude is required.",
                                      pattern: {
                                        value: /^[0-9]+(\.[0-9]+)?$/,
                                        message: "Enter a valid latitude with only numbers and dots.",
                                      }
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label ">
                                            Latitude
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Enter the vaild latitube"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter a vaild latitude"
                                            id={field.name}
                                            value={resObj.latitude}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                latitude: e.target.value,
                                              });

                                            }}

                                          />
                                        </div>

                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="longitude"
                                    control={control}
                                    rules={{
                                      required: "logitube is required.",

                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label">
                                            Longitude
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Enter the vaild logitude"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={30}
                                            placeholder="Enter a vaild logitude"
                                            id={field.name}
                                            value={resObj.longitude}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            onChange={(e) => {
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                longitude: e.target.value,
                                              });
                                              // fetchAddress(resObj.latitude,e.target.value)
                                            }}
                                          // keyfilter={/^[d]*\.?\d*$/}
                                          />
                                        </div>

                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <Controller
                                  name="address"
                                  control={control}
                                  rules={{ required: "Address  is required." }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        Address <b style={{ color: 'red' }}>*</b>
                                      </label>
                                      <div className="input-dropdown-container">
                                        <InputTextarea
                                          placeholder="Enter Address Line"
                                          rows={3}
                                          cols={82}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              clinicaddress: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </>
                                  )}
                                />
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Locality <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Locality"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          locality: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    City <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter City"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          city: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    District <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter District"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          district: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    State <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter State"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          state: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Country <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Country"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          country: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Pincode <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Pincode"
                                      keyfilter={/^[0-9+\-\s]*$/}
                                      maxLength={6}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          pincode: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Phone Number <b style={{ color: 'red' }}>*</b>
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Phone Number"
                                      keyfilter={/^[0-9+\-\s]*$/}
                                      maxLength={14}
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          phoneno: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Email
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Email"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          email: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="flex flex-column">
                                <label className="ym-input-label">
                                  Website
                                </label>
                                <div className="input-dropdown-container">
                                  <InputText
                                    placeholder="Enter Website URL"
                                    className="col-12 "
                                    onChange={(e) => {
                                      setResObj({
                                        ...resObj,
                                        clinicwebsite: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Facebook
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Facebook URL"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          clinicfacebookurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Instagram
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Instagram URL"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          clinicinstaurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className='col-12 d-flex gap-2 mt-4 py-0 pl-0'>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Youtube
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Youtube URL"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          clinicyoutubeurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-6 p-0">
                                <div className="flex flex-column">
                                  <label className="ym-input-label">
                                    Twitter
                                  </label>
                                  <div className="input-dropdown-container">
                                    <InputText
                                      placeholder="Enter Twitter URL"
                                      className="col-12"
                                      onChange={(e) => {
                                        setResObj({
                                          ...resObj,
                                          clinictwitterurl: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <div className="row m-0">
                    <div className="col-12">
                      <div className="child-lef t" id="Timings">
                        <h4 className="rest-detail-head">Timings</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                            <div className="row">
                              <label className="ym-input-label">Timings</label>
                              <div className="row">
                                {daysOfWeek.map((day) => (
                                  <div className="col-3" key={day}>
                                    <div className="d-flex align-items-center gap-2">
                                      <Checkbox
                                        inputId={`day${day}`}
                                        name="day"
                                        value={day}
                                        checked={checkedDays[day]}
                                        onChange={() => handleCheckboxChange(day)}
                                      />
                                      <label
                                        htmlFor={`day${day}`}
                                        className="pref-label"
                                      >
                                        {day}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className=" d-flex from-to gap-2 align-items-center mb-3">
                                <div className="from d-flex flex-column col-3">
                                  <span className="restaurant-name">From</span>
                                  <Dropdown
                                    style={{
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                    id="eater"
                                    placeholder="Select"
                                    value={fromtimes}
                                    options={Timings}
                                    onChange={onFromTimeChange}
                                  />
                                </div>
                                <div className="from d-flex flex-column col-3">
                                  <span className="restaurant-name">To</span>
                                  <Dropdown
                                    style={{
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                    id="eater"
                                    placeholder="Select"
                                    value={totimes}
                                    options={Timings}
                                    onChange={onToTimeChange}
                                  />
                                </div>
                                <div className="mt-3">
                                  <button
                                    className="add-button"
                                    onClick={handleAddTiming}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-start align-items-center mt-3">
                                <div className="col-8">
                                  {Object.entries(selectedTimings).map(
                                    ([day, timing]) => (
                                      <div
                                        className="Sunday-timing col-12 gap-3 d-flex justify-content-center align-items-center mt-3"
                                        key={day}
                                      >
                                        <span className="day">{day}</span>
                                        <div className="row">
                                          <div className="col gap-1">
                                            {timing.map((time, index) => (
                                              <div className="col p-0 mt-1 gap-1">
                                                <span
                                                  className="time"
                                                  key={index}
                                                >
                                                  {time}
                                                </span>

                                                <i
                                                  className="bi bi-trash-fill"
                                                  style={{
                                                    color: "#818181",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    const updatedTimings = {
                                                      ...selectedTimings,
                                                    };
                                                    updatedTimings[day] =
                                                      updatedTimings[day].filter((t) => t !== time);

                                                    // Check if there are no times left, delete the entire day
                                                    if (
                                                      updatedTimings[day]
                                                        .length === 0
                                                    ) {
                                                      delete updatedTimings[day];
                                                    }

                                                    setSelectedTimings(
                                                      updatedTimings
                                                    );
                                                  }}
                                                ></i>
                                                <br />
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 4 && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="child-left" id="uploadimages">
                        <h4>Upload Images</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "75vh" }}>
                            <div className="">
                              <label className="ym-input-label">
                                Dietitian Photo
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <DropFileInput
                                uploadedFiles={dietitianimage}
                                setUploadedFiles={setDietitianimage}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="DietitianImage"
                              />
                            </div>
                            <div className="">
                              <label className="ym-input-label">
                                Clinic Images
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <DropFileInput
                                uploadedFiles={clinicimages}
                                setUploadedFiles={setClinicimages}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="clinicimages"
                              />
                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            </div>
            <div className="card-footer button-container">
              {activeStep === 0 ? (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="row">
                      <Button
                        className="next"
                        label="Next"
                        type="submit"
                        // icon="pi pi-check"
                        onClick={
                          handleNext
                        }

                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <Button
                    className="ym-add-button-previous"
                    label="Back"
                    onClick={handleBack}
                  ></Button>

                  {activeStep === 4 ? (
                    <Button
                      className="p-button-success ym-add-button"
                      label="Add Dietitian"
                      onClick={() => {
                        SubmitDietitianObj();
                      }}
                    // disabled={currentIndex === 3}
                    ></Button>
                  ) : (

                    <Button
                      className="next"
                      label="Next"
                      type="submit"
                      // icon="pi pi-check"
                      onClick={
                        handleNext
                      }
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}

export default DietitianCustomStepper;