import React, { useEffect, useState } from "react";
import Navbar from "../../components/ui/navbar/sidenav.components";
// import Logo from "../../assets/images/Layer1.svg";
import { apiUrl } from "../../config/constants";
import { useNavigate } from "react-router-dom";
import "../dashboard/dashboard.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
// import Cookies from 'js-cookie'
import Barchart from "../../components/ui/charts/bar-chart/bar-chart.components";
import Linechart from "../../components/ui/charts/line-chart/line-chart.components";
import Loader from "../../components/ui/loader/loader.components";
import {
  GetCategoryCount,
  GetCuisineCount,
  GetRecentTableData,
  GetApprovedRejectedData,
  GetTopArea,
  TopAresCovered,
  TopPerformingRest,
  StoreAndEventCount,
} from "../../api/dashboard.api";
import { getRefreshedToken, isTokenExpired, logout } from "../../api/auth.api";
import axios from "axios";
import Doughnutchart from "../../components/ui/charts/doughnut-chart/doughnut-chart.components";
import Mapview from "../../components/ui/map/Mapview.component";
import { RecentTable } from "../../components/dashboard/recent.components";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
const Dashboard = () => {
  const [isAdding, setIsadding] = useState(false); // Loader state..
  const [totalrest, settotalrest] = useState<any>(); // Loader state..
  const [approverest, setapproverest] = useState<any>(); // Loader state..
  const [pendingrest, setpendingrest] = useState<any>(); // Loader state..
  const [rejectrest, setrejectrest] = useState<any>(); // Loader state..
  const [totalstores, settotalstores] = useState<any>(); // Loader state..
  const [approvestores, setapprovestores] = useState<any>(); // Loader state..
  const [pendingstores, setpendingstores] = useState<any>(); // Loader state..
  const [rejectstores, setrejectstores] = useState<any>(); // Loader state..
  const [totalfoodevents, settotalfoodevents] = useState<any>(); // Loader state..
  const [approvefoodevents, setapprovefoodevents] = useState<any>(); // Loader state..
  const [pendingfoodevents, setpendingfoodevents] = useState<any>(); // Loader state..
  const [rejectfoodevents, setrejectfoodevents] = useState<any>(); // Loader state..
  const [NewApproved, setNewApproved] = useState<any>(); // Loader state..
  const [NewPending, setNewPending] = useState<any>(); // Loader state..
  const [NewDenied, setNewDenied] = useState<any>(); // Loader state..
  const [CurrentDayTotal, setCurrentDayTotal] = useState<any>(); // Loader state..
  const [Growth, setGrowth] = useState(0);
  const [locationsData, setLocationsData] = useState([]);
  const [topPerforming, setTopPerforming] = useState([]);
  const [totalRecentRecords, setTotalRecentRecords] = useState(0);
  const [Recentdata, setRecentData] = useState(undefined);
  // const [WeekLineData, setWeekLineData] = useState<any>();
  // const apiUrl = "https://devapi.yeldam.com/api";
  const navigate = useNavigate();
  const [barData, setBarData] = useState<any>({ labels: [], data: [] });
  // const [lineData, setLineData] = useState<any>({});
  const [lineData1, setLineData1] = useState<any>({});
  const [cusinechartData, setCusineChartData] = useState({});
  const [cuisineoptions, setcuisineoptions] = useState({});
  const [categorychartData, setCategoryChartData] = useState({});
  const [catoptions, setcatoptions] = useState({});
  const [topareasData, setTopareasData] = useState({});
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  var width = window.screen.width;
  var c_rows = 10;
  if (width >= 1920) {
    c_rows = 15;
  } else if (width >= 1400 || width <= 1600) {
    c_rows = 10;
  }

  const [lazyParams, setLazyParams] = useState({
    approvalstatus: 1,
    first: 0,
    rows: c_rows,
    page: 0,
    sortField: "",
    sortOrder: 0,
  });

  const agenttimedata = async () => {
    if (isTokenExpired()) {
      await getRefreshedToken();
    } else {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Accept: "*/*",
        Authorization: "Bearer " + token,
      };
      try {
        const resData = await axios.get(
          apiUrl + "/agentdashboard/agenttimedata",
          { headers }
        );

        setLineData1({
          labels: resData.data.data.WeeklyReport.map(
            (report: any) => report._id
          ),

          datasets: [
            {
              label: "Total Restaurants",
              data: resData.data.data.WeeklyReport.map(
                (report: any) => report.count
              ),
              fill: false,
              backgroundColor: documentStyle.getPropertyValue("--green-200"),
              borderColor: documentStyle.getPropertyValue("--green-200"),
              tension: 0.4,
            },
          ],
        });

        if (resData.data.data.Growth === null) {
          setGrowth(0);
        } else {
          setGrowth(resData.data.data.Growth);
        }
      } catch (err: any) {
        // console.log("error agenttimedata", err);
        if (err.code === "ERR_BAD_REQUEST") {
          logout();
          navigate("/");
        }
      }
    }
  };

  const agentapdata = async () => {
    try {
      const agententry = await GetApprovedRejectedData();
      console.log("Approvedrejecteddata:", agententry.data.Result)

      setBarData({
        labels: agententry.data.Result.map((report: any) => report._id),
        datasets: [
          {
            label: "Approved Restaurants",
            data: agententry.data.Result.map(
              (report: any) => report.approvedCount
            ),
            fill: false,
            backgroundColor: "#9BDFC4",
            borderColor: "9BDFC4",
            tension: 0.4,
            barThickness: 8,
            barPercentage: 0.7,
            categoryPercentage: 0.8
          },
          {
            label: "Rejected Restaurants",
            data: agententry.data.Result.map(
              (report: any) => report.rejectedCount
            ),
            fill: false,
            backgroundColor: "#FAAFBC",
            borderColor: "#FAAFBC",
            tension: 0.4,
            barThickness: 8,
            barPercentage: 0.4,
            categoryPercentage: 0.5
          },
        ],
      });
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const restaurantcount = async () => {
    if (isTokenExpired()) {
      await getRefreshedToken();
    } else {
      const token = localStorage.getItem("accessToken");
      const headers = {
        Accept: "*/*",
        Authorization: "Bearer " + token,
      };
      try {
        const countData = await axios.get(
          apiUrl + "/agentdashboard/restaurantcount",
          { headers }
        );

        setapproverest(countData.data.data.Approved);
        setpendingrest(countData.data.data.Pending);
        setrejectrest(countData.data.data.Denied);
        settotalrest(countData.data.data.Total);
        setNewApproved(countData.data.data.NewApproved);
        setNewDenied(countData.data.data.NewDenied);
        setNewPending(countData.data.data.NewPending);
        setCurrentDayTotal(countData.data.data.CurrentDayTotal);
      } catch (err: any) {
        // console.log("error agenttimedata", err);
        if (err.code === "ERR_BAD_REQUEST") {
          logout();
          navigate("/");
        }
      }
    }
  };

  const StoreEventDataCount = async () => {
    try {
      const storeeventscount = await StoreAndEventCount();
      setapprovestores(storeeventscount.data.data.Approved);
      setpendingstores(storeeventscount.data.data.Pending);
      setrejectstores(storeeventscount.data.data.Denied);
      settotalstores(storeeventscount.data.data.Total);
      setapprovefoodevents(storeeventscount.data.data.ApprovedEvents);
      setpendingfoodevents(storeeventscount.data.data.PendingEvents);
      setrejectfoodevents(storeeventscount.data.data.DeniedEvents);
      settotalfoodevents(storeeventscount.data.data.TotalEvents);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const CuisineCount = async () => {
    try {
      const CuisineCount = await GetCuisineCount();
      const Cusinedata = {
        labels: CuisineCount.data.data.map((report: any) => report._id),

        // labels:['a','b','c'],
        datasets: [
          {
            // data: [20,30,40],
            data: CuisineCount.data.data.map(
              (report: any) => report.count
            ),
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4",
              "#02C39A",
              "#D7B6C8",
              "#B3C491",
              "#EDDADA",
              "#9599DF",
              "#FBEBFF",
              "#D1D5EB",
              "#9ACFC4",
              "#9ACFC4"


            ],

            borderWidth: 10,
          },
        ],
      };

      const options = {
        cutout: "50%",
        plugins: {
          legend: {
            display: false, // Hide the legend
            position: "right",
          },
        },
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "250px";
          chart.canvas.parentNode.style.height = "200px";
        },
      }
      setcuisineoptions(options);
      setCusineChartData(Cusinedata);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const CategoryCount = async () => {
    try {
      const CategoryCount = await GetCategoryCount();
      const data = {
        labels: CategoryCount.data.data.map((report: any) => report._id),
        datasets: [
          {
            data: CategoryCount.data.data.map(
              (report: any) => report.count
            ),
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4"
            ],
            borderWidth: 10,
          },
        ],
      };
      const options = {
        cutout: "50%",
        plugins: {
          legend: {
            display: false, // Hide the legend
            position: "right",
          },
        },
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "250px";
          chart.canvas.parentNode.style.height = "200px";
        },
      }
setcatoptions(options);
      setCategoryChartData(data);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const RecentTableData = async () => {
    try {
      const RecentTableobj = await GetRecentTableData(lazyParams);
      setRecentData(RecentTableobj.data.data);
      setTotalRecentRecords(RecentTableobj.data.data.totalRecords);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const TopArea = async () => {
    try {
      const Areasobj = await GetTopArea();
      const transformedData = Areasobj.data.data.map((item: any) => ({
        ...item,
        coordinates: [item.latitude, item.longitude],
        latitude: undefined,
        longitude: undefined,
      }));
      setLocationsData(transformedData);
      setIsadding(false);
    } catch (err: any) {
      // console.log("error agenttimedata", err);
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const TopPerformingData = async () => {
    try {
      const TopPerforming = await TopPerformingRest();
      setTopPerforming(TopPerforming?.data?.data?.Result);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const lineoptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
    onResize: (chart: any, size: any) => {
      chart.canvas.parentNode.style.width = "100%";
      chart.canvas.parentNode.style.height = "200px";
    },
  };
  const baroptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
    onResize: (chart: any, size: any) => {
      chart.canvas.parentNode.style.width = "100%";
      chart.canvas.parentNode.style.height = "215px";
    },
  };



  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };
  const topareasdata = async () => {
    try {
      const Topareas = await TopAresCovered();
      setTopareasData(Topareas.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  // const getRandomColor = (): string => {
  //   const letters = "0123456789ABCDEF";
  //   let color = "#";
  //   for (let i = 0; i < 6; i++) {
  //     color += letters[Math.floor(Math.random() * 16)];
  //   }
  //   return color;
  // };

  // useEffect(() => {
  //   setIsadding(true);
  //   agenttimedata();
  //   agentapdata();
  //   restaurantcount();
  //   CuisineCount();
  //   CategoryCount();
  //   RecentTableData();
  //   TopArea();
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const delayedFetch = () => {
      setIsadding(true);
      // Add a delay of 500ms before making the API call
      timer = setTimeout(() => {
        agenttimedata();
        agentapdata();
        restaurantcount();
        CuisineCount();
        CategoryCount();
        RecentTableData();
        topareasdata();
        TopArea();
        TopPerformingData();
        StoreEventDataCount();
        setIsadding(true);
      }, 500);
    };
    delayedFetch();
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <main>
        {!isAdding ? "" : <Loader />}

        <div>
          <Navbar />
          <section
            className="page-content"
            style={{ backgroundColor: "#FAFAFA" }}
          >
            <div className="ym-content-table">
              <div className="container-fluid">
                <div className="row">
                  <div className="Dashboard-head" >Dashboard</div>
                </div>
                <hr style={{ color: "#ececec", height: "1px", opacity: "100%" }} />
                <div className="row row-deck">
                  <div className="row">
                    <div className="col-8">
                      <div className="grid gap-4 ml-1 d-flex justify-content-start align-items-center ">
                        {/* <div className="row row-deck ym-dashboard-deck"> */}
                        <div className="">
                          <div className="card mb-0 p-1 ym-dash-card ym-box-card">
                            <div className="flex justify-content-center align-items-center gap-2 mb-1">
                              <div
                                className="flex align-items-center justify-content-center bg-blue-100 border-round"
                                style={{ width: "32px", height: "32px" }}
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.97071 1.34921C3.16069 1.12756 3.43804 1 3.72997 1H12.27C12.562 1 12.8393 1.12756 13.0293 1.34921L15.6389 4.39374C15.8719 4.6656 16 5.01186 16 5.36992V5.625C16 6.93668 14.9367 8 13.625 8C12.8629 8 12.1846 7.64102 11.75 7.08291C11.3154 7.64102 10.6371 8 9.875 8C9.11287 8 8.43458 7.64102 8 7.08291C7.56542 7.64102 6.88714 8 6.125 8C5.36286 8 4.68458 7.64102 4.25 7.08291C3.81542 7.64102 3.13713 8 2.375 8C1.06332 8 0 6.93668 0 5.625V5.36992C0 5.01186 0.128088 4.6656 0.361115 4.39374L2.97071 1.34921ZM4.75 5.625C4.75 6.38439 5.36561 7 6.125 7C6.88439 7 7.5 6.38439 7.5 5.625C7.5 5.34886 7.72386 5.125 8 5.125C8.27614 5.125 8.5 5.34886 8.5 5.625C8.5 6.38439 9.11561 7 9.875 7C10.6344 7 11.25 6.38439 11.25 5.625C11.25 5.34886 11.4739 5.125 11.75 5.125C12.0261 5.125 12.25 5.34886 12.25 5.625C12.25 6.38439 12.8656 7 13.625 7C14.3844 7 15 6.38439 15 5.625V5.36992C15 5.25057 14.9573 5.13515 14.8796 5.04453L12.27 2H3.72997L1.12037 5.04453C1.0427 5.13515 1 5.25057 1 5.36992V5.625C1 6.38439 1.61561 7 2.375 7C3.13439 7 3.75 6.38439 3.75 5.625C3.75 5.34886 3.97386 5.125 4.25 5.125C4.52614 5.125 4.75 5.34886 4.75 5.625ZM1.5 8.5C1.77614 8.5 2 8.72386 2 9V15H3V10C3 9.44772 3.44772 9 4 9H7C7.55228 9 8 9.44772 8 10V15H14V9C14 8.72386 14.2239 8.5 14.5 8.5C14.7761 8.5 15 8.72386 15 9V15H15.5C15.7761 15 16 15.2239 16 15.5C16 15.7761 15.7761 16 15.5 16H0.5C0.223858 16 0 15.7761 0 15.5C0 15.2239 0.223858 15 0.5 15H1V9C1 8.72386 1.22386 8.5 1.5 8.5ZM4 15H7V10H4V15ZM9 10C9 9.44772 9.44771 9 10 9H12C12.5523 9 13 9.44772 13 10V13C13 13.5523 12.5523 14 12 14H10C9.44771 14 9 13.5523 9 13V10ZM12 10H10V13H12V10Z"
                                    fill="#00639B"
                                  />
                                </svg>
                              </div>
                              <div style={{ width: "107px" }}>
                                <div className="cards-value">
                                  {totalrest > 0 ? totalrest : "0"}
                                </div>
                                <span className="card-head mb-1">
                                  Total Restaurants
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="card mb-0 p-1 ym-dash-card ym-box-card">
                            <div className="flex justify-content-center align-items-center gap-2 mb-1">
                              <div
                                className="flex align-items-center justify-content-center bg-yellow-100 border-round"
                                style={{ width: "32px", height: "32px" }}
                              >
                                <svg
                                  width="12"
                                  height="14"
                                  viewBox="0 0 12 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 0.5C0 0.223858 0.223858 0 0.5 0H11.5C11.7761 0 12 0.223858 12 0.5C12 0.776142 11.7761 1 11.5 1H10.5V2C10.5 3.7901 9.45472 5.33509 7.94345 6.05972C7.65355 6.19872 7.5 6.43685 7.5 6.64919V7.35083C7.5 7.56317 7.65355 7.8013 7.94345 7.9403C9.45472 8.66493 10.5 10.2099 10.5 12V13L11.5 13C11.7761 13 12 13.2239 12 13.5C12 13.7761 11.7761 14 11.5 14L0.5 14C0.223858 14 0 13.7762 0 13.5C0 13.2239 0.223858 13 0.5 13H1.5V12C1.5 10.2099 2.54528 8.66493 4.05655 7.9403C4.34645 7.8013 4.5 7.56317 4.5 7.35083V6.64919C4.5 6.43685 4.34645 6.19872 4.05655 6.05972C2.54528 5.33509 1.5 3.7901 1.5 2V1H0.5C0.223858 1 0 0.776142 0 0.5ZM2.5 1V2C2.5 3.39097 3.31139 4.59342 4.4889 5.15802C5.02187 5.41357 5.5 5.94896 5.5 6.64919V7.35083C5.5 8.05106 5.02187 8.58646 4.4889 8.842C3.31139 9.40661 2.5 10.609 2.5 12V13H9.5V12C9.5 10.609 8.68861 9.40661 7.5111 8.842C6.97813 8.58646 6.5 8.05106 6.5 7.35083V6.64919C6.5 5.94896 6.97813 5.41357 7.5111 5.15802C8.68861 4.59342 9.5 3.39097 9.5 2V1H2.5Z"
                                    fill="#AE8701"
                                  />
                                </svg>
                              </div>
                              <div style={{ width: "107px" }}>
                                <div className="cards-value">
                                  {pendingrest > 0 ? pendingrest : 0}
                                </div>
                                <span className="card-head mb-1">
                                  Pending Restaurants
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="card mb-0 p-1 ym-dash-card ym-box-card">
                            <div className="flex justify-content-center align-items-center gap-2 mb-1">
                              <div
                                className="flex align-items-center justify-content-center bg-green-100 border-round"
                                style={{ width: "32px", height: "32px" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                    fill="#2BC04C"
                                  />
                                  <path
                                    d="M10.9697 4.96967C10.9626 4.97674 10.9559 4.98423 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967Z"
                                    fill="#2BC04C"
                                  />
                                </svg>
                              </div>
                              <div style={{ width: "107px" }}>
                                <div className="cards-value">
                                  {approverest > 0 ? approverest : 0}
                                </div>
                                <span className="card-head mb-1">
                                  Approved Restaurants
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className="card mb-0 p-1 ym-dash-card ym-box-card">
                            <div className="flex justify-content-center align-items-center gap-2 mb-1">
                              <div
                                className="flex align-items-center justify-content-center bg-red-100 border-round"
                                style={{ width: "32px", height: "32px" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                    fill="#D83B01"
                                  />
                                  <path
                                    d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
                                    fill="#D83B01"
                                  />
                                </svg>
                              </div>
                              <div style={{ width: "107px" }}>
                                <div className="cards-value">
                                  {rejectrest > 0 ? rejectrest : 0}
                                </div>
                                <span className="card-head mb-1">
                                  Rejected Restaurants
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="col mt-2 pl-0 pr-0">
                        <div className="col-12 pl-0 pr-0">
                          <div className="Dashboard-head mb-2">
                            {" "}
                            Weekly Performance
                          </div>
                          <div className="card ym-chart-card mt-1">
                            <div className="row">
                              <div className="col-12 bar-charts">
                                <Linechart
                                  data={lineData1}
                                  options={lineoptions}
                                />
                              </div>
                              {/* <div className="row">
                                <div className="col d-flex align-items-center gap-2">
                                  <div className="col p-0">
                                    <div
                                      className="fw-400 fs-14 d-flex gap-2 align-items-center"
                                      style={{ fontFamily: "" }}
                                    >
                                      {Growth !== null && Growth >= 0 ? (
                                        <>
                                          Increased by{" "}
                                          <div
                                            className="flex align-items-center justify-content-center border-round"
                                            style={{
                                              width: "48px",
                                              height: "24px",
                                              gap: "5px",
                                              backgroundColor:
                                                "rgb(217 255 211)",
                                            }}
                                          >
                                            <i
                                              className="pi pi-arrow-up fs-10"
                                              style={{ color: "#107C10" }}
                                            ></i>
                                            <span
                                              className="fw-600 fs-12"
                                              style={{
                                                fontFamily: "rubik",
                                                color: "#107C10",
                                              }}
                                            >
                                              {" "}
                                              10%{" "}
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          Decreased by{" "}
                                          <span
                                            className="fw-600 fs-16"
                                            style={{
                                              fontFamily: "rubik",
                                              color: "red",
                                            }}
                                          >
                                            {" "}
                                            {Growth}%{" "}
                                          </span>
                                          <div
                                            className="flex align-items-center justify-content-center border-round"
                                            style={{
                                              width: "2.5rem",
                                              height: "2.5rem",
                                              backgroundColor:
                                                "rgb(254 205 205)",
                                            }}
                                          >
                                            <i
                                              className="pi pi-caret-down text-xl"
                                              style={{ color: "red" }}
                                            ></i>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-4 p-0">
                      <div className="card p-2" style={{ border: "none" }}>
                        <ul className="pl-0 pr-0 mx-0 mt-0 mb-0 list-none pt-2 pb-2">
                          <li
                            className="p-1 border-top"
                            style={{ backgroundColor: "#FAFAFA" }}
                          >
                            <div className="row">
                              <div className="col-8 gap-2 d-flex justify-content-start align-items-center">
                                <svg
                                  width="16"
                                  height="15"
                                  viewBox="0 0 16 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M2.97071 0.349209C3.16069 0.127563 3.43804 0 3.72997 0H12.27C12.562 0 12.8393 0.127562 13.0293 0.349208L15.6389 3.39374C15.8719 3.6656 16 4.01186 16 4.36992V4.625C16 5.93668 14.9367 7 13.625 7C12.8629 7 12.1846 6.64102 11.75 6.08291C11.3154 6.64102 10.6371 7 9.875 7C9.11287 7 8.43458 6.64102 8 6.08291C7.56542 6.64102 6.88714 7 6.125 7C5.36286 7 4.68458 6.64102 4.25 6.08291C3.81542 6.64102 3.13713 7 2.375 7C1.06332 7 0 5.93668 0 4.625V4.36992C0 4.01186 0.128088 3.6656 0.361115 3.39374L2.97071 0.349209ZM4.75 4.625C4.75 5.38439 5.36561 6 6.125 6C6.88439 6 7.5 5.38439 7.5 4.625C7.5 4.34886 7.72386 4.125 8 4.125C8.27614 4.125 8.5 4.34886 8.5 4.625C8.5 5.38439 9.11561 6 9.875 6C10.6344 6 11.25 5.38439 11.25 4.625C11.25 4.34886 11.4739 4.125 11.75 4.125C12.0261 4.125 12.25 4.34886 12.25 4.625C12.25 5.38439 12.8656 6 13.625 6C14.3844 6 15 5.38439 15 4.625V4.36992C15 4.25057 14.9573 4.13515 14.8796 4.04453L12.27 1H3.72997L1.12037 4.04453C1.0427 4.13515 1 4.25057 1 4.36992V4.625C1 5.38439 1.61561 6 2.375 6C3.13439 6 3.75 5.38439 3.75 4.625C3.75 4.34886 3.97386 4.125 4.25 4.125C4.52614 4.125 4.75 4.34886 4.75 4.625ZM1.5 7.5C1.77614 7.5 2 7.72386 2 8V14H14V8C14 7.72386 14.2239 7.5 14.5 7.5C14.7761 7.5 15 7.72386 15 8V14H15.5C15.7761 14 16 14.2239 16 14.5C16 14.7761 15.7761 15 15.5 15H0.5C0.223858 15 0 14.7761 0 14.5C0 14.2239 0.223858 14 0.5 14H1V8C1 7.72386 1.22386 7.5 1.5 7.5ZM3.5 8C3.77614 8 4 8.22386 4 8.5V12H12V8.5C12 8.22386 12.2239 8 12.5 8C12.7761 8 13 8.22386 13 8.5V12C13 12.5523 12.5523 13 12 13H4C3.44772 13 3 12.5523 3 12V8.5C3 8.22386 3.22386 8 3.5 8Z"
                                    fill="#003355"
                                  />
                                </svg>

                                <span className="side-heads">
                                  Total Organic Stores
                                </span>
                              </div>
                              <div className="col-4 d-flex justify-content-end">
                                <span className="side-counts">
                                  {totalstores > 0 ? totalstores : "0"}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li className="p-1 border-top">
                            <div className="row">
                              <div className="col-8 gap-2 d-flex justify-content-start align-items-center">
                                <svg
                                  width="12"
                                  height="14"
                                  viewBox="0 0 12 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M0 0.5C0 0.223858 0.223858 0 0.5 0H11.5C11.7761 0 12 0.223858 12 0.5C12 0.776142 11.7761 1 11.5 1H10.5V2C10.5 3.7901 9.45472 5.33509 7.94345 6.05972C7.65355 6.19872 7.5 6.43685 7.5 6.64919V7.35083C7.5 7.56317 7.65355 7.8013 7.94345 7.9403C9.45472 8.66493 10.5 10.2099 10.5 12V13L11.5 13C11.7761 13 12 13.2239 12 13.5C12 13.7761 11.7761 14 11.5 14L0.5 14C0.223858 14 0 13.7762 0 13.5C0 13.2239 0.223858 13 0.5 13H1.5V12C1.5 10.2099 2.54528 8.66493 4.05655 7.9403C4.34645 7.8013 4.5 7.56317 4.5 7.35083V6.64919C4.5 6.43685 4.34645 6.19872 4.05655 6.05972C2.54528 5.33509 1.5 3.7901 1.5 2V1H0.5C0.223858 1 0 0.776142 0 0.5ZM2.5 1V2C2.5 3.39097 3.31139 4.59342 4.4889 5.15802C5.02187 5.41357 5.5 5.94896 5.5 6.64919V7.35083C5.5 8.05106 5.02187 8.58646 4.4889 8.842C3.31139 9.40661 2.5 10.609 2.5 12V13H9.5V12C9.5 10.609 8.68861 9.40661 7.5111 8.842C6.97813 8.58646 6.5 8.05106 6.5 7.35083V6.64919C6.5 5.94896 6.97813 5.41357 7.5111 5.15802C8.68861 4.59342 9.5 3.39097 9.5 2V1H2.5Z"
                                    fill="#AE8701"
                                  />
                                </svg>

                                <span className="side-heads">
                                  Pending Organic Stores
                                </span>
                              </div>
                              <div className="col-4 d-flex justify-content-end">
                                <span className="side-counts">
                                  {pendingstores > 0 ? pendingstores : "0"}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li
                            className="p-1 border-top"
                            style={{ backgroundColor: "#FAFAFA" }}
                          >
                            <div className="row">
                              <div className="col-8 gap-2 d-flex justify-content-start align-items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                    fill="#2BC04C"
                                  />
                                  <path
                                    d="M10.9697 4.96967C10.9626 4.97674 10.9559 4.98423 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967Z"
                                    fill="#2BC04C"
                                  />
                                </svg>
                                <span className="side-heads">
                                  Approved Organic Stores
                                </span>
                              </div>
                              <div className="col-4 d-flex justify-content-end">
                                <span className="side-counts">
                                  {approvestores > 0 ? approvestores : "0"}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li className="p-1 border-top border-bottom">
                            <div className="row">
                              <div className="col-8 gap-2 d-flex justify-content-start align-items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                    fill="#D83B01"
                                  />
                                  <path
                                    d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
                                    fill="#D83B01"
                                  />
                                </svg>
                                <span className="side-heads">
                                  Rejected Organic Stores
                                </span>
                              </div>
                              <div className="col-4 d-flex justify-content-end">
                                <span className="side-counts">
                                  {rejectstores > 0 ? rejectstores : "0"}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>

                      <div className="card p-2 mt-2" style={{ border: "none" }}>
                        <ul className="pl-0 pr-0 mx-0 mt-0 mb-0 list-none pt-2 pb-2">
                          <li className="p-1 border-top">
                            <div className="row">
                              <div className="col-8 gap-2 d-flex justify-content-start align-items-center">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M10.8536 7.14645C11.0488 7.34171 11.0488 7.65829 10.8536 7.85355L7.85355 10.8536C7.75979 10.9473 7.63261 11 7.5 11C7.36739 11 7.24021 10.9473 7.14645 10.8536L5.64645 9.35355C5.45118 9.15829 5.45118 8.84171 5.64645 8.64645C5.84171 8.45118 6.15829 8.45118 6.35355 8.64645L7.5 9.79289L10.1464 7.14645C10.3417 6.95118 10.6583 6.95118 10.8536 7.14645Z"
                                    fill="#00639B"
                                  />
                                  <path
                                    d="M3.5 0C3.77614 0 4 0.223858 4 0.5V1H12V0.5C12 0.223858 12.2239 0 12.5 0C12.7761 0 13 0.223858 13 0.5V1H14C15.1046 1 16 1.89543 16 3V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V3C0 1.89543 0.895431 1 2 1H3V0.5C3 0.223858 3.22386 0 3.5 0ZM1 4V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V4H1Z"
                                    fill="#00639B"
                                  />
                                </svg>
                                <span className="side-heads">
                                  Total Food Events
                                </span>
                              </div>
                              <div className="col-4 d-flex justify-content-end">
                                <span className="side-counts">
                                  {totalfoodevents > 0 ? totalfoodevents : "0"}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li
                            className="p-1 border-top"
                            style={{ backgroundColor: "#FAFAFA" }}
                          >
                            <div className="row">
                              <div className="col-8 gap-2 d-flex justify-content-start align-items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                    fill="#2BC04C"
                                  />
                                  <path
                                    d="M10.9697 4.96967C10.9626 4.97674 10.9559 4.98423 10.9498 4.9921L7.4774 9.41674L5.38388 7.32322C5.09098 7.03033 4.61611 7.03033 4.32322 7.32322C4.03032 7.61612 4.03032 8.09099 4.32322 8.38388L6.96966 11.0303C7.26256 11.3232 7.73743 11.3232 8.03032 11.0303C8.03685 11.0238 8.043 11.0169 8.04876 11.0097L12.041 6.01947C12.3232 5.72582 12.3196 5.25897 12.0303 4.96967C11.7374 4.67678 11.2626 4.67678 10.9697 4.96967Z"
                                    fill="#2BC04C"
                                  />
                                </svg>
                                <span className="side-heads">
                                  Approved Food Events
                                </span>
                              </div>
                              <div className="col-4 d-flex justify-content-end">
                                <span className="side-counts">
                                  {approvefoodevents > 0
                                    ? approvefoodevents
                                    : "0"}
                                </span>
                              </div>
                            </div>
                          </li>
                          <li className="p-1 border-top border-bottom">
                            <div className="row">
                              <div className="col-8 gap-2 d-flex justify-content-start align-items-center">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8C15 11.866 11.866 15 8 15ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
                                    fill="#D83B01"
                                  />
                                  <path
                                    d="M4.64645 4.64645C4.84171 4.45118 5.15829 4.45118 5.35355 4.64645L8 7.29289L10.6464 4.64645C10.8417 4.45118 11.1583 4.45118 11.3536 4.64645C11.5488 4.84171 11.5488 5.15829 11.3536 5.35355L8.70711 8L11.3536 10.6464C11.5488 10.8417 11.5488 11.1583 11.3536 11.3536C11.1583 11.5488 10.8417 11.5488 10.6464 11.3536L8 8.70711L5.35355 11.3536C5.15829 11.5488 4.84171 11.5488 4.64645 11.3536C4.45118 11.1583 4.45118 10.8417 4.64645 10.6464L7.29289 8L4.64645 5.35355C4.45118 5.15829 4.45118 4.84171 4.64645 4.64645Z"
                                    fill="#D83B01"
                                  />
                                </svg>
                                <span className="side-heads">
                                  Rejected Food Events
                                </span>
                              </div>
                              <div className="col-4 d-flex justify-content-end">
                                <span className="side-counts">
                                  {rejectfoodevents > 0
                                    ? rejectfoodevents
                                    : "0"}
                                </span>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>

              <div className="row container-fluid  justify-content-around">
                <div className="col-xl-6 col-lg-6 col-md-6 mb-4 pr-0">
                  <div className="Dashboard-head mb-2">
                    {" "}
                    Weekly Status
                  </div>
                  <div className="card ym-chart-card mt-1">
                    <div className="flex justify-content-between align-items-center mb-2 bar-charts">
                      {/* <div className="flex align-items-center justify-content-between mb-3">
                        <h5 className="mb-0 fw-600">Categories</h5>
                      </div> */}
                      <Barchart data={barData} options={baroptions} />
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 mb-4 pr-0">
                  <div className="Dashboard-head mb-2">
                    {" "}
                    Popular Categories
                  </div>
                  <div className="card ym-chart-card mt-1">
                    <div className="justify-content-between align-items-center mb-4">
                      <div className="row justify-content-center align-items-center doughnut-chart">
                        <Doughnutchart
                          data={categorychartData}
                          options={catoptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 mb-4 pr-0">
                  <div className="Dashboard-head">
                    {" "}
                    Cuisines
                  </div>
                  <div className="card ym-chart-card mt-1">
                    <div className="justify-content-between align-items-center mb-4">
                      <div className="row justify-content-center align-items-center doughnut-chart">
                        <Doughnutchart
                          data={cusinechartData}
                          options={cuisineoptions}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br></br>

              <div className="row container-fluid  justify-content-around">
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div className="Dashboard-head mb-2">
                    {" "}
                    Top Performing Restaurants
                  </div>
                  <div className="card ym-chart-card p-2 mt-1">
                    {Array.isArray(topareasData) && topareasData.length > 0 ? (
                      <DataTable
                        value={topareasData}
                        className="top-performing"
                        rows={5}
                      >
                        <Column field="_id" header="City" />
                        <Column field="count" header="Count" />
                      </DataTable>
                    ) : (
                      <p className="dashboard-no-results">No data available</p>
                    )}
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6">
                  <div
                    className="Dashboard-head mb-2">
                    {" "}
                    Top Areas Covered
                  </div>
                  <div className="card ym-chart-card p-2 mt-1">
                    {Array.isArray(topareasData) && topareasData.length > 0 ? (
                      <DataTable
                        value={topareasData}
                        className="top-areas"
                        rows={5}
                      >
                        <Column field="_id" header="City" />
                        <Column field="count" header="Count" />
                      </DataTable>
                    ) : (
                      <p className="dashboard-no-results">No data available</p>
                    )}
                  </div>
                </div>
              </div>
              <br></br>

              <div className="row container-fluid  justify-content-around">
                <div className="col-xl-12 col-lg-12 col-md-12 mb-4">
                  <div
                    className="Dashboard-head mb-2">
                    {" "}
                    Recently Added Restaurants
                  </div>
                  <div className="card ym-chart-card mt-1 p-2">
                    <RecentTable
                      recentdata={Recentdata}
                      first={lazyParams.first}
                      sortField={lazyParams.sortField}
                      sortOrder={lazyParams.sortOrder}
                      totalRecords={totalRecentRecords}
                      onPage={onPage}
                      onSort={onSort}
                      loading={isAdding}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
