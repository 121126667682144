import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";
import "../pending-restaurant/pending-table.css"

type RecentTableProps = {
    recentdata:any, 
    first:any, 
    sortField:any, 
    sortOrder:any,
    totalRecords:any, 
    onPage:any, 
    onSort:any, 
    loading:any, 
  }
  var width = window.screen.width;
    var c_rows = 10;
    if(width>=1920){
        c_rows=15
    }
    else if(width>=1400 || width<=1600){
        c_rows=10
    }



  // we can use children even though we haven't defined them in our CardProps
export const RecentTable: FunctionComponent<RecentTableProps> = ({ recentdata, first, sortField,sortOrder, totalRecords, onPage, onSort, loading}) => 
  <DataTable 
  value={recentdata} 
  lazy
  filterDisplay="row" 
  responsiveLayout="scroll" 
  first={first} 
  rows={c_rows} 
  totalRecords={totalRecords} 
  onPage={onPage}
  onSort={onSort} 
  sortField={sortField} 
  sortOrder={sortOrder}
  loading={loading}
  className='recent-added-table'
  >
  <Column field="name" header="Restaurant Name" style={{ fontFamily: 'roboto' }} sortable />
  <Column field="city" sortable header="City" style={{ fontFamily: 'roboto' }}/>
  {/* <Column field="foodtype" sortable filter header="Food Type" filterPlaceholder="Search by food type" />
  <Column field="agentname" header="Agent"  filter filterPlaceholder="Search by agent" /> */}
  {/* <Column field="eaterytype" header="Eatery Type" style={{ fontFamily: 'roboto' }} sortable body={(rowData) => {
        console.log('fgetf',rowData.eaterytype)
        return (rowData.eaterytype).join(', ')}}/>  */}
        <Column field="eaterytype" header="Eatery Type" style={{ fontFamily: 'roboto' }} sortable   body={(rowData) =>
          rowData.eaterytype.map((eatery:any) => eatery.name).join(', ')
        }/> 

</DataTable>