import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
type props = {
    data: {};
    options:{};
  };
const Barchart: React.FC<props> = ({data,options}) => {
    return (

      
       
        //  className="w-full md:w-40rem md:h-40rem" width='50' height='33'
        //  style={{display: "flex", justifyContent: "center"}}
        <Chart type="bar" data={data} options={options} />

        );
    };
    
    export default Barchart;