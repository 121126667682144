import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Panel } from "primereact/panel";
import { ScrollPanel } from "primereact/scrollpanel";
import React, { useEffect, useState, useRef } from "react";
import "./customStepper.component.css";
import DropFileInput from "../../pages/restaurants/drop-file-input/drop-file-input";
import { Controller, useForm } from "react-hook-form";
import { SearchRestaurant, getCategories } from "../../api/addRestarant.api";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { getRefreshedToken, isTokenExpired } from "../../api/auth.api";
import { SubmitRestaurant } from "../../api/addRestarant.api";
import {
  AutoComplete,
  AutoCompleteChangeEvent,
  AutoCompleteCompleteEvent,
} from "primereact/autocomplete";
import Loader from "../ui/loader/loader.components";

interface CustomStepperProps {
  steps: string[];
  images: string[];
  stepdesc: string[];
}
interface restaurant {
  name: string;
  address1: string;
  images: any;
}

type resObj = {
  // lat: any;
  name: string;
  address1: string;
  // address2: string;
  sizeofestablishment: string;
  city: string;
  state: string;
  maplink: string;
  latitude: string;
  longitude: string;
  country: string;
  diettype: any[];
  pincode: string;
  landmark: string;
  website: string;
  email: string;
  phoneno: string;
  eaterytype: any[];
  foodtype: any[];
  preference: string[];
  cuisine: string[];
  ambience: string;
  serving: string[];
  mealoftheday: string[];
  serviceoptions: string[];
  values: any[];
  features: string[];
  specialitems: string[];
  source: string;
  sourcelink: string;
  about: string[];
  whyinyeldam: string[];
  zomatolink: string;
  swiggylink: string;
  dineoutlink: string;
  averagecost: string;
  district: string;
  menuitems: string[];
  menuimages: any[];
  thumbnails: any[];
  images: any[];
  tags: string;
  offers: string[];
  hygiene: number;
  health: number;
  taste: number;
  costeffective: number,
  valuesrating: number,
  hospitality: number
};
const initialState = {
  name: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  maplink: "",
  diettype: [],
  latitude: "",
  longitude: "",
  country: "",
  pincode: "",
  landmark: "",
  website: "",
  email: "",
  phoneno: "",
  eaterytype: [],
  foodtype: [],
  preference: [],
  cuisine: [],
  ambience: "",
  serving: [],
  mealoftheday: [],
  serviceoptions: [],
  values: [],
  features: [],
  specialitems: [],
  source: "",
  sourcelink: "",
  about: [],
  whyinyeldam: [],
  images: [],
  zomatolink: "",
  swiggylink: "",
  dineoutlink: "",
  averagecost: "",
  sizeofestablishment: "",
  district: "",
  menuitems: [],
  menuimages: [],
  restaurantimages: [],
  thumbnails: [],
  tags: "",
  offers: [],
  hygiene: 0,
  health: 0,
  taste: 0,
  costeffective: 0,
  valuesrating: 0,
  hospitality: 0
};

const CustomStepper: React.FC<CustomStepperProps> = ({
  steps,
  images,
  stepdesc,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [checked, setChecked] = useState(false); // check box
  const [stepColors, setStepColors] = useState(Array(steps.length).fill(""));
  const [thumbnailImages, setThumbnailImages] = useState<any[]>([]);
  const [menuImages, setMenuImages] = useState<any[]>([]);
  const [restaurantImages, setRestantImages] = useState<any[]>([]);
  const [isAdding, setIsadding] = useState(false);
  const [resObj, setResObj] = useState<resObj>(initialState);
  const [spitems, setspitems] = useState<any>([]);
  const [menutems, setmenutems] = useState<any>([]);
  const [whyyeldam, setwhyyeldam] = useState<any>(null);
  const [aboutrest, setaboutrest] = useState<any>(null);
  const [services, setServices] = useState<string[]>([]);
  const [restaurantTages, setRestaurantTages] = useState(null);
  const [value, setValue] = useState<any | null>(null);
  const [cuisine, setCuisine] = useState<any>(null);
  const [diet, setDiet] = useState<any | null>(null);
  const [serviceopt, setServiceopt] = useState<any | null>(null);
  const [foodType, setFoodType] = useState<any | null>(null);
  const [eatery, setEatery] = useState<any | null>(null);
  const [ambience, setAmbience] = useState<any[]>([]);
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();
  const [mod, setMod] = useState<string[]>([]);
  const [valueslist, setValueslist] = useState([]);
  const [cuisinelist, setCuisinelist] = useState([]);
  const [diettypelist, setDiettypelist] = useState([]);
  const [foodtypelist, setFoodtypelist] = useState([]);
  const [eaterytypelist, setEaterytypelist] = useState([]);
  const [ambiencelist, setAmbiencelist] = useState([]);
  const [serviceoptions, setServiceoptions] = useState([]);

  const toast = useRef<Toast>(null);

  //for timing
  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<
    Record<string, string[]>
  >(initialSelectedTimings);

  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter((day) => checkedDays[day]);

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);

        // Directly set the updatedTimings without wrapping it in an array
        //setResObj({ ...resObj, timings: updatedTimings });

        return updatedTimings;
      });
    }
  };

  const SpecialItemss = (e: any) => {
    const items = e.target.value;
    const itemsdata = items.split(",");
    setspitems(itemsdata);
    setResObj({ ...resObj, specialitems: itemsdata });
  };
  const MenuItemss = (items: any) => {
    // const actualArray = JSON.parse(items);
    console.log("menuitems", items);
    setmenutems(items);
    setResObj({ ...resObj, menuitems: items });
  };


  //for converting Whyyeldam type form string to string[]
  const WhyinYeldam = (e: any) => {
    const why = e.target.value;
    const whyus = why.split(".");
    setwhyyeldam(whyus);
    setResObj({ ...resObj, whyinyeldam: whyus });
  };
  //for converting aboutRestaurant type form string to string[]

  const About = (e: any) => {
    const restabout = e.target.value;
    const about = restabout.split(".");
    setaboutrest(about);
    setResObj({ ...resObj, about: about });
  };

  const handleNext = () => {
    console.log("clicked next");
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    updateStepColors();
    console.log(resObj);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    updateStepColors();
    console.log(resObj);
    reset(defaultValues);
  };

  const updateStepColors = () => {
    const newStepColors = [...stepColors];
    newStepColors[activeStep] = "lightblue"; // Change this to the desired color
    setStepColors(newStepColors);
  };

  const Services = (e: any) => {
    let _services = [...services];

    if (e.checked) _services.push(e.value);
    else _services.splice(_services.indexOf(e.value), 1);

    setServices(_services);
    setResObj({ ...resObj, serving: _services });
  };

  const MOD = (e: any) => {
    let _mod = [...mod];

    if (e.checked) _mod.push(e.value);
    else _mod.splice(_mod.indexOf(e.value), 1);

    setMod(_mod);
    setResObj({ ...resObj, mealoftheday: _mod });
  };

  const [preferences, setPreferences] = useState<string[]>([]);
  const Preferences = (e: any) => {
    let _preferences = [...preferences];

    if (e.checked) _preferences.push(e.value);
    else _preferences.splice(_preferences.indexOf(e.value), 1);

    setPreferences(_preferences);
    setResObj({ ...resObj, preference: _preferences });
  };

  const [features, setFeatures] = useState<string[]>([]);
  const Features = (e: any) => {
    let _features = [...features];

    if (e.checked) _features.push(e.value);
    else _features.splice(_features.indexOf(e.value), 1);

    setFeatures(_features);
    setResObj({ ...resObj, features: _features });
  };

  // for Controller values
  const defaultValues = {
    restaurantname: "",
    latitude: "",
    longitude: "",
    address: "",
    city: "",
    district: "",
    state: "",
    country: "",
    pincode: "",
    specialitem: "",
    average: "",
    eaterytype: "",
    foodtype: "",
    ambience: "",
    aboutrestaurant: "",
    whyyeldam: "",
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    // getValues,
    reset,
  } = useForm({ defaultValues });


  const fetchAddress = async (lat: any, long: any) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=AIzaSyBadx8pwSuzfCbHeQaRPa36kaOFhMj7KrQ`
      );
      const data = await response.json();
      console.log(data)

      let address = '';
      let district = '';
      let city = '';
      let state = '';
      let pincode = '';
      let countryName = '';

      data.results.forEach((result: any) => {
        result.address_components.forEach((component: any) => {
          if (component.types.includes('street_number') || component.types.includes('route') || component.types.includes('street_address')) {
            address += component.long_name + ', ';
          } else if (component.types.includes('locality')) {
            city = component.long_name;
          } else if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name;
          } else if (component.types.includes('administrative_area_level_3')) {
            district = component.long_name;
          } else if (component.types.includes('postal_code')) {
            pincode = component.long_name;
          }
          else if (component.types.includes('country')) {
            countryName = component.long_name;
          }
        });
      });

      address = address.slice(0, -2);

      setResObj({ ...resObj, address1: address, district: district, city: city, state: state, pincode: pincode, country: countryName });

    } catch (error) {
      console.error("Error fetching city:", error);
    }
  };



  // for display the error messages for mandatory fields
  const getFormErrorMessage = (name: string) => {
    const errorObject = errors[name as keyof typeof defaultValues];

    return errorObject ? (
      <small className="p-error">{errorObject.message}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];
  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const tag = [
    { name: "High in Demand", code: "HD" },
    { name: "Popular for Music", code: "RM" },
    { name: "Popular for Food", code: "LDN" },
    { name: "Popular for Ambience", code: "IST" },
  ];

  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });

  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };
  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };

  const handleImageClick = (index: number) => {
    setActiveStep(index);
  };

  const onAmbienceChange = (e: any) => {
    setAmbience(e.value);
    setResObj({ ...resObj, ambience: e.value });
  };

  const onEateryChange = (e: any) => {
    setEatery(e.value);
    setResObj({ ...resObj, eaterytype: e.value });
  };

  const onFoodTypeChange = (e: any) => {
    setFoodType(e.value);
    setResObj({ ...resObj, foodtype: e.value });
  };

  const onDietChange = (e: any) => {
    setDiet(e.value);
    setResObj({ ...resObj, diettype: e.value });
  };

  const OnServiceOptionschange = (e: any) => {
    const optionNames: string[] = e.value.map((option: { name: any; }) => option.name);

    setServiceopt(e.value);
    setResObj({ ...resObj, serviceoptions: optionNames });
  };

  const onCuisineChange = (e: any) => {
    setCuisine(e.value);
    setResObj({
      ...resObj,
      cuisine: e.value,
    });
  };

  const onValueChange = (e: any) => {
    setValue(e.value);
    setResObj({ ...resObj, values: e.value });
  };

  const onTagchange = (e: any) => {
    setRestaurantTages(e.value);
    console.log(e.value)
    setResObj({ ...resObj, tags: e.value.name });
  };
  const ResetForm = () => {
    console.log("resetting the form");
    setIsadding(true);
    window.location.reload();
    setResObj(initialState);
  };
  const SubmitResObj = async () => {
    console.log("going inside submit-form");
    console.log("selectedTimingsobj", resObj);

    const show = (message: any, severity: any, summary: any) => {
      console.log("ouside of toast");
      if (toast.current) {
        console.log("going in toast");
        toast.current.show({
          severity: severity,
          summary: summary,
          detail: message,
        });
      } else {
        console.log("toast issue");
      }
    };
    setIsadding(true);
    console.log("ResObj ", resObj);
    setTimeout(async () => {
      if (
        resObj.name.length === 0 ||
        resObj.address1.length === 0 ||
        resObj.city.length === 0 ||
        resObj.state.length === 0 ||
        resObj.pincode.length === 0 ||
        resObj.country.length === 0 ||
        resObj.latitude.length === 0 ||
        resObj.longitude.length === 0 ||
        resObj.averagecost.length === 0 ||
        resObj.eaterytype.length === 0 ||
        resObj.foodtype.length === 0 ||
        resObj.about.length === 0 ||
        resObj.whyinyeldam.length === 0
        // resObj.images.length === 0
      ) {
        show("Please check all Mandatory filed", "warn", "Warn");
        setIsadding(false);
      } else {
        const formData = new FormData();

        Object.entries(resObj).forEach(([key, value]) => {
          console.log(key, value);
          if (
            key === "foodtype" ||
            key === "diettype" ||
            key === "cuisine" ||
            key === "values" ||
            key === "eaterytype"
          ) {
            if (Array.isArray(value)) {
              value.forEach((val, index) => {
                const newVal = `${key}[${index}]`;
                formData.append(`${newVal}[name]`, val.name);
                formData.append(`${newVal}[code]`, val.code);
              });
            }
          } else {
            if (Array.isArray(value)) {
              value.forEach((item) => {
                formData.append(key, item);
              });
            } else {
              formData.append(key, String(value));
            }
          }
        });

        const timingsJSON = JSON.stringify(selectedTimings);

        // Append the timings JSON string to the FormData object
        formData.append("timings", timingsJSON);

        if (isTokenExpired()) {
          await getRefreshedToken();
        } else {
          await SubmitRestaurant(formData)
            .then((response: any) => {
              if (response.status === 201) {
                setIsadding(false);
                show("Added Successful", "info", "Info");

                setTimeout(() => {
                  ResetForm();
                  //reset();
                }, 3000);
              } else {
                setIsadding(false);
                show("Invalid Access", "error", "Error");
              }
            })
            .catch((error: any) => {
              console.log(error.response.status);
              setIsadding(false);
              if (error.response.status === 403) {
                show("Token Expired", "warn", "Warn");
              }
            });
        }
      }
    }, 1000);
  };

  const [search, setSearch] = useState("");
  const [suggestions, setSuggestions] = useState<resObj[]>([]);
  const [suggest, setSuggest] = useState(false);
  const SearchResBar = async (e: any) => {
    setSearch(e.value);

    if (e.target.value.length >= 3) {
      setTimeout(async () => {
        const resData = await SearchRestaurant(e.target.value);
        console.log(resData.data.data);
        setSuggestions(resData.data.data);
        setSuggest(true);
      }, 1000);
    } else {
      setSuggest(false);
    }
  };

  //api calls

  const get_categories = async () => {
    const response = await getCategories();
    console.log("resoojosdbvksv", response.data.data)
    response.data.data.forEach((item: any) => {
      switch (item.type) {
        case 'ambience':
          setAmbiencelist(item.value);
          break;
        case 'cuisines':
          setCuisinelist(item.value);
          break;
        case 'values':
          setValueslist(item.value);
          break;
        case 'serviceoptions':
          setServiceoptions(item.value);
          break;
        case 'eaterytype':
          setEaterytypelist(item.value);
          break;
        case 'foodtype':
          setFoodtypelist(item.value);
          break;
        case 'diettype':
          setDiettypelist(item.value);
          break;
        default:
          break;
      }
    });
  };





  const searchs = (event: AutoCompleteCompleteEvent) => {
    let _filteredRest;

    if (!event.query.trim().length) {
      _filteredRest = [...suggestions];
    } else {
      _filteredRest = suggestions.filter((rest) => {
        return rest.name.toLowerCase().startsWith(event.query.toLowerCase());
      });
    }
    setSuggestions(_filteredRest);
  };

  // console.log("activeStep", activeStep);
  // console.log(pathname_s);
  const RequiredField: React.FC = ({ }) => {
    return (
      <span className="field" style={{ fontSize: "11px", color: "red" }}>
        Required Field
      </span>
    );
  };
  useEffect(() => {
    // get_states();
    get_categories();
  }, []);

  const itemTemplate = (item: restaurant) => {
    return (
      <div className="flex align-items-center">
        <li className="justify-content-between">
          <div className="flex align-items-center">
            <img
              alt=""
              src={item.images[0]}
              className={`mr-2`}
              style={{ width: "18px", height: "18px" }}
            />
            <div>{item.name}</div>
          </div>
          <div>{item.address1}</div>
        </li>
      </div>
    );
  };

  const [hygienerating, sethygienerating] = useState<number | null>(null);
  const [healthrating, sethealthrating] = useState<number | null>(null);
  const [tasterating, settasterating] = useState<number | null>(null);
  const [effectivecostrating, seteffectivecostrating] = useState<number | null>(null);
  const [valuesrating, setvaluesrating] = useState<number | null>(null);
  const [hospitalityrating, sethospitalityrating] = useState<number | null>(null);
  const [boxNumberColors, setBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [hygieneboxNumberColors, sethygieneBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [tasteboxNumberColors, settasteBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [costboxNumberColors, setcostBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [valueboxNumberColors, setvalueBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [hospitalityboxNumberColors, sethospitalityBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );

  const handleHygieneRatingClick = (value: number) => {
    sethygienerating(value);
    setResObj(prevState => ({ ...prevState, hygiene: value }));
    console.log("hygiene", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    // Update background color
    const updatedColors = { ...hygieneboxNumberColors, [value - 1]: backgroundColor };
    sethygieneBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;

  };

  const handleHealthRatingClick = (value: number) => {
    sethealthrating(value);
    setResObj(prevState => ({ ...prevState, health: value }));
    console.log("health", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }

    // Update background color
    const updatedColors = { ...boxNumberColors, [value - 1]: backgroundColor };
    setBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleTasteRatingClick = (value: number) => {
    settasterating(value);
    setResObj(prevState => ({ ...prevState, taste: value }));
    console.log("taste", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...tasteboxNumberColors, [value - 1]: backgroundColor };
    settasteBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleCostEffectiveRatingClick = (value: number) => {
    seteffectivecostrating(value);
    setResObj(prevState => ({ ...prevState, costeffective: value }));
    console.log("cost", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...costboxNumberColors, [value - 1]: backgroundColor };
    setcostBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleValueRatingClick = (value: number) => {
    setvaluesrating(value);
    setResObj(prevState => ({ ...prevState, valuesrating: value }));
    console.log("values", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...valueboxNumberColors, [value - 1]: backgroundColor };
    setvalueBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleHospitalityRatingClick = (value: number) => {
    sethospitalityrating(value);
    setResObj(prevState => ({ ...prevState, hospitality: value }));
    console.log("hospitality", value);
    let backgroundColor = "";

    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...hospitalityboxNumberColors, [value - 1]: backgroundColor };
    sethospitalityBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };



  return (
    <>
      {!isAdding ? "" : <Loader />}

      <div className="d-flex justify-content-center align-items-center">
        <div className="col d-flex justify-content-center align-items-center">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "85vh" }}
          >
            <ol className="c-stepper">
              {steps.map((label, index) => (
                <li key={label} className={`c-stepper__item`}>
                  <img
                    className="c-stepper__item__img"
                    src={images[index]}
                    style={{ fill: "#00ff00" }}
                    onClick={() => handleImageClick(index)}
                  />
                  <div className="c-stepper__content">
                    <h3 className="c-stepper__title">Step {index + 1}</h3>
                    <p className="c-stepper__disc">{stepdesc[index]}</p>
                    <p
                      className="fs-12"
                      style={{
                        color: index === activeStep ? "#8856BB" : "green",
                      }}
                    >
                      {index === activeStep ? "In progress" : "Completed"}
                    </p>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </div>

        <div className="col">
          <div
            className="card details-card"
            style={{
              width: "calc(100vw - 60vw)",
              height: "92vh",
              borderRadius: "12px",
            }}
          >
            <div className="next-card card-body pb-0">
              <Toast ref={toast}></Toast>

              {activeStep === 0 && (
                <>
                  <div className="row m-0">
                    <div className="col-12 p-1">
                      <div className="child-left" id="RestaurantDetails">
                        <h4 className="rest-detail-head">Restaurant Details</h4>
                        <hr></hr>
                        <ScrollPanel className="addrest-scrollpanel" style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                          {/* <div className="row">
                            <label className="ym-input-label">
                              SEARCH EXISTING RESTAURANT
                            </label>

                            <div className="col-lg-12 col-md-12 col-sm-12">
                              <div className="two-s">
                                <div className="flex gap-3">
                                  <div className="search p-inputgroup flex-1">
                                    <AutoComplete
                                      id="search"
                                      className="Restaurant"
                                      placeholder="Search Restaurant Name..."
                                      style={{ fontFamily: "roboto" }}
                                      value={search}
                                      suggestions={suggestions}
                                      onSelect={(e: any) => {
                                        setSearch(e.value.name);
                                      }}
                                      completeMethod={searchs}
                                      onChange={(
                                        e: AutoCompleteChangeEvent
                                      ) => {
                                        SearchResBar(e);
                                      }}
                                      itemTemplate={itemTemplate}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br></br> */}

                          <div className="row">
                            <Controller
                              name="restaurantname"
                              control={control}
                              rules={{
                                required: "Restaurant name  is required.",
                              }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="ym-input-label">
                                    Restaurant Name{" "}
                                    <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      placeholder="Enter Restaurant Name"
                                      maxLength={50}
                                      id={field.name}
                                      value={resObj.name}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        setResObj({
                                          ...resObj,
                                          name: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>

                          <div className="row">
                            <label className="ym-input-label">
                              PHONE NUMBER
                            </label>
                            <div className="flex flex-column">
                              <InputText
                                id="phoneno"
                                className="no-spinner"
                                maxLength={14}
                                value={resObj.phoneno}
                                onChange={(e: any) => {
                                  setResObj({
                                    ...resObj,
                                    phoneno: e.target.value,
                                  });
                                }}
                                placeholder="Enter Phone Number"
                                aria-describedby="username-help"

                                keyfilter={/^[0-9+\-\s]*$/}
                              />
                            </div>
                          </div>
                          <br></br>

                          <div className="row">
                            <div className="d-flex align-items-baseline gap-1">
                              <label className="ym-input-label">
                                EMAIL Address
                              </label>
                              <i
                                className="bi bi-info-circle"
                                title="Enter the vaild email"
                                id="icon"
                              ></i>
                            </div>

                            <div className="flex flex-column gap-2">
                              <InputText
                                id="email"
                                maxLength={50}
                                value={resObj.email}
                                placeholder="Enter Email Address"
                                aria-describedby="username-help"
                                onChange={(e: any) => {
                                  setResObj({
                                    ...resObj,
                                    email: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <br></br>
                          <div className="row">
                            <div className="d-flex align-items-baseline gap-1">
                              <label className="ym-input-label">
                                Website URL{" "}
                              </label>
                              <i
                                className="bi bi-info-circle"
                                title="Enter the vaild Website URL"
                                id="icon"
                              ></i>
                            </div>
                            <div className="flex flex-column gap-2">
                              <InputText
                                id="website"
                                maxLength={50}
                                value={resObj.website}
                                placeholder="Enter Website URL"
                                aria-describedby="username-help"
                                onChange={(e: any) => {
                                  setResObj({
                                    ...resObj,
                                    website: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col ">
                              <div className="">
                                <Controller
                                  name="latitude"
                                  control={control}
                                  rules={{
                                    required: "latitude is required.",
                                    pattern: {
                                      value: /^[0-9]+(\.[0-9]+)?$/,
                                      message: "Enter a valid latitude with only numbers and dots.",
                                    }
                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <div className="d-flex align-items-baseline">
                                        <label className="ym-input-label ">
                                          Latitude
                                          <b style={{ color: "red" }}>*</b>
                                        </label>
                                        <i
                                          className="bi bi-info-circle ml-1"
                                          title="Enter the vaild latitube"
                                          id="icon"
                                        ></i>
                                      </div>
                                      <div className="flex flex-column">
                                        <InputText
                                          maxLength={30}
                                          placeholder="Enter a vaild latitude"
                                          id={field.name}
                                          value={resObj.latitude}
                                          className={classNames({
                                            "p-invalid": fieldState.error,
                                          })}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              latitude: e.target.value,
                                            });
                                            // fetchAddress(e.target.value,resObj.longitude)
                                          }}
                                        // keyfilter={/^\d*\.?\d*$/} 
                                        />
                                      </div>
                                      {getFormErrorMessage(field.name)}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="">
                                <Controller
                                  name="longitude"
                                  control={control}
                                  rules={{
                                    required: "logitube is required.",

                                  }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <div className="d-flex align-items-baseline">
                                        <label className="ym-input-label">
                                          Longitude
                                          <b style={{ color: "red" }}>*</b>
                                        </label>
                                        <i
                                          className="bi bi-info-circle ml-1"
                                          title="Enter the vaild logitude"
                                          id="icon"
                                        ></i>
                                      </div>
                                      <div className="flex flex-column">
                                        <InputText
                                          maxLength={30}
                                          placeholder="Enter a vaild logitude"
                                          id={field.name}
                                          value={resObj.longitude}
                                          className={classNames({
                                            "p-invalid": fieldState.error,
                                          })}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              longitude: e.target.value,
                                            });
                                            // fetchAddress(resObj.latitude,e.target.value)
                                          }}
                                        // keyfilter={/^[d]*\.?\d*$/}
                                        />
                                      </div>
                                      {getFormErrorMessage(field.name)}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="d-flex justify-content-center align-items-center mb-1">
                            <Button className="get-address" label="Get Address" severity="success" outlined aria-label="Search"
                              onClick={() => fetchAddress(resObj.latitude, resObj.longitude)}
                            />
                          </div>

                          <div className="row mt-3">
                            <Controller
                              name="address"
                              control={control}
                              rules={{ required: "Address  is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="ym-input-label">
                                    Address <b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputTextarea
                                      maxLength={130}
                                      id={field.name}
                                      {...field}
                                      placeholder="Enter Address Line"
                                      value={resObj.address1}
                                      // onChange={(e) => setAddress(e.target.value)}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        setResObj({
                                          ...resObj,
                                          address1: e.target.value,
                                        });
                                      }}
                                      rows={5}
                                      cols={30}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                          <div className="row ">
                            <div className="col m-0">
                              <div className="">
                                <Controller
                                  name="city"
                                  control={control}
                                  rules={{ required: "city is required." }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label ">
                                        City<b style={{ color: "red" }}>*</b>
                                      </label>

                                      <div className="flex flex-column">
                                        <InputText
                                          maxLength={30}
                                          id={field.name}
                                          value={resObj.city}
                                          className={classNames({
                                            "p-invalid": fieldState.error,
                                          })}
                                          placeholder="Enter City"
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              city: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                      {getFormErrorMessage(field.name)}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="">
                                <Controller
                                  name="district"
                                  control={control}
                                  rules={{ required: "district is required." }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label">
                                        District
                                        <b style={{ color: "red" }}>*</b>
                                      </label>

                                      <div className="flex flex-column">
                                        <InputText
                                          maxLength={30}
                                          placeholder="Enter District"
                                          id={field.name}
                                          value={resObj.district}
                                          className={classNames({
                                            "p-invalid": fieldState.error,
                                          })}
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              district: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                      {getFormErrorMessage(field.name)}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col ">
                              <div className="">
                                <Controller
                                  name="state"
                                  control={control}
                                  rules={{ required: "state is required." }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label ">
                                        State<b style={{ color: "red" }}>*</b>
                                      </label>

                                      <div className="flex flex-column">
                                        <InputText
                                          maxLength={30}
                                          id={field.name}
                                          value={resObj.state}
                                          className={classNames({
                                            "p-invalid": fieldState.error,
                                          })}
                                          placeholder="Enter State"
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              state: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                      {getFormErrorMessage(field.name)}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                            <div className="col">
                              <div className="">
                                <Controller
                                  name="country"
                                  control={control}
                                  rules={{ required: "country is required." }}
                                  render={({ field, fieldState }) => (
                                    <>
                                      <label className="ym-input-label ">
                                        Country<b style={{ color: "red" }}>*</b>
                                      </label>

                                      <div className="flex flex-column">
                                        <InputText
                                          maxLength={30}
                                          id={field.name}
                                          value={resObj.country}
                                          className={classNames({
                                            "p-invalid": fieldState.error,
                                          })}
                                          placeholder="Entry Country"
                                          onChange={(e) => {
                                            field.onChange(e);
                                            setResObj({
                                              ...resObj,
                                              country: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                      {getFormErrorMessage(field.name)}
                                    </>
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <Controller
                              name="pincode"
                              control={control}
                              rules={{ required: "pincode  is required." }}
                              render={({ field, fieldState }) => (
                                <>
                                  <label className="ym-input-label">
                                    Pincode<b style={{ color: "red" }}>*</b>
                                  </label>
                                  <div className="flex flex-column">
                                    <InputText
                                      maxLength={6}
                                      placeholder="Enter Pincode"
                                      id={field.name}
                                      value={resObj.pincode}
                                      className={classNames({
                                        "p-invalid": fieldState.error,
                                      })}
                                      onChange={(e) => {
                                        field.onChange(e);
                                        setResObj({
                                          ...resObj,
                                          pincode: e.target.value,
                                        });
                                      }}
                                      keyfilter={/^[0-9]*$/}

                                    />
                                  </div>
                                  {getFormErrorMessage(field.name)}
                                </>
                              )}
                            />
                          </div>
                        </ScrollPanel>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <div className="row m-0">
                    <div className="col-12">
                      <div className="child-left" id="FoodPerferences">
                        <h4 className="rest-detail-head">Food Preference & Dining Details</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                            <div className="row">
                              <label className="ym-input-label mt-2">
                                Preferences
                              </label>
                              <div className="row">
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="preference1"
                                      name="preferences"
                                      value="Veg"
                                      onChange={Preferences}
                                      checked={preferences.includes("Veg")}
                                    />
                                    <label htmlFor="preference1" className="pref-label">
                                      Veg
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="preference2"
                                      name="preferences"
                                      value="Non-Veg"
                                      onChange={Preferences}
                                      checked={preferences.includes("Non-Veg")}
                                    />
                                    <label htmlFor="preference2" className="pref-label">
                                      Non Veg
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="preference3"
                                      name="preferences"
                                      value="Pure-veg"
                                      onChange={Preferences}
                                      checked={preferences.includes("Pure-veg")}
                                    />
                                    <label htmlFor="preference3" className="pref-label">
                                      Pure Veg
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <label className="ym-input-label mt-2">
                                serving
                              </label>
                              <div className="row">
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="service1"
                                      name="services"
                                      value="Ala Carte"
                                      onChange={Services}
                                      checked={services.includes("Ala Carte")}
                                    />
                                    <label htmlFor="service1" className="pref-label">
                                      Ala Carte
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="service2"
                                      name="services"
                                      value="Buffet"
                                      onChange={Services}
                                      checked={services.includes("Buffet")}
                                    />
                                    <label htmlFor="service2" className="pref-label">
                                      Buffet
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="service3"
                                      name="services"
                                      value="Meals"
                                      onChange={Services}
                                      checked={services.includes("Meals")}
                                    />
                                    <label htmlFor="service3" className="pref-label">
                                      Meals
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2" >
                                    <Checkbox
                                      inputId="service5"
                                      name="services"
                                      value="Thali"
                                      onChange={Services}
                                      checked={services.includes("Thali")}
                                    />
                                    <label htmlFor="service5" className="pref-label">
                                      Thali
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="service6"
                                      name="services"
                                      value="Self Cooking"
                                      onChange={Services}
                                      checked={services.includes("Self Cooking")}
                                    />
                                    <label htmlFor="service6" className="pref-label">
                                      Self Cooking
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <label className="ym-input-label mt-2">
                                Meal Of The Day{" "}
                              </label>
                              <div className="row">
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="mod1"
                                      name="mods"
                                      value="Breakfast"
                                      onChange={MOD}
                                      checked={mod.includes("Breakfast")}
                                    />
                                    <label htmlFor="mod1" className="pref-label">
                                      Breakfast
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="mod2"
                                      name="mods"
                                      value="Lunch"
                                      onChange={MOD}
                                      checked={mod.includes("Lunch")}
                                    />
                                    <label htmlFor="mod2" className="pref-label">
                                      Lunch
                                    </label>
                                  </div>
                                </div>

                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="mod3"
                                      name="mods"
                                      value="Dinner"
                                      onChange={MOD}
                                      checked={mod.includes("Dinner")}
                                    />
                                    <label htmlFor="mod3" className="pref-label">
                                      Dinner
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="mod4"
                                      name="mods"
                                      value="Brunch"
                                      onChange={MOD}
                                      checked={mod.includes("Brunch")}
                                    />
                                    <label htmlFor="mod4" className="pref-label">
                                      Brunch
                                    </label>
                                  </div>
                                </div>
                                <div className="col-3">
                                  <div className="flex align-items-center gap-2">
                                    <Checkbox
                                      inputId="mod5"
                                      name="mods"
                                      value="Snacks"
                                      onChange={MOD}
                                      checked={mod.includes("Snacks")}
                                    />
                                    <label htmlFor="mod5" className="pref-label">
                                      Snacks
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row mt-2">
                              <Controller
                                name="specialitem"
                                control={control}
                                rules={{ required: "special items is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <div className="d-flex align-items-baseline">
                                      <label className="ym-input-label">
                                        Special Items
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <i
                                        className="bi bi-info-circle ml-1"
                                        title="Enter the special items"
                                        id="icon"
                                      ></i>
                                    </div>
                                    <div className="flex flex-column">
                                      <InputTextarea
                                        maxLength={250}
                                        placeholder="Enter Special items"
                                        value={spitems}
                                        rows={5}
                                        cols={30}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          SpecialItemss(e);
                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>

                            <div className="row">
                              <Controller
                                name="specialitem"
                                control={control}
                                rules={{ required: "special items is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <div className="d-flex align-items-baseline">
                                      <label className="ym-input-label">
                                        Menu Items
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <i
                                        className="bi bi-info-circle ml-1"
                                        title="Enter the special items"
                                        id="icon"
                                      ></i>
                                    </div>
                                    <div className="flex flex-column">
                                      <InputTextarea
                                        placeholder="Enter Menu items"
                                        value={menutems}
                                        rows={5}
                                        cols={30}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          const itemsString = e.target.value.trim();
                                          const regex = /"(.*?)"/g;
                                          const cleanedString = itemsString.match(regex) || [];
                                          const items = cleanedString.map((item) => item.replace(/"/g, ''));
                                          MenuItemss(items);

                                        }}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>


                            <div className="row">
                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="average"
                                    control={control}
                                    rules={{
                                      required: "average cost is required.",
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline">
                                          <label className="ym-input-label ">
                                            Average cost (per 2 person)
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Please enter the average cost per 2 persons that can be spent in that restaurant"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <InputText
                                            maxLength={6}
                                            id={field.name}
                                            value={resObj.averagecost}
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                            placeholder="Enter Amount"
                                            onChange={(e) => {
                                              field.onChange(e);
                                              setResObj({
                                                ...resObj,
                                                averagecost: e.target.value,
                                              });
                                            }}
                                            keyfilter={/^[0-9]*$/}
                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="d-flex align-items-baseline gap-1">
                                  <label className="ym-input-label">
                                    Ambience
                                  </label>
                                  <i
                                    className="bi bi-info-circle ml-1"
                                    title="Please enter the average cost per 2 persons that can be spent in that restaurant"
                                    id="icon"
                                  ></i>
                                </div>
                                <div className="flex flex-column">
                                  <Dropdown
                                    placeholder="Select Ambience"
                                    options={ambiencelist}
                                    value={ambience}
                                    onChange={onAmbienceChange}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="eaterytype"
                                    control={control}
                                    rules={{
                                      required: "eatery type is required.",
                                    }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline gap-1">
                                          <label className="ym-input-label ">
                                            Eatery type
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Select the eatery type for the restaurant"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <MultiSelect
                                            // id="eater"
                                            value={eatery}
                                            options={eaterytypelist}
                                            // onChange={onEateryChange}
                                            onChange={(
                                              e: MultiSelectChangeEvent
                                            ) => {
                                              field.onChange(e.value);
                                              onEateryChange(e);
                                            }}
                                            optionLabel="name"
                                            placeholder="Select"
                                            display="chip"
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="">
                                  <Controller
                                    name="foodtype"
                                    control={control}
                                    rules={{ required: "foodtype is required." }}
                                    render={({ field, fieldState }) => (
                                      <>
                                        <div className="d-flex align-items-baseline gap-1">
                                          <label className="ym-input-label ">
                                            Food Type
                                            <b style={{ color: "red" }}>*</b>
                                          </label>
                                          <i
                                            className="bi bi-info-circle ml-1"
                                            title="Select the food type for the restaurant"
                                            id="icon"
                                          ></i>
                                        </div>
                                        <div className="flex flex-column">
                                          <MultiSelect
                                            id="food-type"
                                            value={foodType}
                                            options={foodtypelist}
                                            onChange={(
                                              e: MultiSelectChangeEvent
                                            ) => {
                                              field.onChange(e.value);
                                              onFoodTypeChange(e);
                                            }}
                                            optionLabel="name"
                                            placeholder="Select"
                                            display="chip"
                                            className={classNames({
                                              "p-invalid": fieldState.error,
                                            })}
                                          />
                                        </div>
                                        {getFormErrorMessage(field.name)}
                                      </>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col">
                                <div className="d-flex align-items-baseline gap-1">
                                  <label className="ym-input-label">
                                    Diet Type
                                  </label>
                                  <i
                                    className="bi bi-info-circle ml-1"
                                    title="Select the diet type for the restaurant"
                                    id="icon"
                                  ></i>
                                </div>

                                <div className="flex flex-column">
                                  <MultiSelect
                                    value={diet}
                                    options={diettypelist}
                                    onChange={onDietChange}
                                    optionLabel="name"
                                    placeholder="Select "
                                    display="chip"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="d-flex align-items-baseline gap-1">
                                  <label className="ym-input-label">Values</label>
                                  <i
                                    className="bi bi-info-circle ml-1"
                                    title="Select values for the restaurant"
                                    id="icon"
                                  ></i>
                                </div>
                                <div className="flex flex-column">
                                  <MultiSelect
                                    value={value}
                                    options={valueslist}
                                    onChange={onValueChange}
                                    optionLabel="name"
                                    placeholder="Select"
                                    display="chip"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col">
                                <div className="d-flex align-items-baseline gap-1">
                                  <label className="ym-input-label">Cuisine</label>
                                  <i
                                    className="bi bi-info-circle ml-1"
                                    title="Select the cuisine available in the restaurant"
                                    id="icon"
                                  ></i>
                                </div>
                                <div className="flex flex-column gap-2">
                                  <MultiSelect
                                    value={cuisine}
                                    options={cuisinelist}
                                    onChange={onCuisineChange}
                                    optionLabel="name"
                                    placeholder="Select Cuisines..."
                                    display="chip"
                                  />
                                </div>
                              </div>
                              <div className="col">
                                <div className="d-flex align-items-baseline gap-1">
                                  <label className="ym-input-label">
                                    Service Options
                                  </label>
                                  <i
                                    className="bi bi-info-circle ml-1"
                                    title="Select the diet type for the restaurant"
                                    id="icon"
                                  ></i>
                                </div>

                                <div className="flex flex-column">
                                  <MultiSelect
                                    value={serviceopt}
                                    options={serviceoptions}
                                    onChange={OnServiceOptionschange}
                                    optionLabel="name"
                                    placeholder="Select "
                                    display="chip"
                                  />
                                </div>
                              </div>

                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <div className="row m-0">
                    <div className="col-12">
                      <div className="child-lef t" id="Timings">
                        <h4 className="rest-detail-head">Timings</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                            <div className="row">
                              <label className="ym-input-label">Timings</label>
                              <div className="row">
                                {daysOfWeek.map((day) => (
                                  <div className="col-3" key={day}>
                                    <div className="d-flex align-items-center gap-2">
                                      <Checkbox
                                        inputId={`day${day}`}
                                        name="day"
                                        value={day}
                                        checked={checkedDays[day]}
                                        onChange={() => handleCheckboxChange(day)}
                                      />
                                      <label
                                        htmlFor={`day${day}`}
                                        className="pref-label"
                                      >
                                        {day}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <div className=" d-flex from-to gap-2 align-items-center mb-3">
                                <div className="from d-flex flex-column col-3">
                                  <span className="restaurant-name">From</span>
                                  <Dropdown
                                    style={{
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                    id="eater"
                                    placeholder="Select"
                                    value={fromtimes}
                                    options={Timings}
                                    onChange={onFromTimeChange}
                                  />
                                </div>
                                <div className="from d-flex flex-column col-3">
                                  <span className="restaurant-name">To</span>
                                  <Dropdown
                                    style={{
                                      width: "100%",
                                      backgroundColor: "white",
                                    }}
                                    id="eater"
                                    placeholder="Select"
                                    value={totimes}
                                    options={Timings}
                                    onChange={onToTimeChange}
                                  />
                                </div>
                                <div className="mt-3">
                                  <button
                                    className="add-button"
                                    onClick={handleAddTiming}
                                  >
                                    Add
                                  </button>
                                </div>
                              </div>
                              <div className="row d-flex justify-content-start align-items-center mt-3">
                                <div className="col-8">
                                  {Object.entries(selectedTimings).map(
                                    ([day, timing]) => (
                                      <div
                                        className="Sunday-timing col-12 gap-3 d-flex justify-content-center align-items-center mt-3"
                                        key={day}
                                      >
                                        <span className="day">{day}</span>
                                        <div className="row">
                                          <div className="col gap-1">
                                            {timing.map((time, index) => (
                                              <div className="col p-0 mt-1 gap-1">
                                                <span
                                                  className="time"
                                                  key={index}
                                                >
                                                  {time}
                                                </span>

                                                <i
                                                  className="bi bi-trash-fill"
                                                  style={{
                                                    color: "#818181",
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() => {
                                                    const updatedTimings = {
                                                      ...selectedTimings,
                                                    };
                                                    updatedTimings[day] =
                                                      updatedTimings[day].filter((t) => t !== time);

                                                    // Check if there are no times left, delete the entire day
                                                    if (
                                                      updatedTimings[day]
                                                        .length === 0
                                                    ) {
                                                      delete updatedTimings[day];
                                                    }

                                                    setSelectedTimings(
                                                      updatedTimings
                                                    );
                                                  }}
                                                ></i>
                                                <br />
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <div className="row m-0">
                    <div className="col-12">
                      <div className="child-left" id="uploadimages">
                        <h4 className="rest-detail-head">Upload Images</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                            <div className="">
                              <label className="ym-input-label">
                                Thumbnail Image
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <DropFileInput
                                uploadedFiles={thumbnailImages}
                                setUploadedFiles={setThumbnailImages}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="ThumbnailImages"
                              />
                            </div>
                            <br></br>

                            <div className="">
                              <label className="ym-input-label">
                                Restaurant Images
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <DropFileInput
                                uploadedFiles={restaurantImages}
                                setUploadedFiles={setRestantImages}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="RestaurantImages"
                              />
                            </div>
                            <br></br>
                            <div className="">
                              <label className="ym-input-label">
                                Menu Images
                              </label>
                              <DropFileInput
                                uploadedFiles={menuImages}
                                setUploadedFiles={setMenuImages}
                                resObj={resObj}
                                setResObj={setResObj}
                                purpose="MenuImages"
                              />
                            </div>
                            <br></br>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {activeStep === 4 && (
                <>
                  <div className="row m-0">
                    <div className="col-12">
                      <div className="child-left" id="AdditionalInformation">
                        <h4 className="rest-detail-head">Additional Information</h4>
                        <hr></hr>
                        <div className="">
                          <ScrollPanel style={{ width: "100%", height: "calc(100vh - 30vh)" }}>
                            <label className="ym-input-label mt-2">
                              Features and Facilities
                            </label>

                            <div className="d-flex flex-wrap gap-4">
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature1"
                                    name="features"
                                    value="Lift"
                                    onChange={Features}
                                    checked={features.includes("Lift")}
                                  />
                                  <label htmlFor="feature1" className="pref-label">
                                    Lift
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature2"
                                    name="features"
                                    value="Air Conditioned"
                                    onChange={Features}
                                    checked={features.includes("Air Conditioned")}
                                  />
                                  <label htmlFor="feature2" className="pref-label">
                                    Air Conditioned
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature3"
                                    name="features"
                                    value="Wallet Accepted"
                                    onChange={Features}
                                    checked={features.includes("Wallet Accepted")}
                                  />
                                  <label htmlFor="feature3" className="pref-label">
                                    Wallet Accepted
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature4"
                                    name="features"
                                    value="Parking"
                                    onChange={Features}
                                    checked={features.includes("Parking")}
                                  />
                                  <label htmlFor="feature4" className="pref-label">
                                    Parking
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature5"
                                    name="features"
                                    value="Wifi"
                                    onChange={Features}
                                    checked={features.includes("Wifi")}
                                  />
                                  <label htmlFor="feature5" className="pref-label">
                                    Wifi
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature6"
                                    name="features"
                                    value="Valet Parking"
                                    onChange={Features}
                                    checked={features.includes("Valet Parking")}
                                  />
                                  <label htmlFor="feature6" className="pref-label">
                                    Valet Parking
                                  </label>
                                </div>
                              </div>
                              <div className="">
                                <div className="flex align-items-center gap-2">
                                  <Checkbox
                                    inputId="feature7"
                                    name="features"
                                    value="Card Accepted"
                                    onChange={Features}
                                    checked={features.includes("Card Accepted")}
                                  />
                                  <label htmlFor="feature7" className="pref-label">
                                    Card Accepted
                                  </label>
                                </div>
                              </div>
                            </div>


                            <div className="row mt-3">
                              <div className="d-flex align-items-center gap-1">
                                <label className="ym-input-label">Tags</label>
                                <i
                                  className="bi bi-info-circle ml-1"
                                  title="Select any tags for the restaurant if any."
                                  id="icon"
                                ></i>
                              </div>
                              <div className="flex flex-column gap-2">
                                <Dropdown
                                  id="tags"
                                  value={restaurantTages}
                                  onChange={onTagchange}
                                  options={tag}
                                  optionLabel="name"
                                  placeholder="Select"
                                />
                              </div>
                            </div>
                            <br></br>
                            <div className="row">
                              <Controller
                                name="aboutrestaurant"
                                control={control}
                                rules={{
                                  required: "About restaurant is required.",
                                }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <div className="d-flex align-items-baseline gap-1">
                                      <label className="ym-input-label">
                                        About Restaurant{" "}
                                        <b style={{ color: "red" }}>*</b>
                                      </label>
                                      <i
                                        className="bi bi-info-circle ml-1"
                                        title="Enter About Restaurant"
                                        id="icon"
                                      ></i>
                                      <i></i>
                                    </div>
                                    <div className="flex flex-column">
                                      <InputTextarea
                                        maxLength={1500}
                                        value={aboutrest}
                                        placeholder="Enter About Restaurant"
                                        onChange={(e) => {
                                          field.onChange(e);
                                          About(e);
                                        }}
                                        rows={5}
                                        cols={30}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>
                            {/* why yeldam */}
                            <div className="row">
                              <Controller
                                name="whyyeldam"
                                control={control}
                                rules={{ required: "Why yeldam  is required." }}
                                render={({ field, fieldState }) => (
                                  <>
                                    <label className="ym-input-label">
                                      Why in YELDAM{" "}
                                      <b style={{ color: "red" }}>*</b>
                                    </label>
                                    <div className="flex flex-column">
                                      <InputTextarea
                                        maxLength={1500}
                                        placeholder="Why Did You Choose This Restaurant?..."
                                        value={whyyeldam}
                                        onChange={(e) => {
                                          field.onChange(e);
                                          WhyinYeldam(e);
                                        }}
                                        rows={5}
                                        cols={30}
                                        className={classNames({
                                          "p-invalid": fieldState.error,
                                        })}
                                      />
                                    </div>
                                    {getFormErrorMessage(field.name)}
                                  </>
                                )}
                              />
                            </div>

                            <div className="row Yeldam-ratings">
                              <label className="ym-input-label">
                                Yeldam Ratings{" "}
                                <b style={{ color: "red" }}>*</b>
                              </label>
                              <div className="hygine-rating-div">
                                <span className="rating-category">Hygiene</span>
                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                  {[...Array(10)].map((_, index) => (
                                    <span
                                      key={index}
                                      onClick={() => handleHygieneRatingClick(index + 1)}
                                      className="box-number"
                                      style={{ backgroundColor: hygieneboxNumberColors[index] }}

                                    >
                                      {index + 1}
                                    </span>
                                  ))}
                                </div>

                              </div>
                              <div className="hygine-rating-div">
                                <span className="rating-category">Health</span>
                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                  {[...Array(10)].map((_, index) => (
                                    <span
                                      key={index}
                                      onClick={() => handleHealthRatingClick(index + 1)}
                                      className="box-number"
                                      style={{ backgroundColor: boxNumberColors[index] }}

                                    >
                                      {index + 1}
                                    </span>
                                  ))}
                                </div>

                              </div>
                              <div className="hygine-rating-div">
                                <span className="rating-category">Taste</span>
                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                  {[...Array(10)].map((_, index) => (
                                    <span
                                      key={index}
                                      onClick={() => handleTasteRatingClick(index + 1)}
                                      className="box-number"
                                      style={{ backgroundColor: tasteboxNumberColors[index] }}
                                    >
                                      {index + 1}
                                    </span>
                                  ))}
                                </div>

                              </div>
                              <div className="hygine-rating-div">
                                <span className="rating-category">Cost Effective</span>
                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                  {[...Array(10)].map((_, index) => (
                                    <span
                                      key={index}
                                      onClick={() => handleCostEffectiveRatingClick(index + 1)}
                                      className="box-number"
                                      style={{ backgroundColor: costboxNumberColors[index] }}
                                    >
                                      {index + 1}
                                    </span>
                                  ))}
                                </div>

                              </div>
                              <div className="hygine-rating-div">
                                <span className="rating-category">Values</span>
                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                  {[...Array(10)].map((_, index) => (
                                    <span
                                      key={index}
                                      onClick={() => handleValueRatingClick(index + 1)}
                                      className="box-number"
                                      style={{ backgroundColor: valueboxNumberColors[index] }}
                                    >
                                      {index + 1}
                                    </span>
                                  ))}
                                </div>

                              </div>
                              <div className="hygine-rating-div">
                                <span className="rating-category">Hospitality</span>
                                <div className="rating d-flex justify-content-between mt-2 mb-2">
                                  {[...Array(10)].map((_, index) => (
                                    <span
                                      key={index}
                                      onClick={() => handleHospitalityRatingClick(index + 1)}
                                      className="box-number"
                                      style={{ backgroundColor: hospitalityboxNumberColors[index] }}
                                    >
                                      {index + 1}
                                    </span>
                                  ))}
                                </div>

                              </div>

                            </div>
                          </ScrollPanel>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

            </div>
            <div className="card-footer button-container">
              {activeStep === 0 ? (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="row">
                      <Button
                        className="next"
                        label="Next"
                        type="submit"
                        // icon="pi pi-check"
                        onClick={
                          handleNext
                          //handleSubmit(onSubmit)
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-between">
                  <Button
                    className="ym-add-button-previous"
                    label="Back"
                    onClick={handleBack}
                  ></Button>

                  {activeStep === 4 ? (
                    <Button
                      className="p-button-success ym-add-button"
                      label="Add Restaurant"
                      onClick={() => {
                        SubmitResObj();
                      }}
                    // disabled={currentIndex === 3}
                    ></Button>
                  ) : (
                    // <Button
                    //   className=" ym-add-button-next"
                    //   label="Next"
                    //   onClick={()=>{handleNext();
                    //     handleSubmit(onSubmit)}}
                    // // disabled={currentIndex === 3}
                    // ></Button>
                    <Button
                      className="next"
                      label="Next"
                      type="submit"
                      // icon="pi pi-check"
                      onClick={
                        handleNext
                        //handleSubmit(onSubmit)
                      }
                    />
                  )}
                </div>
              )}
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

export default CustomStepper;
