import React, { FunctionComponent } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeicons/primeicons.css";
import "../pending-restaurant/pending-table.css";
import { Tooltip } from "primereact/tooltip";


type PendingTableProps = {
  customers: any;
  first: any;
  sortField: any;
  sortOrder: any;
  totalRecords: any;
  onPage: any;
  onSort: any;
  onFilter: any;
  filters: any;
  loading: any;
  countryBodyTemplate: any;
  representativeBodyTemplate: any;
  actionBodyTemplate: any;
};
var width = window.screen.width;
var c_rows = 10;
if (width >= 1920) {
  c_rows = 15;
} else if (width >= 1400 || width <= 1600) {
  c_rows = 10;
}

// we can use children even though we haven't defined them in our CardProps
export const PendingTable: FunctionComponent<PendingTableProps> = ({
  customers,
  first,
  sortField,
  sortOrder,
  totalRecords,
  onPage,
  onSort,
  onFilter,
  filters,
  loading,
  countryBodyTemplate,
  representativeBodyTemplate,
  actionBodyTemplate,
}) => (
  <>
    <DataTable
      value={customers}
      lazy
      filterDisplay="row"
      responsiveLayout="scroll"
      // dataKey="id"
      paginator
      first={first}
      rows={c_rows}
      totalRecords={totalRecords}
      onPage={onPage}
      onSort={onSort}
      sortField={sortField}
      sortOrder={sortOrder}
      onFilter={onFilter}
      filters={filters}
      loading={loading}
    >
      <Column
        field="name"
        header="Restaurant Name"
        sortable
        filter
        filterPlaceholder="Search by Name"
      />
      <Column
        field="city"
        sortable
        header="City"
        filter
        filterPlaceholder="Search by City"
      />
          <Column
  field="eaterytype"
  header="Eatery Type"
  style={{ fontFamily: 'roboto' }}
  filter
  filterPlaceholder="Search by Eatery Type"
  body={(rowData) => {
    console.log("rowdata",rowData)
    const eateryTypes = rowData.eaterytype || []; // Ensure eaterytype is defined and default to an empty array if not
    const typeNames = eateryTypes.map((type:any) => type.name); // Extract names from the objects
    return typeNames.join(', '); // Display the names as a comma-separated string
  }}
/>

      <Column
        header="Action"
        className="action_btn"
        body={actionBodyTemplate}
        exportable={false}
        style={{ minWidth: "8rem", fontFamily: "roboto" }}
      ></Column>
    </DataTable>
  </>
);
