import React from "react";
import storeImage from "../../assets/icons/storeimage.svg";
import storeOptions from "../../assets/icons/storeoptions.svg";
import timings from "../../assets/icons/timings.svg";
import uploadimages from "../../assets/icons/uploadimages.svg";
import additional from "../../assets/icons/additional.svg";
import Navbar from "../../components/ui/navbar/sidenav.components";
import "../../pages/restaurants/add-restaurants/addrest";

import AddOrganicStore from "../../../src/components/AddOrganicStore/addOrganicStore.component";
const organicStore = () => {
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];
  const stepdesc = [
    "Organic Store Details",
    "Service Options & Preferences",
    "Timings",
    "Upload Images",
  ];

  const images = [storeImage, storeOptions, timings, uploadimages, additional];

  return (
    <>
      <main>
        <div>
          <Navbar />
          <section
            className="page-content"
            style={{ height: "100vh", background: "#F5F5F5" }}
          >
            <div className="d-flex justify-content-center align-items-center">
              <AddOrganicStore steps={steps} images={images} stepdesc={stepdesc} />
              
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default organicStore;
