import React, { useEffect, useState } from 'react';
import "./suggestedDetails.pages.css";
import Loader from "../../../components/ui/loader/loader.components";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import { GetUserSuggestedRestbyID } from '../../../api/userSuggested.api';
import { Image } from "primereact/image";
import {sas} from "../../../config/constants"

const SuggestedDetails: React.FC<any> = () => {

  const [isAdding, setIsadding] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { pathname } = location;
  const splitLocation = pathname.split

  const [restObj, setRestObj] = useState({
    _id: "",
    name: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    mobileno: "",
    images: [],

  });

  const loadLazyData = async () => {
    try {
      // setIsadding(true);

      const resData = await GetUserSuggestedRestbyID(id as string);
      console.log("suggested res Data", resData.data.data);
      setRestObj(resData.data.data);
    } catch (error) {
      console.error("Error loading data:", error);
      // setIsadding(false);
    }
  };

  useEffect(() => {
    // setIsadding(true);
    loadLazyData();
  }, [id]);

  return (
    <main>
      {!isAdding ? "" : <Loader />}
      <div className="">
        <Navbar />
        <section className="page-content">
          <div className="p-2 d-flex">
            &nbsp;
            <span
              className="back_rest_btn d-flex gap-2 align-items-center"
              onClick={() =>
                navigate(`/usersuggested`)
              }
            >
              <i className="pi pi-arrow-left"></i>
              Restaurant Details{" "}
            </span>
          </div>
          <hr />
          <div className='suggested-rest-details p-3'>
            <div className=''>
              <p className='suggested-rest-name'>{restObj.name}</p>
            </div>
            <div className=''>
              <p className='suggested-address-head'>
                <i className="pi pi-map-marker mr-1"></i>
                Address
              </p>
              <p className='suggested-rest-address'>
                {restObj.address +
                  "," +
                  restObj.city +
                  "," +
                  " " +
                  restObj.state +
                  "-" +
                  restObj.pincode +
                  "."}
              </p>
            </div>
            <div className=''>
              <p className='suggested-phone-head'>
                <i className="pi pi-phone mr-1"></i>
                Phone
              </p>
              <p className='suggested-phone-number'>
                {restObj.mobileno}
              </p>
            </div>

          </div>
          <div className='rest-tab-suggested'>
            <TabView>
              <TabPanel header="Images">
                <div className="image-gallery">
                  {restObj.images.map((image, index) => (
                    <Image className='ml-2' key={index} src={image+"?"+sas} alt={`Image ${index}`} preview/>
                  ))}
                </div>
              </TabPanel>
            </TabView>
          </div>
        </section>
      </div>
    </main>
  );
}

export default SuggestedDetails;
