import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Approved from "../../pages/restaurants/approved-restaurants/approved-restaurants";
import Dashboard from "../../pages/dashboard/dashboard";
import Pending from "../../pages/restaurants/pending-restaurants/pending-restaurants";
import Rejected from "../../pages/restaurants/rejected-restaurants/rejected-restaurants";
import Details from "../../pages/restaurants/details-restaurant/details";
import Editrestaurant from "../../pages/restaurants/edit-restaurants/editRestaurants";
import Profile from "../../pages/profile/profile";
import AddRestaurant from "../../pages/restaurants/add-restaurants/addRestaurants";
import Addrest from "../../pages/restaurants/add-restaurants/addrest";
import OrganicStore from "../../../src/pages/OrganicStore/organicStore"
import Addevents from "../../pages/FoodEvents/addEvents/addEvents";
import LoginScreen from "../../pages/LoginScreen/loginScreen.page";
import PendingStores from "../../pages/OrganicStore/PendingOrganicStores/pendingStores.pages";
import ApprovedStores from "../../pages/OrganicStore/ApprovedOrganicStores/approvedStores.pages";
import RejectedStores from "../../pages/OrganicStore/RejectedOrganicStores/rejectedStores.pages";
import StoreDetails from "../../pages/OrganicStore/OrganicStoreDetails/storeDetails";
import ApprovedEvents from "../../pages/FoodEvents/ApprovedFoodEvents/approvedEvents.pages";
import PendingEvents from "../../pages/FoodEvents/PendingFoodEvents/pendingEvents.pages";
import RejectedEvents from "../../pages/FoodEvents/RejectedFoodEvents/rejectedEvents.pages";
import UserSuggested from "../../pages/UserSuggested/UserSuggestedPage/userSuggested.pages";
import SuggestedDetails from "../../pages/UserSuggested/UserSuggestedRestaurantDetails/suggestedDetails.pages";
import SuggestedStoreDetails from "../../pages/UserSuggested/UserSuggestedStoreDetails/suggestedStoreDetails.pages";
import AddDietitian from "../../pages/Dietitians/AddDietitian/addDietitian";
import { PendingDietitian } from "../../pages/Dietitians/PendingDietitian/pendingDietitian";
import { ApprovedDietitian } from "../../pages/Dietitians/ApprovedDietitian/approvedDietitian";
import { RejectTable } from "../reject-restaurant/reject-table.components";
import { RejectedDietitian } from "../../pages/Dietitians/RejectedDietitian/rejectedDietitian";
import DietitianDetails from "../../pages/Dietitians/details-dietitians/dietitianDetails";


export const AppRouter: React.FC = () => {
  const isAuthenticated = !!localStorage.getItem("accessToken");

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginScreen />}
        />
        <Route
          path="/dashboard"
          element={isAuthenticated ? <Dashboard /> : <Navigate to="/" />}
        />
        <Route
          path="/restaurants/approved"
          element={isAuthenticated ? <Approved /> : <Navigate to="/" />}
        />
        <Route
          path="/restaurants/pending"
          element={isAuthenticated ? <Pending /> : <Navigate to="/" />}
        />
        <Route
          path="/restaurants/rejected"
          element={isAuthenticated ? <Rejected /> : <Navigate to="/" />}
        />
        {/* <Route
          path="/addrestaurant"
          element={isAuthenticated ? <Addrestaurant /> : <Navigate to="/" />}
        /> */}
        <Route
          path="/restaurants/addrestaurant"
          element={isAuthenticated ? <Addrest /> : <Navigate to="/" />}
        />

        <Route
          path="/restaurants/pending/details/:id"
          element={isAuthenticated ? <Details /> : <Navigate to="/" />}
        />
        <Route
          path="/restaurants/approved/details/:id"
          element={isAuthenticated ? <Details /> : <Navigate to="/" />}
        />
        <Route
          path="/restaurants/rejected/details/:id"
          element={isAuthenticated ? <Details /> : <Navigate to="/" />}
        />
        <Route
          path="/restaurants/approved/editrestaurant/:id"
          element={isAuthenticated ? <Editrestaurant /> : <Navigate to="/" />}
        />
        <Route
          path="/restaurants/pending/editrestaurant/:id"
          element={isAuthenticated ? <Editrestaurant /> : <Navigate to="/" />}
        />
        <Route
          path="/restaurants/rejected/editrestaurant/:id"
          element={isAuthenticated ? <Editrestaurant /> : <Navigate to="/" />}
        />
        <Route
          path="/profile"
          element={isAuthenticated ? <Profile /> : <Navigate to="/" />}
        />
        {/* route for add food event */}
        <Route
          path="/foodevents/addevent"
          element={isAuthenticated ? <Addevents /> : <Navigate to="/" />}
        />
        <Route
          path="/foodevents/approvedevent"
          element={isAuthenticated ? <ApprovedEvents /> : <Navigate to="/" />}
        />
        <Route
          path="/foodevents/pendingevent"
          element={isAuthenticated ? <PendingEvents /> : <Navigate to="/" />}
        />
        <Route
          path="/foodevents/rejectedevent"
          element={isAuthenticated ? <RejectedEvents /> : <Navigate to="/" />}
        />

        {/*  */}

        {/* route for Organic Stores */}
        <Route
          path="/organicstores/addorgaincstore"
          element={isAuthenticated ? <OrganicStore /> : <Navigate to="/" />}
        />
        <Route
          path="/organicstores/pendingstore"
          element={isAuthenticated ? <PendingStores /> : <Navigate to="/" />}
        />
        <Route
          path="/organicstores/approvedstore"
          element={isAuthenticated ? <ApprovedStores /> : <Navigate to="/" />}
        />
        <Route
          path="/organicstores/rejectedstore"
          element={isAuthenticated ? <RejectedStores /> : <Navigate to="/" />}
        />

        <Route
          path="/organicstores/pendingstore/details/:id"
          element={isAuthenticated ? <StoreDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/organicstores/approvedstore/details/:id"
          element={isAuthenticated ? <StoreDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/organicstores/rejectedstore/details/:id"
          element={isAuthenticated ? <StoreDetails /> : <Navigate to="/" />}
        />

        {/* Routes for Dietitians */}
        <Route
          path="/dietitian/adddietitian"
          element={isAuthenticated ? <AddDietitian /> : <Navigate to="/" />}
        />
        <Route
          path="/dietitian/pendingdietitian"
          element={isAuthenticated ? <PendingDietitian /> : <Navigate to="/" />}
        />
        <Route
          path="/dietitian/approveddietitian"
          element={isAuthenticated ? <ApprovedDietitian /> : <Navigate to="/" />}
        />
        <Route
          path="/dietitian/rejecteddietitian"
          element={isAuthenticated ? <RejectedDietitian /> : <Navigate to="/" />}
        />
        <Route path='/dietitian/approveddietitian/details/:id' element={isAuthenticated ? <DietitianDetails /> : <Navigate to='/' />} />
        <Route path='/dietitian/pendingdietitian/details/:id' element={isAuthenticated ? <DietitianDetails /> : <Navigate to='/' />} />
        <Route path='/dietitian/rejecteddietitian/details/:id' element={isAuthenticated ? <DietitianDetails /> : <Navigate to='/' />} />


        {/* Routes for User Suggested */}
        <Route
          path="/usersuggested"
          element={isAuthenticated ? <UserSuggested /> : <Navigate to="/" />}
        />
        <Route
          path="/usersuggested/details/:id"
          element={isAuthenticated ? <SuggestedDetails /> : <Navigate to="/" />}
        />
        <Route
          path="/usersuggested/storedetails/:id"
          element={isAuthenticated ? <SuggestedStoreDetails /> : <Navigate to="/" />}
        />
      </Routes>
    </BrowserRouter>
  );
};
