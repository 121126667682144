import React, { useEffect, useState } from 'react';
import "./pendingEvents.pages.css";
import Navbar from '../../../components/ui/navbar/sidenav.components';
import PendingEventsTable from '../../../components/PendingFoodEventsTable/pendingEvents-table.component';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { Button } from 'primereact/button';
import { getRefreshedToken, isTokenExpired } from "../../../api/auth.api";
import { GetEventsTableData } from '../../../api/pendingTable.api';
import { Dialog } from "primereact/dialog";
import EventDetails from '../FoodEventDetails/eventDetails';
import { GetEventbyID } from '../../../api/pendingTable.api';

interface PendingEventData {
  _id: string;
  eventtitle: string;
  eventstartdate: string;
  city: string;
  description: string[]; // Or whatever the type of description is
  referencelink: string;
  // Add other properties as per your data structure
}

const PendingEvents: React.FC<any> = () => {

  const initialPendingEvent: PendingEventData = {
    _id: '',
    eventtitle: '',
    eventstartdate: '',
    city: '',
    description: [],
    referencelink: '',
    // Initialize other properties with default values
  };

  // const navigate = useNavigate()
  const [loading, setLoading] = useState(false);
  const [totaleventsRecords, setTotaleventsRecords] = useState(0);
  const [customers, setCustomers] = useState(undefined);
  const [visible, setVisible] = useState<boolean>(false);
  const [pendingevents, setpendingevents] = useState<PendingEventData>(initialPendingEvent);
  const [isAdding, setIsadding] = useState(false);

  var width = window.screen.width;
  var c_rows = 10;
  if (width >= 1920) {
    c_rows = 15
  }
  else if (width >= 1400 || width <= 1600) {
    c_rows = 10
  }

  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: c_rows,
    page: 0,
    sortField: "",
    sortOrder: -1,
    filters: {
      eventtitle: { value: "", matchMode: "contains" },
      city: { value: "", matchMode: "contains" }
    },
  });
  let loadLazyTimeout: any = 0;



  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  const viewProduct = (product:any) => {
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      setIsadding(true);
      setVisible(true)
      const eventData = await GetEventbyID(product._id as string);

      console.log("eventsData", eventData.data.data);

      setpendingevents(eventData.data.data);

      setIsadding(false);
    }, 100);
  };
console.log("pendingdata",pendingevents);
  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(async () => {
      // CustomerService.getCustomers({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {
      // setTotalRecords(data.totalRecords);
      // setCustomers(data.customers);
      // setLoading(false);
      // console.log(data);
      // });
      if (isTokenExpired()) {
        console.log("inside");
        await getRefreshedToken();
      } else {
        const respObj = await GetEventsTableData(lazyParams, 0);
        console.log("approved events", respObj.data.data);

        setCustomers(respObj.data.data.foodevnts);
        console.log()
        setTotaleventsRecords(respObj.data.data.totaleventsRecords);
        setLoading(false);
      }
    }, );
  };
  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyParams(event);
  };

  console.log("custmers in events", customers);


  // const viewProduct = (product: any) => {
  //   setVisible(true);
  //   loadEventData(product)
    // navigate(`/approved/details/${product._id}`)
    // window.open(`/foodevents/pendingevents/details/${product._id}`);
  // }
  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <Tooltip
          target=".p-dt-tooltip"
          // content="View"
          mouseTrack
          mouseTrackLeft={10}
        />
        <Button icon="pi pi-eye" label='View Details' className="p-dt-tooltip p-button-rounded mr-2 view-bt" onClick={() => viewProduct(rowData)} />
      </React.Fragment>
    );
  }



  return (
    <>
      <main>
        <div>
          <Navbar />
          <section className="page-content">
            <div className="ym-content-table">
           
                <div>
                  <h1 className="tables-heading">Pending Food Events</h1>
                </div>
                <hr style={{color:"#ececec",height:"1px",opacity:"100%"}}/>

          
              <div className="">
                <PendingEventsTable
                  customers={customers}
                  first={lazyParams.first}
                  sortField={lazyParams.sortField}
                  sortOrder={lazyParams.sortOrder}
                  totalRecords={totaleventsRecords}
                  onPage={onPage}
                  onSort={onSort}
                  onFilter={onFilter}
                  filters={lazyParams.filters}
                  loading={loading}
                  actionBodyTemplate={actionBodyTemplate}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
      <div className='dialog-box'>
        <Dialog
          visible={visible}
          header="Event Details"
          style={{ width: "50vw" }}
          className="foodevents-model"
          breakpoints={{
            "1920px": "50vw",
            "1420px": "50vw",
            "1120px": "50vw",
            "1090px": "40vw",
            "980px": "50vw",
            "840px": "60vw",
            "740px": "50vw",
            "641px": "70vw",
            "541px": "70vw",
            "490px": "80vw",
            "350px": "90vw",
          }}
          onHide={() => setVisible(false)}
        >
          <EventDetails
          eventtitle={pendingevents.eventtitle}
          eventstartdate = {pendingevents.eventstartdate}
          eventcity = {pendingevents.city}
          eventdescription = {pendingevents.description}
          eventlink = {pendingevents.referencelink}
          status='pending'
           />
        </Dialog>
      </div>
    </>
  );
}

export default PendingEvents;

