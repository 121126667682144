import React from 'react';
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import SuggestedRestaurantTable from '../../../components/UserSuggestedRestaurants/SuggestedRestaurant-Table.component';
import SuggestedStoreTable from '../../../components/UserSuggestedOrganicStores/suggestedStore-Table.component';

const UserSuggested = () => {
    return (
        <main>
            <div>
                <Navbar />
                <section className="page-content">
                    <div className="ym-content-table mb-0">
                        
                            <div>
                                <h1 className="tables-heading">User Suggested</h1>
                            </div>
                            <hr style={{color:"#ececec",height:"1px",opacity:"100%",marginBottom:"0px"}}/>

                    
                    </div>
                    <div className='tabp'>
                        <TabView>
                            <TabPanel header="Restaurants">
                                <SuggestedRestaurantTable />
                            </TabPanel>
                            <TabPanel header="Organic Stores">
                                <SuggestedStoreTable />
                            </TabPanel>
                        </TabView>

                    </div>
                </section>
            </div>
        </main>
    );
}

export default UserSuggested;
