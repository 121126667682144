
import React, { useState } from "react";
import "./timings.component.css";

type Props = {
  timing: {
    day: string;
    timings: { opens: string; closes: string; }[];
  }[];
  editable: Boolean;
};



const Timings: React.FC<Props> = ({ timing, editable }) => {
  const [parsedTimings, setParsedTimings] = useState(timing.map((timingStr: any) => JSON.parse(timingStr))
  );
  const handleDeleteTiming = (dayIndex: number) => {
    const updatedTimings = parsedTimings.filter((_, index) => index !== dayIndex);
    setParsedTimings(updatedTimings);
  };


  return (
    <div className="main">
      <div className="timings-div d-flex flex-column gap-2">
        {parsedTimings.map((dayData, index) => (
          <>
            {Object.keys(dayData).map((day: string, dayIndex: number) => (
              <div key={index} className="timings-card d-flex justify-content-between p-2">
                <div key={dayIndex} className="col d-flex justify-content-between align-items-center ">
                  <p className="col-lg-3 day m-0">{day}</p>
                  <div className="col-lg-6 d-flex flex-column">
                    {dayData[day].map((time: string, timeIndex: number) => (
                      <p className="time mb-1" key={timeIndex}>
                        {time}
                      </p>
                    ))}
                  </div>
                  <div className="col-lg-1 d-flex justify-content-end">
                    {editable &&
                      <button className="trash_btn" onClick={() => handleDeleteTiming(dayIndex)} >
                        <i className="pi pi-trash icontrash">
                        </i>
                      </button>
                    }
                  </div>
                </div>
              </div>
            ))}

          </>

        ))}
      </div>
    </div>
  );
};

export default Timings;
