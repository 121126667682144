/* eslint-disable react/jsx-no-undef */
import React from 'react';
import { Image } from "primereact/image";
import {sas} from "../../config/constants"

type props = {
  menuimages: string[];
}

const Restaurantmenu:React.FC<props>  = ({menuimages}) => {
  return (
    <div>
      {/* <h6>Menu</h6> */}
      <div className="d-flex">
      {menuimages.map((item) => (
                    <div className="rest-img">
                        {/*@ts-ignore   */}
                        
                        <Image src={item+"?"+sas} key={item} preview alt='menu' />
                    </div>
                ))}
      </div>
    </div>
  )
}

export default Restaurantmenu
