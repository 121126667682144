import React, { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "./details.css";
import { Button } from "primereact/button";
import Restaurantdetails from "../../../components/restaurant-details/restaurant-details.components";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { Dialog } from "primereact/dialog";
import { useLocation, useNavigate } from "react-router-dom";
// import Loader from "../../../components/ui/loader/loader.components";
import { useParams } from "react-router-dom";
import { GetRestbyID } from "../../../api/pendingTable.api";
import { getComments } from "../../../api/adminComment.api";
import Loader from "../../../components/ui/loader/loader.components";
import { Carousel } from "../../../components/ui/carousel/carousel.components";
import { Skeleton } from "primereact/skeleton";
import { Image } from "primereact/image";
import { Tooltip } from "primereact/tooltip";
import Restaurantphotos from "../../../components/RestaurantPhotos/Restaurant.photos.component";
import Restaurantmenu from "../../../components/RestaurantMenu/Restaurant.menu.component";
import AditionalInfo from "../../../components/Restaurant-AditionalInfo/aditionalInfo.component";
import Timings from "../../../components/RestaurantTimings/timings.component";
import { sas } from "../../../config/constants";
import EditRestaurant from "../edit-restaurants/EditRestaurant";

const Details = () => {
  const name = "Astorio Restaurant";
  const cuisine = ["Indian", "Chinese", "Mughlai", "North Indian"];
  const specialitems = ["Chicken Lollipop", "Soups", "Biryani"];
  const preference = ["Veg", "Non Veg"];
  const foodtype = ["Healthy", "Traditional"];
  const eaterytype = ["Restaurant"];
  const ambience = ["Drive In"];
  const values = ["Quality Driven", "Service Driven"];
  const serving = ["Breakfast", "Lunch", "Dinner", "Buffet"];
  const serviceoptions = ["Drive-In", "Take Away"];
  const address = "2nd Floor, Sun Tower, Near Axis Bank, Madhapur, Hyderabad";
  const images = [
    "https://simplefood.at/wp-content/uploads/2020/11/%C2%A9SophiaEerden_SimpleFood_Portrait_final_-5-768x960.jpg",
    "https://i.pinimg.com/originals/06/a1/f8/06a1f8e1c6d33fc7616c91a152b06629.jpg",
    "https://images.unsplash.com/photo-1634141613544-001d33883517?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1yZWxhdGVkfDE0fHx8ZW58MHx8fHw%3D&w=1000&q=80",
    "https://149359356.v2.pressablecdn.com/wp-content/uploads/2018/04/smaller-file-veg.jpeg",
  ];
  const sourcelink = "https://www.youtube.com/embed/UVP7YtOnP_o";
  const description =
    "Flechazo is a casual dining multi-cuisine restaurant based on the 'MediterrAsian' Fusion concept.";
  const whyyeldam =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";

  const [visible, setVisible] = useState(false);
  const [isAdding, setIsadding] = useState(false); // Loader state..
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const [lvisible, setLvisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false)

  const { id } = useParams();
  const navigate = useNavigate();
  const [restObj, setRestObj] = useState({
    _id: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    maplink: "",
    diettype: [],
    location: {
      coordinates: [0, 0],
    },
    country: "",
    pincode: "",
    landmark: "",
    website: "",
    email: "",
    phoneno: "",
    eaterytype: [],
    foodtype: [],
    preference: [],
    cuisine: [],
    ambience: "",
    serving: [],
    mealoftheday: [],
    serviceoptions: [],
    values: [],
    features: [],
    specialitems: [],
    sourcelink: "",
    timings: [],
    source: "",
    about: [],
    whyinyeldam: [],
    images: [],
    zomatolink: "",
    swiggylink: "",
    dineoutlink: "",
    averagecost: "",
    sizeofestablishment: "",
    menuimages: [],
    thumbnails: [],
    comment: "",
    hygiene:0,
    health:0,
    taste:0,
    costeffective:0,
    valuesrating:0,
    hospitality:0
  });

  const location = useLocation();

  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const restStatus = async () => {
    // const respData =await RestStatus(restObj._id,!restObj.status);
    // console.log(respData);
    // toastShow(respData.data.message)
    setVisible(false);
    // refreshPage();
  };

  const [comment, setComment] = useState<string[]>([]);

  const loadLazyData = async () => {
    try {
      setIsadding(true);

      const resData = await GetRestbyID(id as string);

      console.log("resData", resData.data.data);
      console.log("resDataimage", resData.data.data?.thumbnails[0]);

      if (resData.data.data) {
        setRestObj(resData.data.data);
      }

      setIsadding(false);
    } catch (error) {
      // Handle any potential errors from API calls
      console.error("Error loading data:", error);
      setIsadding(false);
    }
  };

  function titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }
  const ss_timelabel = "<h6><b>No Timings</b></h6>";
  const s_timelabel = "<h5><b>Opening Hours</b></h5>";
  const timings: any[] = restObj.timings;
  console.log(timings + "timings");

  useEffect(() => {
    setIsadding(true);
    loadLazyData();
    console.log("et", eaterytype);
  }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <main>
      {!isAdding ? "" : <Loader />}
      <div className="">
        <Navbar />
        {/* {lvisible ? <Loader/>:null} */}
        <section className="page-content">
          <div className="d-flex mb-4 mr-5 justify-content-between ">
            <div className="p-2 d-flex">
              &nbsp;
              <span
                className="back_rest_btn d-flex gap-2 align-items-center"
                onClick={() => {
                  const currentPath = window.location.pathname;
                  if (currentPath.includes("/pending")) {
                    navigate(`/restaurants/pending`);
                  } else if (currentPath.includes("/approved")) {
                    navigate(`/restaurants/approved`);
                  } else if (currentPath.includes("/rejected")) {
                    navigate(`/restaurants/rejected`);
                  }
                }}
              >
                <i className="pi pi-arrow-left"></i>
                Restaurant Details
              </span>
            </div>
            <div className="">
              <Button
                label="Edit Restaurant"
                icon="pi pi-pencil"
                className="p-button-rounded p-button-edit "
                onClick={() => {
                  setEditVisible(true);

                }}
                autoFocus
              />
            </div>
          </div>
          <hr />

          <div className="ym-content-table mb-0">
            <div className=" gap-3 d-flex align-items-start">
              <div
                className="row mb-1 view-gallery-cols"
                style={{ height: "180px" }}
              >
                <div className="col pt-0 pl-0">
                  {/* @ts-ignore       */}
                  <Image
                    src={restObj.thumbnails[0] + "?" + sas}
                    alt="Image"
                    preview
                    className="details-img"
                  />
                </div>
              </div>

              <div className="row mb-1 col-8">
                <div className="col d-flex align-items-left mb-4 justify-content-center flex-column ml-1 gap-3 rest-details">
                  <div className="page-title">
                    {!lvisible ? (
                      <h1 className="ym-portal-heading">{restObj.name}</h1>
                    ) : (
                      <Skeleton width="10rem" className="mb-2"></Skeleton>
                    )}
                  </div>
                  <div className="address-latlong gap-6 d-flex">
                    <div className="address-div col-lg-6">
                      {!lvisible ? (
                        <div>
                          <p className="address-heading gap-2 d-flex align-items-center">
                            <i
                              className="pi pi-map-marker"
                              style={{ fontSize: "12px" }}
                            ></i>
                            Address
                          </p>
                          <p className="area-data mb-0 ml-3">
                            {restObj.address1 +
                              "," +
                              restObj.city +
                              "," +
                              " " +
                              restObj.state +
                              "-" +
                              restObj.pincode +
                              "."}
                          </p>
                        </div>
                      ) : (
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                      )}
                    </div>
                    <div className="lat-long-div">
                      {!lvisible ? (
                        <div>
                          <p className="address-heading gap-2 d-flex align-items-center">
                            <i
                              className="pi pi-map"
                              style={{ fontSize: "12px" }}
                            ></i>
                            Latitude & Longitude
                          </p>
                          <p className="area-data mb-0 ml-4">
                            {restObj.location.coordinates[1] +
                              " & " +
                              restObj.location.coordinates[0]}
                          </p>
                        </div>
                      ) : (
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                      )}
                    </div>
                  </div>
                  <div className="phone-email-web d-flex gap-6">
                    <div className="phone-email d-flex col-lg-6">
                      <div className="phone-num col-lg-6">
                        {!lvisible ? (
                          <div>
                            <p className="address-heading gap-2 d-flex align-items-center">
                              <i
                                className="pi pi-phone"
                                style={{ fontSize: "12px" }}
                              ></i>
                              Phone
                            </p>
                            <p className="area-data mb-0 ml-3">
                              {restObj.phoneno
                                ? " " + restObj.phoneno
                                : "--"}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                      <div className="email-st">
                        {!lvisible ? (
                          <div>
                            <p className="address-heading gap-2 d-flex align-items-center">
                              <i
                                className="pi pi-envelope"
                                style={{ fontSize: "12px" }}
                              ></i>
                              Email
                            </p>
                            <p className="area-data mb-0 ml-3">
                              {restObj.email ? restObj.email : "--"}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                    </div>
                    <div className="website">
                      {!lvisible ? (
                        <div>
                          <p className="address-heading gap-2 d-flex align-items-center">
                            <i
                              className="pi pi-globe"
                              style={{ fontSize: "12px" }}
                            ></i>
                            Website
                          </p>
                          <p className="area-data mb-0 ml-4">
                            {restObj.website ? restObj.website : "--"}
                          </p>
                        </div>
                      ) : (
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <TabView
              activeIndex={activeIndex}
              onTabChange={(e) => setActiveIndex(e.index)}
            >
              {/* @ts-ignore */}
              <TabPanel header="Dining Details">
                <Restaurantdetails
                  cuisine={restObj.cuisine}
                  specialitems={restObj.specialitems}
                  preference={restObj.preference}
                  foodtype={restObj.foodtype}
                  eaterytype={restObj.eaterytype}
                  ambience={restObj.ambience}
                  values={restObj.values}
                  serving={restObj.serving}
                  serviceoptions={restObj.serviceoptions}
                  phoneno={restObj.phoneno}
                  address={restObj.address1 + "," + restObj.address2}
                  sourcelink={restObj.sourcelink}
                  images={restObj.images}
                  comment={restObj.comment}
                  zomatolink={restObj.zomatolink}
                  swiggylink={restObj.swiggylink}
                  dineoutlink={restObj.dineoutlink}
                  maplink={restObj.maplink}
                  email={restObj.email}
                  website={restObj.website}
                  city={restObj.city}
                  state={restObj.state}
                  pincode={restObj.pincode}
                  mealoftheday={restObj.mealoftheday}
                  averagecost={restObj.averagecost}
                  diettype={restObj.diettype}
                  sizeofestablishment={restObj.sizeofestablishment}
                  menuimages={restObj.menuimages}
                />
              </TabPanel>
              {/* @ts-ignore       */}
              <TabPanel header="Timings">
                <Timings timing={timings} editable={false}/>
              </TabPanel>
              <TabPanel header="Restaurant Images">
                <Restaurantphotos images={restObj.images} />
              </TabPanel>
              {/* @ts-ignore       */}
              <TabPanel header="Menu">
                <Restaurantmenu menuimages={restObj.menuimages} />
              </TabPanel>
              {/* @ts-ignore       */}
              <TabPanel header="Additional Info">
                <AditionalInfo
                  description={restObj.about}
                  whyyeldam={restObj.whyinyeldam}
                  features={restObj.features}
                  hygiene={restObj.hygiene}
                  health={restObj.health}
                  taste={restObj.taste}
                  costeffective={restObj.costeffective}
                  valuesrating={restObj.valuesrating}
                  hospitality={restObj.hospitality}

                />
              </TabPanel>
            </TabView>
            <Dialog
              header="Edit Restaurant Details"
              visible={editVisible}
              style={{ width: "45vw", height: "100vh" }}
              onHide={() => {
                setEditVisible(false);
              }}

            >
              <EditRestaurant
                restdataObj={restObj}
                setEditVisible={setEditVisible}
              />
            </Dialog>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Details;
