import { Skeleton } from "primereact/skeleton";
import React, { useState } from "react";

type props = {
  storetype: string[];
  servicetype: string[];
  serviceoptions: string;
  storesize: string[];
};

const ServiceOptions: React.FC<props> = ({
  storetype,
  servicetype,
  serviceoptions,
  storesize,
}) => {
  const [lvisible, setLvisible] = useState(false);
  return (
    <div className="Main">
      <div className="row">
        <div className="column1 col-lg-4">
          <div className="gap-2 d-flex align-items-start">
            <div className="preference mb-3">
              {lvisible ? (
                <>
                  <Skeleton width="10rem" className="mb-2"></Skeleton>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                </>
              ) : (
                <>
                  <h6 className="mb-1">Store Type</h6>
                  <p className="pb-0 mb-0">
              
                {storetype.map((tag, i) => (
                    <span key={i} className="span-data">
                      {i > 0 && ', '} {tag.trim()}
                    </span>
                  ))}
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="gap-2 d-flex align-items-start">
            <div className="preference mb-3">
              {lvisible ? (
                <>
                  <Skeleton width="10rem" className="mb-2"></Skeleton>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                </>
              ) : (
                <>
                  <h6 className="mb-1">Service Type</h6>
                  <div className="d-flex">
                    {servicetype.map((tag, i) => (
                      <div className="">
                        &nbsp;
                        <span className="span-data" key={"servicetype" + i}>
                          {i > 0 && ", "}
                          {tag.trim()}
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="column2 col-lg-4">
          <div className="gap-2 d-flex align-items-start">
            <div className="preference mb-3">
              {lvisible ? (
                <>
                  <Skeleton width="10rem" className="mb-2"></Skeleton>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                </>
              ) : (
                <>
                  <h6 className="mb-1">Service Options</h6>
                  <div className="d-flex">
                  {serviceoptions ? serviceoptions : '--'}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="gap-2 d-flex align-items-start">
            <div className="preference mb-3">
              {lvisible ? (
                <>
                  <Skeleton width="10rem" className="mb-2"></Skeleton>
                  <Skeleton width="100%" className="mb-2"></Skeleton>
                </>
              ) : (
                <>
                  <h6 className="mb-1">Store Size</h6>
                  <div className="d-flex">
                    {storesize.map((tag, i) => (
                      <div className="">
                        &nbsp;
                        <span className="span-data" key={"servicetype" + i}>
                          {i > 0 && ", "}
                          {tag.trim()}
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceOptions;
