import HttpInstance from "./http.api";

export const GetTableData = (params:any,approvalstatus:number)=>{
   const RespData= HttpInstance.post("/restaurant/agenttablefilter",{...params,approvalstatus:approvalstatus})
   return RespData;
}

export const GetRestbyID = (id:string)=>{
   const RespData = HttpInstance.get("/restaurant/agentresbyid?id="+id)
   return RespData
}

export const GetStoreTableData = (params:any,approvalstatus:number) =>{
   const RespData = HttpInstance.post("/organicstore/agentstoretable",{...params,approvalstatus:approvalstatus})
   return RespData
}
export const GetStorebyID = (id:string) =>{
   const RespData = HttpInstance.get("/organicstore/getstorebyid?id="+id)
   return RespData
}
export const GetEventsTableData = (params:any, approvalstatus:number) =>{
   const RespData = HttpInstance.post("/foodevents/agenteventtable",{...params,approvalstatus:approvalstatus})
   return RespData
}
export const GetEventbyID = (id:string) =>{
   const RespData = HttpInstance.get("/foodevents/getfoodeventbyid?id="+id)
   return RespData
}