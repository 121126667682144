import React, { useState, useEffect } from 'react';
import Loading from "../../../assets/images/LoaderY.gif"

const Loader: React.FC = ({}) => {
    return (

        <div className="ym-loading-overlay ym-component-overlay">
        {/* <i className="pi pi-spin pi-spinner" style={{ fontSize: '3rem',color: '#36a41d'}}></i> */}
        <img src={Loading} style={{ width: '100px', height:'100px',marginLeft: "301px" }} alt="loading"/>
        </div>
        );
    };
    
    export default Loader;