import HttpInstance from "./http.api";

export const GetSuggestedRestaurantsTableData = (params:any, approvalstatus:number) =>{
    const RespData = HttpInstance.post("/userrestaurant/usersuggestedresttable",{...params,approvalstatus:approvalstatus})
    return RespData
 }
 export const GetSuggestedStoresTableData = (params:any, approvalstatus:number) =>{
    const RespData = HttpInstance.post("/userstore/usersuggestedstoretable",{...params,approvalstatus:approvalstatus})
    return RespData
 }

 export const GetUserSuggestedRestbyID = (id:string) =>{
   const RespData = HttpInstance.get("/userrestaurant/getuserestbyid?id="+id)
   return RespData
}
export const GetUserSuggestedStorebyID = (id:string) =>{
   const RespData = HttpInstance.get("/userstore/getuserstorebyid?id="+id)
   return RespData
}