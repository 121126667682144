import React, { useState } from "react";
import "./RestaurantDetailsScreen.css";
import { Card } from "primereact/card";
import Pillsdetails from "../detail-pills/detail-pills.components";
import { Tooltip } from "primereact/tooltip";
import Zomatoicon from "../../assets/icons/zomatoicon.svg";
import Youtubeicon from "../../assets/icons/youtubeicon.png";
import Googleicon from "../../assets/icons/googleicon.svg";
import Swiggyicon from "../../assets/icons/swiggyicon.svg";
import { Dialog } from "primereact/dialog";
import YouTube, { YouTubeProps } from "react-youtube";
import Dineout from "../../assets/images/Dineout.svg";
import { Skeleton } from "primereact/skeleton";

type props = {
  cuisine: any[];
  preference: string[];
  foodtype: any[];
  eaterytype: any[];
  ambience: string;
  values: any[];
  serving: string[];
  serviceoptions: string[];

  phoneno: string;
  address: string;
  sourcelink: string;
  images: string[];
  comment: string;
  zomatolink: string;
  swiggylink: string;
  dineoutlink: string;
  maplink: string;
  email: string;
  website: string;
  city: string;
  pincode: string;
  state: string;
  mealoftheday: string[];
  specialitems: string[];
  averagecost: string;
  diettype: any[];
  sizeofestablishment: string;
  menuimages: string[];
};
const Restaurantdetails: React.FC<props> = ({
  cuisine,
  specialitems,
  preference,
  foodtype,
  eaterytype,
  ambience,
  values,
  serving,
  serviceoptions,

  phoneno,
  address,
  sourcelink,

  comment,
  zomatolink,
  swiggylink,
  dineoutlink,
  maplink,
  email,
  website,
  city,
  pincode,
  state,
  mealoftheday,
  averagecost,

  diettype,
  sizeofestablishment,
  menuimages,
}) => {
  const [lvisible, setLvisible] = useState(false);

  console.log("specialitems", specialitems);

  return (
    <>
      <div className="Main">
        <div className="row">
          <div className="column1 col-lg-4">
            {/* preferences */}
            <div className="gap-2 d-flex align-items-start">
              <div className="preference mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Preference</h6>
                    <div className="d-flex">
                      {preference.map((tag, i) => (
                        <div className="">
                          &nbsp;
                          <span className="span-data" key={"Preference" + i}>
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* serving */}
            <div className="gap-2 d-flex align-items-start">
              <div className="serving mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Servings</h6>
                    <div className="d-flex">
                      {serving.map((tag, i) => (
                        <div className="">
                          &nbsp;
                          <span className="span-data" key={"serving" + i}>
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* meal of the day */}
            <div className="gap-2 d-flex align-items-start">
              <div className="meal mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Meal Of The Day</h6>
                    <div className="d-flex">
                      {mealoftheday.map((tag, i) => (
                        <div className="">
                          <span className="span-data" key={"mealoftheday"}>
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* special items */}
            <div className="gap-2 d-flex align-items-start">
              <div className="meal mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Special Items</h6>
                    <p>
                      {specialitems.map((tag, i) => (
                        <span key={i} className="span-data">
                          {i > 0 && ", "} {tag.trim()}
                        </span>
                      ))}
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column2 col-lg-4">
            {/* average cost */}
            <div className="gap-2 d-flex align-items-start">
              <div className="about">
                {averagecost === "NaN" ? (
                  ""
                ) : (
                  <>
                    {lvisible ? (
                      <>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="100%" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-2">Average Cost (per 2 persons)</h6>
                        <p className="span-data">₹{averagecost}</p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* Ambience */}
            <div className="gap-2 d-flex align-items-start">
              <div className="ambience mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <div className="gap-2 d-flex align-items-start">
                      <div className="ambience">
                        <h6 className="mb-1">Ambience</h6>

                        <span className="span-data">
                          {ambience ? ambience : "--"}
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* eatery type */}
            <div className="gap-2 d-flex align-items-start">
              <div className="eaterytype mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Eatery Type</h6>
                    <div className="d-flex">
                      {eaterytype.map((tag, i) => (
                        <div className="">
                          <span className="span-data" key={"eaterytype" + i}>
                            {i > 0 && ", "}
                            {tag.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* food type */}
            <div className="gap-2 d-flex align-items-start">
              <div className="foodtype mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Food Type</h6>
                    <div className="d-flex">
                      {foodtype.map((a, i) => (
                        <div className="">
                          <span className="span-data" key={"foodtype" + i}>
                            {i > 0 && ", "}
                            {a.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column3 col-lg-4">
            {/* diet type */}
            <div className="gap-2 d-flex align-items-start">
              <div className="eaterytype mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Diet Type</h6>
                    <div className="d-flex">
                      {diettype.map((tag, i) => (
                        <div className="">
                          <span className="span-data" key={"diettype" + i}>
                            {i > 0 && ", "}
                            {tag.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Values */}
            <div className="gap-2 d-flex align-items-start">
              {values[0] === "None" ? (
                ""
              ) : (
                <>
                  <div className="values">
                    {lvisible ? (
                      <>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="100%" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-1">Values</h6>

                        <p>
                          {values.map((tag, i) => (
                            <span key={i} className="span-data">
                              {i > 0 && ", "} {tag.name.trim()}
                            </span>
                          ))}
                        </p>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            {/* Cuisines */}
            <div className="gap-2 d-flex align-items-start">
              <div className="eaterytype mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Cuisines</h6>
                    <div className="d-flex">
                      {cuisine.map((tag, i) => (
                        <div className="d-flex">
                          <span className="span-data" key={"cuisine" + i}>
                            {i > 0 && ", "}
                            {tag.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
             {/* Service Options */}
            <div className="gap-2 d-flex align-items-start">
              <div className="preference mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Service Options</h6>
                    <div className="d-flex">
                      {serviceoptions.map((tag, i) => (
                        <div className="">
                          &nbsp;
                          <span className="span-data" key={"serviceoptions" + i}>
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Restaurantdetails;
