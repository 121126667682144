import React from 'react';
import Navbar from '../../../components/ui/navbar/sidenav.components';
import timings from "../../../assets/icons/timings.svg";
import uploadimages from "../../../assets/icons/uploadimages.svg";
import doctor from "../../../assets/icons/doc.svg";
import education from "../../../assets/icons/grad.svg";
import clinic from "../../../assets/icons/clinic.svg"
import DietitianCustomStepper from '../../../components/DietitianCustomStepper/dietitianCustomStepper.component';

const AddDietitian = () => {
    const steps = ["Step 1", "Step 2", "Step 3", "Step 4", "Step 5"];
    const stepdesc = [
        "Dietitian Details",
        "Education Details",
        "Clinic Details",
        "Timings",
        "Upload Images",
    ];
    const images = [doctor, education, clinic, timings, uploadimages];
    return (
        <>
            <main>
                <div>
                    <Navbar />
                    <section
                        className="page-content"
                        style={{ height: "100vh", background: "#F5F5F5" }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <DietitianCustomStepper
                                steps={steps}
                                images={images}
                                stepdesc={stepdesc}
                            />
                        </div>
                    </section>
                </div>
            </main>
        </>

    );
}

export default AddDietitian;
