import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { Divider } from 'primereact/divider';
import React from "react";
import "../timing/timings.css"

type props={
  func:Function
}

const AddRemoveInputField:React.FC<props>=({func})=>{
  const [sunday, setSunday] = useState([{opens: "",closes: "",}]);
  const [monday, setMonday] = useState([{opens: "",closes: "",}]);
  const [tuesday, setTuesday] = useState([{opens: "",closes: "",}]);
  const [wednesday, setWednesday] = useState([{opens: "",closes: "",}]);
  const [thursday, setThursday] = useState([{opens: "",closes: "",}]);
  const [friday, setFriday] = useState([{opens: "",closes: "",}]);
  const [saturday, setSaturday] = useState([{opens: "",closes: "",}]);

const [blocksunday, setBlockSunday] = useState([{day: "",timings:[],},]);
const [blockmonday, setBlockMonday] = useState([{day: "",timings:[],},]);
const [blocktuesday, setBlockTuesday] = useState([{day: "",timings:[],},]);
const [blockwednesday, setBlockWednesday] = useState([{day: "",timings:[],},]);
const [blockthursday, setBlockThursday] = useState([{day: "",timings:[],},]);
const [blockfriday, setBlockFriday] = useState([{day: "",timings:[],},]);
const [blocksaturday, setBlockSaturday] = useState([{day: "",timings:[],},]);


  const [day, setDay] = useState();
  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "12:00am"
  ];
  const Days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const addSundayField = () => {
    setSunday([...sunday,{opens: "",closes: "",},]);
  };
  const addMondayField = () => {
    setMonday([...monday,{opens: "",closes: "",},]);
  };
  const addTuesdayField = () => {
    setTuesday([...tuesday,{opens: "",closes: "",},]);
  };
  const addWednesdayField = () => {
    setWednesday([...wednesday,{opens: "",closes: "",},]);
  };
  const addThursdayField = () => {
    setThursday([...thursday,{opens: "",closes: "",},]);
  };
  const addFridayField = () => {
    setFriday([...friday,{opens: "",closes: "",},]);
  };
  const addSaturdayField = () => {
    setSaturday([...saturday,{opens: "",closes: "",},]);
  };
  const removeSundayFields = (index: any) => {
    const rows = [...sunday]; rows.splice(index, 1); setSunday(rows);
  };
  const removeMondayFields = (index: any) => {
    const rows = [...monday]; rows.splice(index, 1); setMonday(rows);
  };
  const removeaddTuesdayField = (index: any) => {
    const rows = [...tuesday]; rows.splice(index, 1); setTuesday(rows);
  };
  const removeaddWednesdayField = (index: any) => {
    const rows = [...wednesday]; rows.splice(index, 1); setWednesday(rows);
  };
  const removeaddThursdayField = (index: any) => {
    const rows = [...thursday]; rows.splice(index, 1); setThursday(rows);
  };
  const removeaddFridayField = (index: any) => {
    const rows = [...friday]; rows.splice(index, 1); setFriday(rows);
  };
  const removeaddSaturdayField = (index: any) => {
    const rows = [...saturday]; rows.splice(index, 1); setSaturday(rows);
  };

  const handleChange = (day:any,index: any, evnt: any) => {
    const { name, value } = evnt.target;
    if(day==="sunday"){
        console.log(day)
        const list: any = [...sunday];
        console.log(list);
        list[index][name] = value;
        console.log(list);
        setSunday(list);
    }
    else if(day==="monday"){
        const list: any = [...monday];
        console.log(list);
        list[index][name] = value;
        console.log(list);
        setMonday(list);
    }
    else if(day==="tuesday"){
        const list: any = [...tuesday];
        console.log(list);
        list[index][name] = value;
        console.log(list);
        setTuesday(list);
    }
    else if(day==="wednesday"){
        const list: any = [...wednesday];
        console.log(list);
        list[index][name] = value;
        console.log(list);
        setWednesday(list);
    }
    else if(day==="thursday"){
        const list: any = [...thursday];
        console.log(list);
        list[index][name] = value;
        console.log(list);
        setThursday(list);
    }
    else if(day==="friday"){
        const list: any = [...friday];
        console.log(list);
        list[index][name] = value;
        console.log(list);
        setFriday(list);
    }
    else if(day==="saturday"){
        const list: any = [...saturday];
        console.log(list);
        list[index][name] = value;
        console.log(list);
        setSaturday(list);
    }

  };

const onsubmit = (e: any) => {

    const sundaylist: any = [...blocksunday]; sundaylist[0]["day"] = "sunday"; sundaylist[0]["timings"] = sunday;setBlockSunday(sundaylist)
    const mondaylist: any = [...blockmonday]; mondaylist[0]["day"] = "monday"; mondaylist[0]["timings"] = monday;setBlockMonday(mondaylist)
    const tuesdaylist: any = [...blocktuesday]; tuesdaylist[0]["day"] = "tuesday"; tuesdaylist[0]["timings"] = tuesday;setBlockTuesday(tuesdaylist)
    const wednesdaylist: any = [...blockwednesday]; wednesdaylist[0]["day"] = "wednesday"; wednesdaylist[0]["timings"] = wednesday;setBlockWednesday(wednesdaylist)
    const thursdaylist: any = [...blockthursday]; thursdaylist[0]["day"] = "thursday"; thursdaylist[0]["timings"] = thursday;setBlockThursday(thursdaylist)
    const fridaylist: any = [...blockfriday]; fridaylist[0]["day"] = "friday"; fridaylist[0]["timings"] = friday;setBlockFriday(fridaylist)
    const saturdaylist: any = [...blocksaturday]; saturdaylist[0]["day"] = "saturday"; saturdaylist[0]["timings"] = saturday;setBlockSaturday(saturdaylist)

    const totallist: any = []
    totallist[0]= sundaylist[0]
    totallist[1]= mondaylist[0]
    totallist[2]= tuesdaylist[0]
    totallist[3]= wednesdaylist[0]
    totallist[4]= thursdaylist[0]
    totallist[5]= fridaylist[0]
    totallist[6]= saturdaylist[0]

    console.log(totallist)
    func(totallist)
};
  
  return (
    <>
    <div className="container">

     <div className="row ym-timing-row">
    <div className="col-3 lg:col-3 xl:col-3 m-0 p-0">
          <div className="d-flex align-items-center">
        <Checkbox inputId="sunday" name="sunday" value="Sunday"  checked />
        <label htmlFor="sunday" className="ml-1">Sunday</label>
    </div>
    </div>
    <div className="col-7 lg:col-7 xl:col-7">
    {sunday.map((data, index) => {
            const { opens, closes } = data;

            return (
              <div className="row d-flex" key={index}>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Opens"
                      options={Timings}
                      value={opens}
                      name="opens"
                      onChange={(evnt) => {handleChange("sunday",index, evnt);onsubmit(evnt)}}
                    />
                </div>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Closes"
                      options={Timings}
                      value={closes}
                      name="closes"
                      onChange={(evnt) => {handleChange("sunday",index, evnt);onsubmit(evnt)}}
                    />
                </div>
                <div className="col-2 lg:col-2 xl:col-2">
                  {sunday.length !== 1 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={removeSundayFields}
                    >
                      x
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
    })}

    </div>
    <div className="col-2 lg:col-2 xl:col-2">
    <button
                className="btn btn-outline-success "
                onClick={addSundayField}
              >
                +
    </button>
    </div>
    </div>
 
    <Divider />
    <div className="row ym-timing-row">
    <div className="col-3 lg:col-3 xl:col-3 m-0 p-0">
    <div className="d-flex align-items-center">
        <Checkbox inputId="monday" name="monday" value="Monday"  checked />
        <label htmlFor="monday" className="ml-1">Monday</label>
    </div>
    </div>
    <div className="col-7 lg:col-7 xl:col-7">
    {monday.map((data, index) => {
            const { opens, closes } = data;

            return (
              <div className="row d-flex" key={index}>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Opens"
                      options={Timings}
                      value={opens}
                      name="opens"
                      onChange={(evnt) => {handleChange("monday",index, evnt);onsubmit(evnt)}}
                    />
                    </div>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Closes"
                      options={Timings}
                      value={closes}
                      name="closes"
                      onChange={(evnt) => {handleChange("monday",index, evnt);onsubmit(evnt)}}
                    />
                </div>
                <div className="col-2 lg:col-2 xl:col-2">
                  {monday.length !== 1 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={removeMondayFields}
                    >
                      x
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
    })}

    </div>
    <div className="col-2 lg:col-2 xl:col-2">
    <button
                className="btn btn-outline-success "
                onClick={addMondayField}
              >
                +
    </button>
    </div>
    </div>
    <Divider />

    <div className="row ym-timing-row">
    <div className="col-3 lg:col-3 xl:col-3 m-0 p-0">
    <div className="flex align-items-center">
        <Checkbox inputId="tuesday" name="tuesday" value="Tuesday"  checked />
        <label htmlFor="tuesday" className="ml-2">Tuesday</label>
    </div>
    </div>
    <div className="col-7 lg:col-7 xl:col-7">
    {tuesday.map((data, index) => {
            const { opens, closes } = data;

            return (
              <div className="row d-flex" key={index}>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Opens"
                      options={Timings}
                      value={opens}
                      name="opens"
                      onChange={(evnt) => {handleChange("tuesday",index, evnt);onsubmit(evnt)}}
                    />
                    </div>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Closes"
                      options={Timings}
                      value={closes}
                      name="closes"
                      onChange={(evnt) => {handleChange("tuesday",index, evnt);onsubmit(evnt)}}
                    />
                </div>
                <div className="col-2 lg:col-2 xl:col-2">
                  {tuesday.length !== 1 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={removeaddTuesdayField}
                    >
                      x
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
    })}

    </div>
    <div className="col-2 lg:col-2 xl:col-2">
    <button
                className="btn btn-outline-success "
                onClick={addTuesdayField}
              >
                +
    </button>
    </div>
    </div>

    <Divider />
    <div className="row ym-timing-row">
    <div className="col-3 lg:col-3 xl:col-3 m-0 p-0">
    <div className="flex align-items-center">
        <Checkbox inputId="wednesday" name="wednesday" value="Wednesday"  checked />
        <label htmlFor="wednesday" className="ml-2">Wednesday</label>
    </div>
    </div>
    <div className="col-7 lg:col-7 xl:col-7">
    {wednesday.map((data, index) => {
            const { opens, closes } = data;

            return (
              <div className="row d-flex" key={index}>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Opens"
                      options={Timings}
                      value={opens}
                      name="opens"
                      onChange={(evnt) => {handleChange("wednesday",index, evnt);onsubmit(evnt)}}
                    />
                    </div>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Closes"
                      options={Timings}
                      value={closes}
                      name="closes"
                      onChange={(evnt) =>{handleChange("wednesday",index, evnt);onsubmit(evnt)}}
                    />
                </div>
                <div className="col-2 lg:col-2 xl:col-2">
                  {wednesday.length !== 1 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={removeaddWednesdayField}
                    >
                      x
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
    })}

    </div>
    <div className="col-2 lg:col-2 xl:col-2">
    <button
                className="btn btn-outline-success "
                onClick={addWednesdayField}
              >
                +
    </button>
    </div>
    </div>

    <Divider />
    <div className="row ym-timing-row">
    <div className="col-3 lg:col-3 xl:col-3 m-0 p-0">
    <div className="flex align-items-center">
        <Checkbox inputId="thursday" name="thursday" value="Thursday"  checked />
        <label htmlFor="thursday" className="ml-2">Thursday</label>
    </div>
    </div>
    <div className="col-7 lg:col-7 xl:col-7">
    {thursday.map((data, index) => {
            const { opens, closes } = data;

            return (
              <div className="row d-flex" key={index}>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Opens"
                      options={Timings}
                      value={opens}
                      name="opens"
                      onChange={(evnt) => {handleChange("thursday",index, evnt);onsubmit(evnt)}}
                    />
                    </div>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Closes"
                      options={Timings}
                      value={closes}
                      name="closes"
                      onChange={(evnt) => {handleChange("thursday",index, evnt);onsubmit(evnt)}}
                    />
                </div>
                <div className="col-2 lg:col-2 xl:col-2">
                  {thursday.length !== 1 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={removeaddThursdayField}
                    >
                      x
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
    })}

    </div>
    <div className="col-2 lg:col-2 xl:col-2">
    <button
                className="btn btn-outline-success "
                onClick={addThursdayField}
              >
                +
    </button>
    </div>
    </div>

    <Divider />
    <div className="row ym-timing-row">
    <div className="col-3 lg:col-3 xl:col-3 m-0 p-0">
    <div className="flex align-items-center">
        <Checkbox inputId="friday" name="friday" value="Friday"  checked />
        <label htmlFor="friday" className="ml-2">Friday</label>
    </div>
    </div>
    <div className="col-7 lg:col-7 xl:col-7">
    {friday.map((data, index) => {
            const { opens, closes } = data;

            return (
              <div className="row d-flex" key={index}>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Opens"
                      options={Timings}
                      value={opens}
                      name="opens"
                      onChange={(evnt) => {handleChange("friday",index, evnt);onsubmit(evnt)}}
                    />
                    </div>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Closes"
                      options={Timings}
                      value={closes}
                      name="closes"
                      onChange={(evnt) => {handleChange("friday",index, evnt);onsubmit(evnt)}}
                    />
                </div>
                <div className="col-2 lg:col-2 xl:col-2">
                  {friday.length !== 1 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={removeaddFridayField}
                    >
                      x
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
    })}

    </div>
    <div className="col-2 lg:col-2 xl:col-2">
    <button
                className="btn btn-outline-success "
                onClick={addFridayField}
              >
                +
    </button>
    </div>
    </div>
    <Divider />
    <div className="row ym-timing-row">
    <div className="col-3 lg:col-3 xl:col-3 m-0 p-0">
    <div className="flex align-items-center">
        <Checkbox inputId="saturday" name="saturday" value="Saturday"  checked />
        <label htmlFor="saturday" className="ml-2">Saturday</label>
    </div>
    </div>
    <div className="col-7 lg:col-7 xl:col-7">
    {saturday.map((data, index) => {
            const { opens, closes } = data;

            return (
              <div className="row d-flex" key={index}>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Opens"
                      options={Timings}
                      value={opens}
                      name="opens"
                      onChange={(evnt) => {handleChange("saturday",index, evnt);;onsubmit(evnt)}}
                    />
                    </div>
                <div className="col-5 lg:col-5 xl:col-5">
                    <Dropdown
                      placeholder="Closes"
                      options={Timings}
                      value={closes}
                      name="closes"
                      onChange={(evnt) => {handleChange("saturday",index, evnt);onsubmit(evnt)}}
                    />
                </div>
                <div className="col-2 lg:col-2 xl:col-2">
                  {saturday.length !== 1 ? (
                    <button
                      className="btn btn-outline-danger"
                      onClick={removeaddSaturdayField}
                    >
                      x
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
    })}

    </div>
    <div className="col-2 lg:col-2 xl:col-2">
    <button
                className="btn btn-outline-success "
                onClick={addSaturdayField}
              >
                +
    </button>
    </div>
    </div>

    </div>
    </>
  );
}
export default AddRemoveInputField;
