import React from 'react';
import { Chart } from 'primereact/chart';
type props = {
    data: {};
    options:{};
  };
const Linechart: React.FC<props> = ({data,options}) => {
    return (

        // <div className="card">

        // <div style={{ width: '500px', height: '300px' }}>
        <Chart type="line" data={data} options={options} 
        // className="md:w-40rem md:h-20rem" width='50' height='33'
         />
    // </div>
        );
    };
    
export default Linechart;