import React, { useEffect, useState } from "react";
import "./profile.css";
import Navbar from "../../components/ui/navbar/sidenav.components";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Loader from "../../components/ui/loader/loader.components";
import Profiledetails from "../../components/profile/profile.components";
const Profile = () => {
  
  const [visible, setVisible] = useState(false);
  const [isAdding, setIsadding] = useState(false); // Loader state..

  const { id } = useParams();
  const navigate = useNavigate();
  const restStatus = async () => {

    setVisible(false);
    // refreshPage();
  };

  const [comment, setComment] = useState<any[]>([]);
  const loadLazyData = () => {
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      setIsadding(true);
   
      setIsadding(false);
    }, 1000);
  };
  useEffect(() => {
    setIsadding(true);
    loadLazyData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");


  return (
    <main>
      {!isAdding ? "" : <Loader />}
      <div>
        <Navbar />
        <section className="page-content">
          {/* <div className="d-flex">
            <div className="p-2">
              
            </div>
            <div className="ml-auto ">
             
            </div>
          </div> */}
           <div className="ym-content-table">
              <div className="container-fluid">
              <Profiledetails/>
              </div>
              </div>
          
      
    
        </section>
      </div>
    </main>
  );
};

export default Profile;
