import React, { useEffect, useState } from 'react';
import "./dietitianDetails.css";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { GetDietitiandetailsbyID } from '../../../api/dietitians.api';
import { Skeleton } from 'primereact/skeleton';
import { Image } from "primereact/image";
import {sas} from "../../../config/constants"
import DietitianInfo from '../../../components/DietitianInfo/dietitianInfo';
import EducationalInfo from '../../../components/EducationalInfo/educationalInfo';
import ClinicInfo from '../../../components/ClinicInfo/clinicInfo';
import DietitianTimings from '../../../components/DietitianTimings/dietitianTimings';
import ClinicAndDietitianImages from '../../../components//ClinicAndDietitianImages/clinicAndDietitianImages';

const DietitianDetails = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    console.log(splitLocation);
    const { id } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState<number>(0);
    const [visible, setVisible] = useState(false);
    const [lvisible, setLvisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [isAdding, setIsadding] = useState(false);
    const [commentObj, setCommentObj] = useState({
        comment: "",
        approvalstatus: "",
    });

    const loadlazydata = () => {
        const loadLazyTimeout = setTimeout(async () => {
            setIsadding(true);
            const storeData = await GetDietitiandetailsbyID(id as string);

            console.log("storeData", storeData.data.data);

            setDietObj(storeData.data.data);
            setLvisible(false);
            setIsadding(false);
        }, 100);
    };
    useEffect(() => {
        setIsadding(true);
        loadlazydata();
        setLvisible(true);
    }, []);

    const [dietObj, setDietObj] = useState({
        about: '',
        agentname: "",
        approvalstatus: "",
        awards: [],
        certification: "",
        certificationimages: [],
        city: "",
        clinicaddress: "",
        clinicfacebookurl: "",
        clinicimages: [],
        clinicinstaurl: "",
        clinicname: "",
        clinictwitterurl: "",
        clinicwebsite: "",
        clinicyoutubeurl: "",
        country: "",
        college: "",
        createdAt: "",
        dateadded: "",
        dietitianfacebookurl: "",
        dietitianimages: [],
        dietitianinstaurl: "",
        dietitianname: "",
        dietitiantitle: "",
        dietitiantwitterurl: "",
        dietitianwebsite: "",
        dietitianyoutube:"",
        district: "",
        degree: "",
        email: "",
        experience: "",
        fees: "",
        gender: "",
        languages: [],
        locality: "",
        location: { coordinates: [0.0, 0.0] },
        mode: [],
        membership: [],
        phoneno: "",
        pincode: "",
        registereddietitian: "",
        services: [],
        speciality: [],
        timings: [],
        yearofcompletion: "",
        state: "",
        _id: ""
    });


    console.log("diettttt", dietObj);



    return (
        <main>
            <div>
                <Navbar />
                <section className="page-content">
                    <div className="ym-content-table mt-0">
                        <div className="page_title d-flex">
                            <div className="p-2 d-flex">
                                &nbsp;
                                <span
                                    className="back_rest_btn d-flex gap-2 align-items-center"
                                    onClick={() => {
                                        const currentPath = window.location.pathname;
                                        if (currentPath.includes("/pending")) {
                                            navigate(`/dietitian/pendingdietitian`);
                                        } else if (currentPath.includes("/approved")) {
                                            navigate(`/dietitian/approveddietitian`);
                                        } else if (currentPath.includes("/rejected")) {
                                            navigate(`/dietitian/rejecteddietitian`);
                                        }
                                    }}
                                >
                                    <i className="pi pi-arrow-left"></i>
                                    Dietitian Details
                                </span>
                            </div>

                            
                        </div>
                        <hr />
                        <div className="ym-content-table mb-4">
                            <div className=" gap-3 d-flex align-items-start">
                                <div
                                    className="row mb-1 col-2 view-gallery-cols"
                                    style={{ height: "180px" }}
                                >
                                    <div className="col-5 pt-0 pl-0">
                                        
                                        <Image className='main-details-profile' src={dietObj.dietitianimages[0] + "?" + sas} alt="Image" preview />
                                    </div>
                                </div>

                                <div className="row mb-1 col-lg-9">
                                    <div className="col d-flex align-items-left mb-4 justify-content-center flex-column ml-1 gap-4 rest-details">
                                        <div className="d-flex gap-3" >
                                            <div className="page-title col-lg-6">
                                                {!lvisible ? (
                                                    <h1 className="ym-portal-heading">
                                                        {dietObj.dietitiantitle} {dietObj.dietitianname}
                                                    </h1>
                                                ) : (
                                                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                                                )}
                                            </div>


                                        </div>

                                        <div className="d-flex">
                                            <div className="address-div col-lg-3">
                                                {!lvisible ? (
                                                    <div>
                                                        <p className="address-heading gap-2 d-flex align-items-center">
                                                            Gender
                                                        </p>
                                                        <p className="area-data mb-0">
                                                            {dietObj.gender}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                )}
                                            </div>

                                            <div className="address-div col-lg-3">
                                                {!lvisible ? (
                                                    <div>
                                                        <p className="address-heading gap-2 d-flex align-items-center">
                                                            Experience
                                                        </p>
                                                        <p className="area-data mb-0">
                                                            {dietObj.experience} Years
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                )}
                                            </div>

                                            <div className="address-div col-lg-3">
                                                {!lvisible ? (
                                                    <div>
                                                        <p className="address-heading gap-2 d-flex align-items-center">
                                                            Specialty
                                                        </p>
                                                        <p className="area-data mb-0">
                                                            {dietObj.speciality.join('/')}
                                                        </p>

                                                    </div>
                                                ) : (
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                )}
                                            </div>

                                        </div>

                                        <div className=" d-flex gap-3">
                                            <div className="phone-email d-flex col-lg-6">
                                                <div className="phone-num col-lg-6">
                                                    {!lvisible ? (
                                                        <div>
                                                            <p className="address-heading gap-2 d-flex align-items-center">
                                                                Mode
                                                            </p>
                                                            <p className="area-data mb-0">
                                                                {dietObj.mode.join('&')}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                    )}
                                                </div>
                                                <div className="email-st">
                                                    {!lvisible ? (
                                                        <div>
                                                            <p className="address-heading gap-2 d-flex align-items-center">
                                                                Fees
                                                            </p>
                                                            <p className="area-data mb-0">
                                                            ₹{dietObj.fees}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <TabView>
                            <TabPanel header="Dietitian Details">
                                <DietitianInfo
                                registereddietitian={dietObj.registereddietitian}
                                languages={dietObj.languages}
                                services={dietObj.services}
                                about= {dietObj.about}
                                dietitianinstagram = {dietObj.dietitianinstaurl}
                                dietitiantwitter = {dietObj.dietitiantwitterurl}
                                dietitianyoutube = {dietObj.dietitianyoutube}
                                dietitianfacebook = {dietObj.dietitianfacebookurl}
                                dietitianwebsite = {dietObj.dietitianwebsite}
                                />
                            </TabPanel>
                            <TabPanel header="Education Details">
                                <EducationalInfo
                                degree={dietObj.degree}
                                college={dietObj.college}
                                yearofcompletion={dietObj.yearofcompletion}
                                certification={dietObj.certification}
                                membership={dietObj.membership}
                                awardsandrecognitions={dietObj.awards}
                                certificationimages={dietObj.certificationimages}
                                />
                            </TabPanel>
                            <TabPanel header="Clinic Details">
                                <ClinicInfo
                                clinicname={dietObj.clinicname}
                                phone={dietObj.phoneno}
                                email={dietObj.email}
                                address={dietObj.clinicaddress}
                                locality={dietObj.locality}
                                city={dietObj.city}
                                state={dietObj.state}
                                pincode={dietObj.pincode}
                                latitude={dietObj.location.coordinates[1]}
                                longitude={dietObj.location.coordinates[0]}
                                clinicwebsite={dietObj.clinicwebsite}
                                clinicinstagram={dietObj.clinicinstaurl}
                                clinictwitter={dietObj.clinictwitterurl}
                                clinicyoutube={dietObj.clinicyoutubeurl}
                                clinicfacebook={dietObj.clinicfacebookurl}
                                />
                            </TabPanel>
                            <TabPanel header="Timings">
                                <DietitianTimings timings={dietObj.timings}/>
                            </TabPanel>
                            <TabPanel header="Images">
                                <ClinicAndDietitianImages
                                clinicimages={dietObj.clinicimages}
                                dietitianimages={dietObj.dietitianimages}
                                />
                            </TabPanel>
                        </TabView>
                    </div>
                </section>

            </div>
        </main>
    );
}

export default DietitianDetails;
