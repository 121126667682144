import React from 'react';
import { Image } from "primereact/image";
import {sas} from "../../config/constants"

interface props {
  clinicimages: string[];
  dietitianimages: string[];
}

const ClinicAndDietitianImages: React.FC<props> = ({ dietitianimages, clinicimages }) => {
  return (
    <div>
      <div className="d-flex mt-2 mb-2">
        {dietitianimages.map((item) => (
          <div className="tttt">
            {/*@ts-ignore   */}
            <Image className='clinic-images' src={item + "?" + sas} alt="Image" preview/>

          </div>
        ))}
      </div>
      <div className="d-flex mt-4 mb-4">
        {clinicimages.map((item) => (
          <div className="tttt">
            {/*@ts-ignore   */}
            <Image className='clinic-images' src={item + "?" + sas} alt="Image" preview />

          </div>
        ))}
      </div>
    </div>
  );
}

export default ClinicAndDietitianImages;
