import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from '../config/constants'

type ResObj={
    name: string,
    address1:string,
    address2:string,
    city: string,
    state: string,
    maplink: string,
    latitude:string,
    longitude:string,
    country:string,
    diettype:any[],
    pincode: string,
    landmark: string,
    website: string,
    email:string,
    phoneno: string,
    eaterytype: string,
    foodtype: any[],
    preference:string [],
    cuisine:string[],
    ambience: string,
    serving: string[],
    mealoftheday: string[],
    serviceoptions: string[],
    values: string[],
    features:string [],
    specialitems: string[],
    sourcelink: string,
    timings: string[],
    days:string[],
    source: string,
    about: string[],
    whyinyeldam:string[],
    images: string[],
    zomatolink:string,
    swiggylink:string,
    dineoutlink:string,
    averagecost:string,
    district:string
}
const HttpInstanceImage=axios.create({
    baseURL: apiUrl,
    headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken'),
     'Content-Type':'multipart/form-data'
    }
  })
export const SubmitRestaurant = (resObj:any)=>{
   const respData= HttpInstanceImage.post("/restaurant/createrestaurant",resObj)
   return respData
}

export const SearchRestaurant = (search:string)=>{
    const respData = HttpInstance.post("/restaurant/searchinaddres?search="+search)
    return respData
}

export const getDistricts = (state:string)=>{
    const respData = HttpInstance.post("/districts/getdistricts",{state:state})
    return respData
}
export const SubmitEvent = (resObj:any)=>{
    const respData= HttpInstance.post("/foodevents/addfoodevents",resObj)
    return respData
 }
 export const getCategories = ()=>{
    const respData = HttpInstance.get("/restaurant/categories")
    return respData
}
export const SubmitOrganicStore = (resObj:any)=>{
    const respData= HttpInstanceImage.post("/organicstore/addorganicstore",resObj)
    return respData
 }
 
