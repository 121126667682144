import React, { useEffect, useState } from 'react'
import "./approvedStores.pages.css";
import ApprovedStoretable from '../../../components/ApprovedOrganicStoreTable/approvedStore-table.component';
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { getRefreshedToken, isTokenExpired } from "../../../api/auth.api";
import { useNavigate } from "react-router-dom";
import { GetStoreTableData } from '../../../api/pendingTable.api';
import { Button } from 'primereact/button';
const ApprovedStores=()=>{
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(undefined);
  // const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const navigate = useNavigate();

  // const [selectedRepresentative, setSelectedRepresentative] = useState(undefined);
  var width = window.screen.width;
  var c_rows = 10;
  if (width >= 1920) {
    c_rows = 15;
  } else if (width >= 1400 || width <= 1600) {
    c_rows = 10;
  }
  const [lazyParams, setLazyParams] = useState({
    approvalstatus: 1,
    first: 0,
    rows: c_rows,
    page: 0,
    sortField: "",
    sortOrder: 0,
    filters: {
      'storename': { value: '', matchMode: 'contains' },
      'city': { value: '', matchMode: 'contains' },
    },
  });


  let loadLazyTimeout: any = 0;
  
  useEffect(() => {
    loadLazyData();
  }, [lazyParams]);

  
  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(async () => {
      console.log(lazyParams);
      const respObj = await GetStoreTableData(lazyParams, 1);
      console.log("Inside pending",respObj.data.data);
      setCustomers(respObj.data.data.stores);
      setTotalRecords(respObj.data.data.totalRecords);
      setLoading(false);
    }, 100);
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyParams(event);
  };

  const representativeBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <img
          alt={rowData.representative.name}
          src={`https://www.primereact.org/images/avatar/${rowData.representative.image}`}
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="vertical-align-middle ml-2">
          {rowData.representative.name}
        </span>
      </React.Fragment>
    );
  };

  const countryBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <img
          alt="flag"
          src="https://www.primereact.org/images/flag/flag_placeholder.png"
          className={`flag flag-${rowData.country.code}`}
          width={30}
        />
        <span className="vertical-align-middle ml-2">
          {rowData.country.name}
        </span>
      </React.Fragment>
    );
  };
  const viewProduct = (product: any) => {
    // setDeleteProductDialog(true);
    navigate(`/organicstores/approvedstore/details/${product._id}`);
    // window.open(`/approved/details/${product._id}`);
  };
  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        {/* <Tooltip
          target=".p-dt-tooltip"
          // content="View"
          mouseTrack
          mouseTrackLeft={10}
        /> */}
        <Button
          icon="pi pi-eye"
          label="View Details"
          className="p-button-rounded mr-2 view-bt p-dt-tooltip"
          onClick={() => viewProduct(rowData)}
        />
      </React.Fragment>
    );
  };
  return (
    <>
      <main>
        <div>
          <Navbar />
          <section className="page-content">
            <div className="ym-content-table">
              
                <div>
                  <h1 className="tables-heading">Approved Restaurants</h1>
                </div>
                <hr style={{color:"#ececec",height:"1px",opacity:"100%"}}/>
              
              <div className="">
                <ApprovedStoretable
                  customers={customers}
                  first={lazyParams.first}
                  sortField={lazyParams.sortField}
                  sortOrder={lazyParams.sortOrder}
                  totalRecords={totalRecords}
                  onPage={onPage}
                  onSort={onSort}
                  
                  onFilter={onFilter}
                  filters={lazyParams.filters}
                  loading={loading}
                  countryBodyTemplate={countryBodyTemplate}
                  representativeBodyTemplate={representativeBodyTemplate}
                  actionBodyTemplate={actionBodyTemplate}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}

export default ApprovedStores;
