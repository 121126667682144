import React from 'react';
import "./eventDetails.css";


type props = {
    eventtitle: string;
    eventstartdate: any;
    eventcity: string;
    eventdescription: string[];
    eventlink: string;
    status: 'pending' | 'approved' | 'rejected';
}
const EventDetails: React.FC<props> = ({ eventtitle, eventstartdate, eventcity, eventdescription, eventlink, status }) => {
    let statusText = null;

    // Determine which status text to display based on the status prop
    if (status === 'pending') {
        statusText = <span className='pending-text'>Pending</span>;
    } else if (status === 'approved') {
        statusText = <span className='approved-text'>Approved</span>;
    } else if (status === 'rejected') {
        statusText = <span className='rejected-text'>Rejected</span>;
    }
    return (
        <>
            <div className='d-flex pt-2'>
                {statusText}
            </div>
            <div className='event-title-name pt-2'>
                <span>{eventtitle}</span>
            </div>
            <div className='d-flex gap-6 align-items-center pt-2'>
                <div className='date-event d-flex align-items-center gap-2'>
                    <i className="pi pi-calendar" style={{ color: "#818181" }}></i> {" "}
                    <span className='date-text'>{eventstartdate}</span>
                </div>
                <div className='city-event d-flex align-items-center gap-2'>
                <i className="pi pi-map-marker" style={{ color: "#818181" }}></i> {" "}
                    <span className='date-text'>{eventcity}</span>
                </div>

            </div>
            <div className='pt-2'>
                <span className='event-desc'>{eventdescription}</span>
            </div>
            <div className='pt-2'>
            <p className="event-link gap-2 d-flex align-items-center">
                            <i
                              className="pi pi-globe"
                             
                            ></i>
                            {eventlink}
                          </p>
            </div>
        </>
    );
}

export default EventDetails;
