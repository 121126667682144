import React from "react";
import "./rejectedStore-table.component.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

type props = {
  customers: any;
  first: any;
  sortField: any;
  sortOrder: any;
  totalRecords: any;
  onPage: any;
  onSort: any;
  onFilter: any;
  filters: any;
  loading: any;
  countryBodyTemplate: any;
  representativeBodyTemplate: any;
  actionBodyTemplate: any;
};
var width = window.screen.width;
var c_rows = 10;
if (width >= 1920) {
  c_rows = 15;
} else if (width >= 1400 || width <= 1600) {
  c_rows = 10;
}

const RejectedStoretable: React.FC<props> = ({
  customers,
  first,
  sortField,
  sortOrder,
  totalRecords,
  onPage,
  onSort,
  onFilter,
  filters,
  loading,
  countryBodyTemplate,
  representativeBodyTemplate,
  actionBodyTemplate,
}) => {
  return (
    <DataTable
      className="pending-table-data"
      value={customers}
      lazy
      filterDisplay="row"
      responsiveLayout="scroll"
      // dataKey="id"
      paginator
      first={first}
      rows={c_rows}
      totalRecords={totalRecords}
      onPage={onPage}
      onSort={onSort}
      sortField={sortField}
      sortOrder={sortOrder}
      onFilter={onFilter}
      filters={filters}
      loading={loading}
    >
      <Column
        field="storename"
        header="Organic Store Name"
        sortable
        filter
        filterPlaceholder="Search by Name"
      />
      <Column
        field="city"
        sortable
        header="City"
        filter
        filterPlaceholder="Search by City"
      />
      <Column
        field="storetype"
        header="Store Type"
        body={(rowData) => (
          <span>
            {rowData.storetype.map((type:any, index:any) => (
              <span key={index}>
                {index > 0 && ', '}
                {type}
              </span>
            ))}
          </span>
        )}
        sortable
        filter
        filterPlaceholder="Search by Store Type"
      />
      <Column
        header="Actions"
        className="action_btn"
        exportable={false}
        body={actionBodyTemplate}
        style={{ minWidth: "8rem" }}
      ></Column>
    </DataTable>
  );
};

export default RejectedStoretable;
