import React, { useEffect, useRef, useState } from "react";
import "./EditRestaurant.css";
import { InputText } from "primereact/inputtext";
import { ScrollPanel } from "primereact/scrollpanel";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import DropFileInput from "../../../pages/restaurants/drop-file-input/drop-file-input";
// import { GetRestByID } from "../../../api/pendingTable.api";
import { useParams } from "react-router-dom";

import RestaurantTimings from "../../../components/RestaurantTimings/timings.component";

import { Button } from "primereact/button";
import { response } from "express";
import { Toast } from "primereact/toast";
import { getCategories } from "../../../api/addRestarant.api";
import { EditRestaurantSubmit } from "../../../api/editRestaurant.api";
import { sas } from "../../../config/constants";

interface eateryType {
  name: string;
  code: string;
}
interface Cuisine {
  name: string;
  code: string;
}
interface ServiceOptions {
  name: string;
  code: string;
}

const EditRestaurant: React.FC<any> = ({ restdataObj, setEditVisible }) => {
  const [eaterytypelist, setEaterytypelist] = useState<eateryType[]>([]);
  const [cuisinelist, setCuisinelist] = useState<Cuisine[]>([]);
  const { id } = useParams();
  const [thumbnailImages, setThumbnailImages] = useState<any[]>([]);
  const [restaurantTages, setRestaurantTages] = useState(null);
  const [menuImages, setMenuImages] = useState<any[]>([]);
  const [restaurantImages, setRestantImages] = useState<any[]>([]);
  const [commentObj, setCommentObj] = useState({
    agentID: "",
    restID: "",
    comment: "",
    approvalstatus: "",
  });

  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };
  const tag = [
    "High in Demand",
    "Popular for Music", 
    "Popular for Food", 
    "Popular for Ambience",
  ];

  const Timings = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
    "11:59pm",
    "12:00am",
  ];
  const daysOfWeek: string[] = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const [checkedDays, setCheckedDays] = useState(() => {
    const initialCheckedState: Record<string, boolean> = {};
    daysOfWeek.forEach((day) => {
      initialCheckedState[day] = false;
    });
    return initialCheckedState;
  });

  const handleCheckboxChange = (day: string) => {
    setCheckedDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };
  const onFromTimeChange = (e: any) => {
    setfromtimes(e.value);
  };

  const onToTimeChange = (e: any) => {
    settotimes(e.value);
  };

  const get_eaterytype = async () => {
    const response = await getCategories();
    console.log("object inside etry", response.data);
    setEaterytypelist(response.data.data[3].value);
  };

  const get_cuisine = async () => {
    const response = await getCategories();
    setCuisinelist(response.data.data[1].value);
  };

  const [restObj, setRestObj] = useState(restdataObj);

  let [mappedOptions, setMappedOptions] = useState<any>([]);


  useEffect(() => {
    get_eaterytype();
    get_cuisine();
    setMappedOptions(restObj.serviceoptions.map((option: any, index: any) => ({
      name: option,
      code: option.toUpperCase().substring(0, 3), // You can use a unique identifier for the code property
    })))
  }, []);



  const handlePreferenceChange = (value: any) => {
    const updatedPreferences = restObj.preference.includes(value)
      ? restObj.preference.filter((pref: any) => pref !== value)
      : [...restObj.preference, value];

    setRestObj({ ...restObj, preference: updatedPreferences });
  };

  const handleServingChange = (value: any) => {
    const updatedServing = restObj.serving.includes(value)
      ? restObj.serving.filter((serv: any) => serv !== value)
      : [...restObj.serving, value];

    setRestObj({ ...restObj, serving: updatedServing });
  };

  //for timing
  const initialSelectedTimings = {};
  const [selectedTimings, setSelectedTimings] = useState<
    Record<string, string[]>
  >(initialSelectedTimings);
  const [totimes, settotimes] = useState();
  const [fromtimes, setfromtimes] = useState();

  const handleAddTiming = () => {
    if (fromtimes && totimes) {
      const selectedDays = Object.keys(checkedDays).filter(
        (day) => checkedDays[day]
      );

      setSelectedTimings((prevSelectedTimings) => {
        const updatedTimings = { ...prevSelectedTimings };

        selectedDays.forEach((dayKey) => {
          const existingTimings = updatedTimings[dayKey] || [];
          const newTiming = `${fromtimes} to ${totimes}`;
          updatedTimings[dayKey] = [...existingTimings, newTiming];
        });

        console.log("selectedTimings", updatedTimings);

        // Directly set the updatedTimings without wrapping it in an array
        //setResObj({ ...resObj, timings: updatedTimings });

        return updatedTimings;
      });
    }
  };

  const timingsJSON = JSON.stringify(selectedTimings);

  const handleDeleteClick = (index: any, purpose: any) => {
    const updatedRestObj = { ...restObj };
    console.log("updatedRestObj", updatedRestObj);
    if (purpose === "ThumbnailImages") {
      const updatedThumbnails = [...restObj.thumbnails];
      updatedThumbnails.splice(index, 1);
      updatedRestObj.thumbnails = updatedThumbnails;
    } else if (purpose === "RestaurantImages") {
      const updatedImages = [...updatedRestObj.images];
      updatedImages.splice(index, 1);
      updatedRestObj.images = updatedImages;
    } else if (purpose === "MenuImages") {
      const updatedMenuImages = [...updatedRestObj.menuimages];
      updatedMenuImages.splice(index, 1);
      updatedRestObj.menuimages = updatedMenuImages;
    }

    setRestObj(updatedRestObj);
  };
  console.log("restdata:",restObj);
  const handleEditRestaurant = async () => {
    if (restObj) {
      const formData = new FormData();
      restObj.preference.forEach((pref: string, index: number) => {
        formData.append(`preference[${index}]`, pref);
      });
      restObj.serving.forEach((serve: string, index: number) => {
        formData.append(`serving[${index}]`, serve);
      });
      formData.append("name", restObj.name);
      formData.append("phoneno", restObj.phoneno);
      formData.append("address1", restObj.address1);
      formData.append("latitude",restObj.location.coordinates[1]); 
      formData.append("longitude", restObj.location.coordinates[0]); 
      
      formData.append("city", restObj.city);
      formData.append("district", restObj.district);
      formData.append("state", restObj.state);
      formData.append("country", restObj.country);
      formData.append("pincode", restObj.pincode);
      formData.append("averagecost", restObj.averagecost);
      restObj.thumbnails.forEach((thumbnail: File, index: number) => {
        formData.append(`thumbnails`, thumbnail);
      });
      restObj.images.forEach((image: File, index: number) => {
        formData.append(`images`, image);
      });
      restObj.menuimages.forEach((menuimage: File, index: number) => {
        formData.append(`menuimages`, menuimage);
      });
      restObj.eaterytype.forEach((eateryType: any, index: any) => {
        formData.append(`eaterytype[${index}][name]`, eateryType.name);
        formData.append(`eaterytype[${index}][code]`, eateryType.code);
      });
      restObj.cuisine.forEach((cuisine: any, index: any) => {
        formData.append(`cuisine[${index}][name]`, cuisine.name);
        formData.append(`cuisine[${index}][code]`, cuisine.code);
      });


      // formData.append("serviceoptions", restObj.serviceoptions);
      restObj.serviceoptions.forEach((serviceoption: string, index: number) => {
        formData.append(`serviceoptions[${index}]`, serviceoption);
      });
      formData.append("tags", restObj.tags);
      if (hygienerating !== null) formData.append("hygiene", hygienerating.toString());
      if (healthrating !== null) formData.append("health", healthrating.toString());
      if (tasterating !== null) formData.append("taste", tasterating.toString());
      if (effectivecostrating !== null) formData.append("costeffective", effectivecostrating.toString());
      if (valuesrating !== null) formData.append("valuesrating", valuesrating.toString());
      if (hospitalityrating !== null) formData.append("hospitality", hospitalityrating.toString());
      if (
        JSON.stringify(selectedTimings) !==
        JSON.stringify(initialSelectedTimings)
      ) {
        formData.append("timings", JSON.stringify(selectedTimings));
      }
      try {
        const response = await EditRestaurantSubmit(restObj._id, formData);
        if(response.status === 200)
        {
          setTimeout(async() => {
            setEditVisible(false);
        }, 3000);
        show("Restaurant Updated Successfully", "success", "Success");
        }
      } catch (error) {
        console.error("Error updating restaurant:", error);
      }
    }
  };

  const [hygienerating, sethygienerating] = useState<number | null>(null);
  const [healthrating, sethealthrating] = useState<number | null>(null);
  const [tasterating, settasterating] = useState<number | null>(null);
  const [effectivecostrating, seteffectivecostrating] = useState<number | null>(
    null
  );
  const [valuesrating, setvaluesrating] = useState<number | null>(null);
  const [hospitalityrating, sethospitalityrating] = useState<number | null>(
    null
  );
  const [boxNumberColors, setBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [hygieneboxNumberColors, sethygieneBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [tasteboxNumberColors, settasteBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [costboxNumberColors, setcostBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [valueboxNumberColors, setvalueBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );
  const [hospitalityboxNumberColors, sethospitalityBoxNumberColors] = useState<{ [key: number]: string }>(
    Object.fromEntries(Array.from({ length: 10 }, (_, index) => [index, '#f8f8f8']))
  );

  const handleHygieneRatingClick = (value: number) => {
    sethygienerating(value);

    console.log("hygiene", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    // Update background color
    const updatedColors = { ...hygieneboxNumberColors, [value - 1]: backgroundColor };
    sethygieneBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;

  };

  const handleHealthRatingClick = (value: number) => {
    sethealthrating(value);

    console.log("health", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }

    // Update background color
    const updatedColors = { ...boxNumberColors, [value - 1]: backgroundColor };
    setBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleTasteRatingClick = (value: number) => {
    settasterating(value);

    console.log("taste", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...tasteboxNumberColors, [value - 1]: backgroundColor };
    settasteBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleCostEffectiveRatingClick = (value: number) => {
    seteffectivecostrating(value);

    console.log("cost", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...costboxNumberColors, [value - 1]: backgroundColor };
    setcostBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleValueRatingClick = (value: number) => {
    setvaluesrating(value);

    console.log("values", value);
    let backgroundColor = "";
    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...valueboxNumberColors, [value - 1]: backgroundColor };
    setvalueBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };

  const handleHospitalityRatingClick = (value: number) => {
    sethospitalityrating(value);

    console.log("hospitality", value);
    let backgroundColor = "";

    if (value >= 1 && value <= 3) {
      backgroundColor = "pink";
    } else if (value >= 4 && value <= 6) {
      backgroundColor = "orange";
    } else if (value >= 7 && value <= 10) {
      backgroundColor = "#36a41d";
    }
    const updatedColors = { ...hospitalityboxNumberColors, [value - 1]: backgroundColor };
    sethospitalityBoxNumberColors(updatedColors);
    console.log("bbb", updatedColors);
    // Update background color of document body
    document.body.style.backgroundColor = backgroundColor;
  };









  const handleMultiSelectChange = (selectedOptions: any) => {
    // Update mappedOptions based on the selected options
    console.log("selectedOptions",selectedOptions)

    const selectedValues = selectedOptions.map((option: any) => option.name);

    console.log("selectedValues",selectedValues)
    
  
  //  console.log("updatedOptions",updatedOptions)

setMappedOptions(selectedOptions)
   setRestObj({ ...restObj, serviceoptions: selectedValues });
  };

  return (
    <>
      <div className="p-2 d-flex  gap-2">
        <Toast ref={toast} position="top-right" />
        <ScrollPanel>
          <div className="d-flex flex-column gap-2">
            <div className="row gap-1">
              <label className="ym-input-label">
                Restaurant Name <b style={{ color: "red" }}>*</b>
              </label>
              <div className="flex flex-column">
                <InputText
                  // placeholder="Enter Restaurant Name"
                  maxLength={50}
                  value={restObj.name}
                  onChange={(e) => {
                    setRestObj({
                      ...restObj,
                      name: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row gap-1">
              <label className="ym-input-label">
                Phone Number <b style={{ color: "red" }}>*</b>
              </label>
              <div className="flex flex-column">
                <InputText
                  // placeholder="Enter Restaurant Name"
                  maxLength={50}
                  value={restObj.phoneno}
                  onChange={(e) => {
                    setRestObj({
                      ...restObj,
                      phoneno: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="row gap-1">
              <label className="ym-input-label">
                Address <b style={{ color: "red" }}>*</b>
              </label>
              <div className="flex flex-column">
                <InputTextarea
                  maxLength={150}
                  value={restObj.address1}
                  onChange={(e) => {
                    setRestObj({
                      ...restObj,
                      address1: e.target.value,
                    });
                  }}
                  rows={5}
                  cols={30}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="d-flex align-items-baseline">
                  <label className="ym-input-label">
                    Latitude
                    <b style={{ color: "red" }}>*</b>
                  </label>
                  <i
                    className="bi bi-info-circle"
                    title="Enter the valid latitude"
                    id="icon"
                  ></i>
                </div>
                <div className="flex flex-column">
                  <InputText
                    maxLength={30}
                    value={restObj.location.coordinates[1]}
                    name="latitude"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setRestObj({
                        ...restObj,
                        location: {
                          ...restObj.location,
                          coordinates: [
                            restObj.latitude,
                            parseFloat(value),
                          ],
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="d-flex align-items-baseline">
                  <label className="ym-input-label">
                    Longitude
                    <b style={{ color: "red" }}>*</b>
                  </label>
                  <i
                    className="bi bi-info-circle"
                    title="Enter the valid longitude"
                    id="icon"
                  ></i>
                </div>
                <div className="flex flex-column">
                  <InputText
                    maxLength={30}
                    value={restObj.location.coordinates[0]}
                    name="longitude"
                    onChange={(e) => {
                      const { name, value } = e.target;
                      setRestObj({
                        ...restObj,
                        location: {
                          ...restObj.location,
                          coordinates: [
                            parseFloat(value),
                            restObj.location.coordinates[1],
                          ],
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="d-flex align-items-baseline">
                  <label className="ym-input-label">
                    City
                    <b style={{ color: "red" }}>*</b>
                  </label>
                  <i
                    className="bi bi-info-circle"
                    title="Enter the vaild logitude"
                    id="icon"
                  ></i>
                </div>
                <div className="flex flex-column">
                  <InputText
                    maxLength={30}
                    value={restObj.city}
                    onChange={(e) => {
                      setRestObj({
                        ...restObj,
                        city: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="d-flex align-items-baseline">
                  <label className="ym-input-label">
                    District
                    <b style={{ color: "red" }}>*</b>
                  </label>
                  <i
                    className="bi bi-info-circle"
                    title="Enter the vaild logitude"
                    id="icon"
                  ></i>
                </div>
                <div className="flex flex-column">
                  <InputText
                    maxLength={30}
                    value={restObj.district}
                    onChange={(e) => {
                      setRestObj({
                        ...restObj,
                        district: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="d-flex align-items-baseline">
                  <label className="ym-input-label">
                    State
                    <b style={{ color: "red" }}>*</b>
                  </label>
                  <i
                    className="bi bi-info-circle"
                    title="Enter the vaild logitude"
                    id="icon"
                  ></i>
                </div>
                <div className="flex flex-column">
                  <InputText
                    maxLength={30}
                    value={restObj.state}
                    onChange={(e) => {
                      setRestObj({
                        ...restObj,
                        state: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="d-flex align-items-baseline">
                  <label className="ym-input-label">
                    Country
                    <b style={{ color: "red" }}>*</b>
                  </label>
                  <i
                    className="bi bi-info-circle"
                    title="Enter the vaild logitude"
                    id="icon"
                  ></i>
                </div>
                <div className="flex flex-column">
                  <InputText
                    maxLength={30}
                    value={restObj.country}
                    onChange={(e) => {
                      setRestObj({
                        ...restObj,
                        country: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="row gap-1">
              <label className="ym-input-label">
                PinCode <b style={{ color: "red" }}>*</b>
              </label>
              <div className="flex flex-column">
                <InputText
                  // placeholder="Enter Restaurant Name"
                  maxLength={50}
                  value={restObj.pincode}
                  onChange={(e) => {
                    setRestObj({
                      ...restObj,
                      pincode: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="row gap-1">
              <label className="ym-input-label mt-2">Preferences</label>
              <div className="row">
                <div className="col-lg-3">
                  <div className="flex align-items-center gap-2">
                    <Checkbox
                      inputId="preference1"
                      name="preferences"
                      value="Veg"
                      checked={(restObj.preference as string[]).includes("Veg")}
                      onChange={(e) => handlePreferenceChange(e.target.value)}
                    />
                    <label htmlFor="preference1" className="ml-2">
                      Veg
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="preference2"
                      name="preferences"
                      value="Non-Veg"
                      checked={(restObj.preference as string[]).includes(
                        "Non-Veg"
                      )}
                      onChange={(e) => handlePreferenceChange(e.target.value)}
                    />
                    <label htmlFor="preference2" className="ml-2">
                      Non Veg
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="preference3"
                      name="preferences"
                      value="Pure-veg"
                      checked={(restObj.preference as string[]).includes(
                        "Pure-veg"
                      )}
                      onChange={(e) => handlePreferenceChange(e.target.value)}
                    />
                    <label htmlFor="preference3" className="ml-2">
                      Pure Veg
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row gap-1">
              <label className="ym-input-label mt-2">serving</label>
              <div className="row gap-1">
                <div className="col-lg-3">
                  <div className="flex align-items-center gap-2">
                    <Checkbox
                      inputId="service1"
                      name="services"
                      value="Ala Carte"
                      checked={(restObj.serving as string[]).includes(
                        "Ala Carte"
                      )}
                      onChange={(e) => handleServingChange(e.target.value)}
                    />
                    <label htmlFor="service1" className="ml-2">
                      Ala Carte
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="service2"
                      name="services"
                      value="Buffet"
                      checked={(restObj.serving as string[]).includes("Buffet")}
                      onChange={(e) => handleServingChange(e.target.value)}
                    />
                    <label htmlFor="service2" className="ml-2">
                      Buffet
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="service3"
                      name="services"
                      value="Meals"
                      checked={(restObj.serving as string[]).includes("Meals")}
                      onChange={(e) => handleServingChange(e.target.value)}
                    />
                    <label htmlFor="service3" className="ml-2">
                      Meals
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="service5"
                      name="services"
                      value="Thali"
                      checked={(restObj.serving as string[]).includes("Thali")}
                      onChange={(e) => handleServingChange(e.target.value)}
                    />
                    <label htmlFor="service5" className="ml-2">
                      Thali
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="flex align-items-center">
                    <Checkbox
                      inputId="service6"
                      name="services"
                      value="Self Cooking"
                      checked={(restObj.serving as string[]).includes(
                        "Self Cooking"
                      )}
                      onChange={(e) => handleServingChange(e.target.value)}
                    />
                    <label htmlFor="service6" className="ml-2">
                      Self Cooking
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="">
                  <div className="d-flex align-items-baseline">
                    <label className="ym-input-label ">
                      Average cost (per 2 person)
                      <b style={{ color: "red" }}>*</b>
                    </label>
                    <i
                      className="bi bi-info-circle"
                      title="Please enter the average cost per 2 persons that can be spent in that restaurant"
                      id="icon"
                    ></i>
                  </div>
                  <div className="flex flex-column">
                    <InputText
                      maxLength={6}
                      value={restObj.averagecost}
                      //   placeholder="Enter Amount"
                      onChange={(e) =>
                        setRestObj({ ...restObj, averagecost: e.target.value })
                      }
                      keyfilter={/^[0-9]*$/}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="">
                  <div className="d-flex align-items-baseline gap-1">
                    <label className="ym-input-label ">
                      Eatery type
                      <b style={{ color: "red" }}>*</b>
                    </label>
                    <i
                      className="bi bi-info-circle"
                      title="Select the eatery type for the restaurant"
                      id="icon"
                    ></i>
                  </div>
                  <div className="flex flex-column">
                    <MultiSelect
                      options={[
                        { name: "Restaurant", code: "RES" },
                        { name: "Bakery", code: "BAK" },
                        { name: "Cafe", code: "CAF" },
                        { name: "Cloud Kitchen", code: "CLO" },
                        { name: "Store", code: "STO" },
                        { name: "Dhaba", code: "DHA" },
                        { name: "Food Court", code: "FOO" },
                        { name: "Juice", code: "JUI" },
                        { name: "Tiffins", code: "TIF" },
                        { name: "Street Food", code: "STR" },
                        { name: "Sandwich", code: "SAN" },
                        { name: "Fruit Salad", code: "FRU" },
                        { name: "Oats", code: "OAT" },
                        { name: "Vegetable Salads", code: "VEG" },
                        { name: "Sweets", code: "SWT" },
                        { name: "Desserts", code: "DST" },
                        { name: "Mess", code: "MES" },
                      ]}
                      value={restObj.eaterytype.filter(
                        (eatery: eateryType[]) => eatery != null
                      )}
                      onChange={(e: MultiSelectChangeEvent) => {
                        console.log("{restObj.eaterytype", restObj.eaterytype);
                        setRestObj({ ...restObj, eaterytype: e.value });
                      }}
                      optionLabel="name"
                      display="chip"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="d-flex align-items-baseline gap-1">
                  <label className="ym-input-label">Cuisine</label>
                  <i
                    className="bi bi-info-circle"
                    title="Select the cuisine available in the restaurant"
                    id="icon"
                  ></i>
                </div>
                <div className="flex flex-column gap-2">
                  <MultiSelect
                    options={[
                      { name: "Chinese", code: "CHI" },
                      { name: "Mughlai", code: "MUG" },
                      { name: "Italian", code: "ITA" },
                      { name: "Continental", code: "CON" },
                      { name: "American", code: "AM" },
                      { name: "Thai", code: "TH" },
                      { name: "Mediterranean", code: "MED" },
                      { name: "Mexican", code: "MEX" },
                      { name: "Japanese", code: "JAP" },
                      { name: "Bengali", code: "BEN" },
                      { name: "Awadhi", code: "AWa" },
                      { name: "Oriental", code: "ORI" },
                      { name: "Sichuan", code: "SIC" },
                      { name: "Spanish", code: "SPA" },
                      { name: "Chettinad", code: "CHE" },
                      { name: "South Indian", code: "SOI" },
                      { name: "North Indian", code: "NOI" },
                      { name: "Andhra", code: "AND" },
                      { name: "Asian", code: "ASN" },
                      { name: "Rajasthani", code: "RJT" },
                      { name: "Arabian", code: "ARB" },
                      { name: "Korean", code: "KRN" },
                      { name: "Burmese", code: "BUR" },
                      { name: "European", code: "ERP" },
                      { name: "French", code: "FRN" },
                      { name: "Tibetan", code: "TIB" },
                      { name: "Jain", code: "JAN" },
                      { name: "Kashmiri", code: "KSH" },
                      { name: "Kolhapuri", code: "KLH" },
                      { name: "Gujarati", code: "GJT" },
                      { name: "Parsi", code: "PRS" },
                      { name: "Iranian", code: "IRN" },
                      { name: "Singaporean", code: "SNP" },
                      { name: "Goan", code: "GOA" },
                      { name: "Mangalorean", code: "MNG" },
                    ]}
                    value={restObj.cuisine.filter(
                      (cuisine: Cuisine[]) => cuisine != null
                    )}
                    onChange={(e: MultiSelectChangeEvent) => {
                      console.log("{restObj.eaterytype", restObj.eaterytype);
                      setRestObj({ ...restObj, cuisine: e.value });
                    }}
                    optionLabel="name"
                    display="chip"
                  />
                </div>
              </div>
              <div className="col-lg-6 d-flex flex-column gap-1">
                <div className="d-flex align-items-baseline gap-1">
                  <label className="ym-input-label">Service Options</label>
                  <i
                    className="bi bi-info-circle"
                    title="Select the service options for the restaurant"
                    id="icon"
                  ></i>
                </div>

                <div className="flex flex-column">
                  <MultiSelect
                    options={[
                      { name: "Dine-In", code: "DIN" },
                      { name: "Take-Away", code: "TAK" },
                      { name: "Delivery", code: "DEL" },
                    ]}

                    // value={mappedOptions}
                    value={mappedOptions}
                      
                    onChange={(e) => handleMultiSelectChange(e.value)
                    } 

                   
      

                    optionLabel="name"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="d-flex align-items-center gap-1">
                <label className="ym-input-label">Tags</label>
                <i
                  className="bi bi-info-circle"
                  title="Select any tags for the restaurant if any."
                  id="icon"
                ></i>
              </div>
              <div className="flex flex-column gap-2">
                <Dropdown
                  id="tags"
                  value={restObj.tags}
                  onChange={(e) => {
                    const selectedTag = e.value;
                    setRestObj({ ...restObj, tags: selectedTag });
                  }}
                  options={tag}
                  placeholder="Select"
                />
              </div>
            </div>

            <div className="row Yeldam-ratings mt-2">
              <label className="ym-input-label">
                Yeldam Ratings <b style={{ color: "red" }}>*</b>
              </label>
              <div className="hygine-rating-div">
                <span className="rating-category">Hygiene</span>
                <div className="rating d-flex justify-content-between mt-2 mb-2">
                  {[...Array(10)].map((_, index) => {
                    let highlightedClass = '';
                    if (index + 1 === restObj.hygiene) {
                      if (restObj.hygiene <= 3) {
                        highlightedClass = 'highlighted1';
                      } else if (restObj.hygiene <= 6) {
                        highlightedClass = 'highlighted2';
                      } else {
                        highlightedClass = 'highlighted';
                      }
                    }
                    return (
                      <span
                        key={index}
                        onClick={() => handleHygieneRatingClick(index + 1)}
                        className={`box-number ${highlightedClass}`}
                        style={{ backgroundColor: hygieneboxNumberColors[index] }}
                      >
                        {index + 1}
                      </span>
                    );
                  })}
                </div>
              </div>

              <div className="hygine-rating-div">
                <span className="rating-category">Health</span>
                <div className="rating d-flex justify-content-between mt-2 mb-2">
                  {[...Array(10)].map((_, index) => {
                    let highlightedClass = '';
                    if (index + 1 === restObj.health) {
                      if (restObj.health <= 3) {
                        highlightedClass = 'highlighted1';
                      } else if (restObj.health <= 6) {
                        highlightedClass = 'highlighted2';
                      } else {
                        highlightedClass = 'highlighted';
                      }
                    }
                    return (
                      <span
                        key={index}
                        onClick={() => handleHealthRatingClick(index + 1)}
                        className={`box-number ${highlightedClass}`}
                        style={{ backgroundColor: boxNumberColors[index] }}
                      >
                        {index + 1}
                      </span>
                    );
                  })}
                </div>
              </div>


              <div className="hygine-rating-div">
                <span className="rating-category">Taste</span>
                <div className="rating d-flex justify-content-between mt-2 mb-2">
                  {[...Array(10)].map((_, index) => {
                    let highlightedClass = '';
                    if (index + 1 === restObj.taste) {
                      if (restObj.taste <= 3) {
                        highlightedClass = 'highlighted1';
                      } else if (restObj.taste <= 6) {
                        highlightedClass = 'highlighted2';
                      } else {
                        highlightedClass = 'highlighted';
                      }
                    }
                    return (
                      <span
                        key={index}
                        onClick={() => handleTasteRatingClick(index + 1)}
                        className={`box-number ${highlightedClass}`}
                        style={{ backgroundColor: tasteboxNumberColors[index] }}
                      >
                        {index + 1}
                      </span>
                    );
                  })}
                </div>
              </div>


              <div className="hygine-rating-div">
                <span className="rating-category">Cost Effective</span>
                <div className="rating d-flex justify-content-between mt-2 mb-2">
                  {[...Array(10)].map((_, index) => {
                    let highlightedClass = '';
                    if (index + 1 === restObj.costeffective) {
                      if (restObj.costeffective <= 3) {
                        highlightedClass = 'highlighted1';
                      } else if (restObj.costeffective <= 6) {
                        highlightedClass = 'highlighted2';
                      } else {
                        highlightedClass = 'highlighted';
                      }
                    }
                    return (
                      <span
                        key={index}
                        onClick={() => handleCostEffectiveRatingClick(index + 1)}
                        className={`box-number ${highlightedClass}`}
                        style={{ backgroundColor: costboxNumberColors[index] }}
                      >
                        {index + 1}
                      </span>
                    );
                  })}
                </div>
              </div>


              <div className="hygine-rating-div">
                <span className="rating-category">Values</span>
                <div className="rating d-flex justify-content-between mt-2 mb-2">
                  {[...Array(10)].map((_, index) => {
                    let highlightedClass = '';
                    if (index + 1 === restObj.valuesrating) {
                      if (restObj.valuesrating <= 3) {
                        highlightedClass = 'highlighted1';
                      } else if (restObj.valuesrating <= 6) {
                        highlightedClass = 'highlighted2';
                      } else {
                        highlightedClass = 'highlighted';
                      }
                    }
                    return (
                      <span
                        key={index}
                        onClick={() => handleValueRatingClick(index + 1)}
                        className={`box-number ${highlightedClass}`}
                        style={{ backgroundColor: valueboxNumberColors[index] }}
                      >
                        {index + 1}
                      </span>
                    );
                  })}
                </div>
              </div>


              <div className="hygine-rating-div">
                <span className="rating-category">Hospitality</span>
                <div className="rating d-flex justify-content-between mt-2 mb-2">
                  {[...Array(10)].map((_, index) => {
                    let highlightedClass = '';
                    if (index + 1 === restObj.hospitality) {
                      if (restObj.hospitality <= 3) {
                        highlightedClass = 'highlighted1';
                      } else if (restObj.hospitality <= 6) {
                        highlightedClass = 'highlighted2';
                      } else {
                        highlightedClass = 'highlighted';
                      }
                    }
                    return (
                      <span
                        key={index}
                        onClick={() => handleHospitalityRatingClick(index + 1)}
                        className={`box-number ${highlightedClass}`}
                        style={{ backgroundColor: hospitalityboxNumberColors[index] }}
                      >
                        {index + 1}
                      </span>
                    );
                  })}
                </div>
              </div>


            </div>
            {/* Timings */}
            <div className="row ">
              <div className="col-12">
                <div className="child-lef t" id="Timings">
                  <label className="ym-input-label mb-2">
                    Timings <b style={{ color: "red" }}>*</b>
                  </label>

                  <div className="">
                    <div className="row">
                      <div className="row">
                        {daysOfWeek.map((day: string) => (
                          <div className="col-lg-3" key={day}>
                            <div className="d-flex align-items-center">
                              <Checkbox
                                inputId={`day${day}`}
                                name="day"
                                value={day}
                                checked={checkedDays[day]}
                                onChange={() => handleCheckboxChange(day)}
                              />
                              <label htmlFor={`day${day}`} className="ml-1">
                                {day}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className=" d-flex from-to gap-2 align-items-center mb-3">
                        <div className="from d-flex flex-column col-lg-3">
                          <span className="restaurant-name">From</span>
                          <Dropdown
                            style={{
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            id="eater"
                            placeholder="Select"
                            value={fromtimes}
                            options={Timings}
                            onChange={onFromTimeChange}
                          />
                        </div>
                        <div className="from d-flex flex-column col-3">
                          <span className="restaurant-name">To</span>
                          <Dropdown
                            style={{
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            id="eater"
                            placeholder="Select"
                            value={totimes}
                            options={Timings}
                            onChange={onToTimeChange}
                          />
                        </div>
                        <div className="mt-3">
                          <button
                            className="add-button"
                            onClick={handleAddTiming}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                      <div className="row d-flex justify-content-start align-items-center mt-3">
                        <div className="col-8">
                          {Object.entries(selectedTimings).map(
                            ([day, timing]) => (
                              <div
                                className="Sunday-timing col-12 gap-3 d-flex justify-content-center align-items-center mt-3"
                                key={day}
                              >
                                <span className="day">{day}</span>
                                <div className="row">
                                  <div className="col gap-1">
                                    {timing.map((time, index) => (
                                      <div className="col p-0 mt-1 gap-1">
                                        <span className="time" key={index}>
                                          {time}
                                        </span>

                                        <i
                                          className="bi bi-trash-fill"
                                          style={{
                                            color: "#818181",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            const updatedTimings = {
                                              ...selectedTimings,
                                            };
                                            updatedTimings[day] =
                                              updatedTimings[day].filter(
                                                (t) => t !== time
                                              );

                                            // Check if there are no times left, delete the entire day
                                            if (
                                              updatedTimings[day].length === 0
                                            ) {
                                              delete updatedTimings[day];
                                            }

                                            setSelectedTimings(updatedTimings);
                                          }}
                                        ></i>
                                        <br />
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="row d-flex justify-content-start align-items-center mt-3">
                        <RestaurantTimings
                          timing={restObj.timings}
                          editable={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* IMages */}
            <div className="row m-0">
              <div className="col-12">
                <div className="child-left" id="uploadimages">
                  <div className="">
                    <div className="">
                      <label className="ym-input-label">
                        Thumbnail Image
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <DropFileInput
                        uploadedFiles={thumbnailImages}
                        setUploadedFiles={setThumbnailImages}
                        resObj={restObj}
                        setResObj={setRestObj}
                        purpose="ThumbnailImages"
                      />
                    </div>

                    <div className="grid mt-2 ml-1 gap-3">
                      {Array.isArray(restObj.thumbnails) &&
                        restObj.thumbnails.every(
                          (item: any) => typeof item === "string"
                        ) &&
                        restObj.thumbnails.map((item: any, index: any) => {
                          return (
                            <div
                              id="Imagecrd"
                              style={{ position: "relative" }}
                              key={index}
                            >
                              <img
                                className="uploadimage fefe"
                                key={item}
                                src={item + "?" + sas}
                                alt=""
                              />

                              <i
                                className="pi pi-trash icontrash"
                                onClick={() =>
                                  handleDeleteClick(index, "ThumbnailImages")
                                }
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  margin: "8px",
                                }}
                              ></i>
                            </div>
                          );
                        })}
                    </div>
                    <div className="">
                      <label className="ym-input-label">
                        Restaurant Images
                        <b style={{ color: "red" }}>*</b>
                      </label>
                      <DropFileInput
                        uploadedFiles={restaurantImages}
                        setUploadedFiles={setRestantImages}
                        resObj={restObj}
                        setResObj={setRestObj}
                        purpose="RestaurantImages"
                      />
                    </div>
                    <div className="grid mt-2 ml-1 gap-3">
                      {Array.isArray(restObj.images) &&
                        restObj.images.every(
                          (item: any) => typeof item === "string"
                        ) &&
                        restObj.images.map((item: any, index: any) => {
                          // const imageUrl = URL.createObjectURL(item);

                          return (
                            <div
                              id="Imagecrd"
                              style={{ position: "relative" }}
                              key={index}
                            >
                              <img
                                className="uploadimage"
                                key={item.id}
                                src={item + "?" + sas}
                                alt=""
                              />

                              <i
                                className="pi pi-trash icontrash"
                                onClick={() =>
                                  handleDeleteClick(index, "RestaurantImages")
                                }
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  margin: "8px",
                                }}
                              ></i>
                            </div>
                          );
                        })}
                    </div>
                    <br></br>
                    <div className="">
                      <label className="ym-input-label">Menu Images</label>
                      <DropFileInput
                        uploadedFiles={menuImages}
                        setUploadedFiles={setMenuImages}
                        resObj={restObj}
                        setResObj={setRestObj}
                        purpose="MenuImages"
                      />
                    </div>

                    <div className="grid mt-2 ml-1 gap-3">
                      {Array.isArray(restObj.menuimages) &&
                        restObj.menuimages.every(
                          (item: any) => typeof item === "string"
                        ) &&
                        restObj.menuimages.map((item: any, index: any) => {
                          // const imageUrl = URL.createObjectURL(item);

                          return (
                            <div
                              id="Imagecrd"
                              style={{ position: "relative" }}
                              key={index}
                            >
                              <img
                                className="uploadimage"
                                key={item.id}
                                src={item + "?" + sas}
                                alt=""
                              />

                              <i
                                className="pi pi-trash icontrash"
                                onClick={() =>
                                  handleDeleteClick(index, "MenuImages")
                                }
                                style={{
                                  position: "absolute",
                                  top: "0",
                                  right: "0",
                                  margin: "8px",
                                }}
                              ></i>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* update button */}
            <div className="d-flex justify-content-end">
              <Button
                label="Save Changes"
                className="p-button-rounded d-flex   p-button-success change-btn-login"
                autoFocus
                onClick={handleEditRestaurant}
              />
            </div>
          </div>
        </ScrollPanel>
      </div>
    </>
  );
};

export default EditRestaurant;
