import React from 'react';
import "./detailsinfo.css";

interface props {
    registereddietitian: string;
    languages: string[];
    services: string[];
    about: string;
    dietitianinstagram: string;
    dietitiantwitter: string;
    dietitianyoutube: string;
    dietitianfacebook: string;
    dietitianwebsite: string;
}

const DietitianInfo: React.FC<props> = ({
    registereddietitian,
    languages,
    services,
    about,
    dietitianinstagram,
    dietitiantwitter,
    dietitianyoutube,
    dietitianfacebook,
    dietitianwebsite
}) => {
    return (
        <div>
            <div className='d-flex'>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Registered Dietitian</h6>
                    <p className='sub-detail-data'>{registereddietitian ? registereddietitian:"--"}</p>
                </div>

                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Languages</h6>
                    <p className='sub-detail-data'>{languages.join(", ") ? languages:"--"}</p>
                </div>
            </div>

            <div className='d-flex'>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Website</h6>
                    <p className='sub-detail-data'>{dietitianwebsite ? dietitianwebsite:"--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Instagram</h6>
                    <p className='sub-detail-data'>{dietitianinstagram ? dietitianinstagram:"--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Twitter</h6>
                    <p className='sub-detail-data'>{dietitiantwitter ? dietitiantwitter:"--"}</p>
                </div>

            </div>
            <div className='d-flex'>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Youtube</h6>
                    <p className='sub-detail-data'>{dietitianyoutube ? dietitianyoutube : "--"}</p>
                </div>
                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Facebook</h6>
                    <p className='sub-detail-data'>{dietitianfacebook ? dietitianfacebook: "--"}</p>
                </div>
            </div>


            <div className='col-6 pt-0'>
                <h6 className="mb-1 sub-detail-head">Services</h6>
                <p className='sub-detail-data'>{services.join(",") ? services:"--"}</p>
            </div>
            <div className='col-6 pt-0'>
                <h6 className="mb-1 sub-detail-head">About</h6>
                <p className='sub-detail-data'>{about ? about:"--"}</p>
            </div>


        </div>
    );
}

export default DietitianInfo;
