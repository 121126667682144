import React from 'react';
import { Chart } from 'primereact/chart';
type props = {
    data: {};
    options:{};
  };
const Doughnutchart: React.FC<props> = ({data,options}) => {
    return (

        // <div className="card flex justify-content-center">
        <Chart type="doughnut" data={data} options={options} 
        // className="w-full md:w-30rem"
         />
    // </div>
        );
    };
    
    export default Doughnutchart;